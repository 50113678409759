import React from 'react'
import Adminfooter from './Adminfooter'
import Adminheader from './Adminheader'
import Adminsidebar from './Adminsidebar'

function Adminlayout({childern}) {
    return (
        <div>
            <Adminheader />
            <div className="row mr-0">
                <div className="col-md-2"> <Adminsidebar /></div>
               <div className="col-md-9" style={{background:'aliceblue',marginLeft:"70px"}}>
                {childern}</div>
            </div>
            <Adminfooter/>
            
        </div>
    )
}

export default Adminlayout
