import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {Search,CSVExport} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { dateFilter } from "react-bootstrap-table2-filter";
import DatePicker from "react-multi-date-picker";

function Adminteacher() {
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;

  const [endDate, setendDate] = useState(new Date());
  const [startDate, setstartDate] = useState(new Date());

  useEffect(() => {
    getusers();
  }, []);

  const getusers = () => {
    try {
      axios
        .get(`https://doorstepedu.info/api/user/getallUser`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata(response.data.success);
            return;
          } else {
            //alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };


  const approve = async (data) => {
    try {
      axios
        .get(`https://doorstepedu.info/api/user/blockuser/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const unblock = async (data) => {
    try {
      axios
        .get(`https://doorstepedu.info/api/user/unblockuser/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };
  const postdeleteimage = async (data) => {
    try {
      const config = {
        url: "/user/deleteUser/" + data._id,
        method: "delete",
        baseURL: "https://doorstepedu.info/api",
        headers: { "content-type": "multipart/form-data" },

        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("Deleted successfully");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const columns = [
    {
      dataField: "_id",
      text: "ID",
    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "email",
      text: "Teacher Email",
    },
    {
      dataField: "standard",
      text: "Standard from",
    },
    {
      dataField: "standard1",
      text: "Standard To",
    },
    {
      dataField: "subject",
      text: "Subject",
    },
    {
      dataField: "subject1",
      text: "Optional Subject 1",
    },
    {
      dataField: "subject2",
      text: "Optional Subject 2",
    },
    {
      dataField: "mobile",
      text: "Phone Number",
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            {!row.blockstatus ? (
              <button
                className="btn btn-outline-success"
                onClick={() => approve(row)}
              >
                Block
              </button>
            ) : (
              <button
                className="btn btn-outline-danger"
                onClick={() => unblock(row)}
              >
                Un-Block
              </button>
            )}
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn btn-danger"
              onClick={() => postdeleteimage(row)}
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total px-4">
      Showing {from} to {to} of {size} Results
    </span>
  );
  function getDateObject(datevalue) {
    //  return new Date(datevalue);
    let d = new Date(datevalue);
    //let d=moment(datevalue).format('DD/MM/YYYY');
    let v = d.getDate() + "/" + d.getMonth() + "/" + d.getFullYear();
    return v;
  }

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  // data.filter((data) => {
  //   console.log(new Date(data.date.split("-").reverse().join("-")));
  //   console.log(Date.parse(data.date));
  // });
  // const search = () => {
  //   const filteredData = data1.filter(
  //     (data) =>
  //       new Date(data.date.split("-").reverse().join("-")) >=
  //         new Date(startDate.split("-").reverse().join("-")) &&
  //       new Date(data.date.split("-").reverse().join("-")) <=
  //         new Date(endDate.split("-").reverse().join("-"))
  //   );
  //   setdata(filteredData);
  // };

  return (
    <div style={{ background: "#fff" }}>
      <h2 style={{ color: "#01a695", textAlign: "center" }}> Teacher </h2>
      <div className="row pb-3 px-3">
        <div className="col-md-2">
          {/* <h7>Date From</h7> */}
          {/* <DatePicker
        style={{ height: "35px", width: "140px", marginRight: "-5px" }}
        placeholder="DD-MM-YYYY"
        format="DD-MM-YYYY"
        type="input-icon"
        onChange={(date) => setstartDate(date.format("DD-MM-YYYY"))}
      />
    </div>
    <div className="col-md-2">
      <h7>Date To</h7>
      <DatePicker
        style={{ height: "35px", width: "140px", marginRight: "-5px" }}
        placeholder="DD-MM-YYYY"
        format="DD-MM-YYYY"
        type="input-icon"
        onChange={(date) => setendDate(date.format("DD-MM-YYYY"))}
      /> */}
        </div>
        {/* <div className="col-md-2">
      <button
        className="btn btn-primary"
        style={{ marginTop: "20px" }}
        onClick={search}
      >
        filter
      </button>
    </div> */}
      </div>
      <ToolkitProvider
        keyField="id"
        data={data}
        columns={columns}
        search
        exportCSV={{
          fileName: "TeacherDetails.csv",
          blobType: "text/csv;charset=ansi",
        }}
      >
        {(props) => (
          <div>
            <span className="px-3 mr-auto ">
              <ExportCSVButton
                className="btn-outline-success"
                {...props.csvProps}
              >
                Export to Excel
              </ExportCSVButton>
            </span>
            <span className="pl-4 mr-5 mt-5">
              <SearchBar className="form-control " {...props.searchProps} />
            </span>
            <span className="pl-4 mr-5 mt-5" style={{marginLeft:"20px"}}>
            <ClearSearchButton
              className="btn-outline-info"
              {...props.searchProps}
            />
</span>

            <hr />
            <div className="table-responsive" style={{margin:'30px'}}>
              <BootstrapTable
                keyField="id"
                striped
                hover
                condensed
                columns={columns}
                data={data}
                pagination={paginationFactory(options)}
                {...props.baseProps}
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
}

export default Adminteacher;
