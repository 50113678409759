import axios from "axios";
import React, { useState } from "react";
import Logo from "../../assets/logo.jpg";

function Adminsignin1() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: "/admin/login",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        hearder: { "content-type": "application/json" },

        data: {
          email: email,
          password: password,
        },
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          console.log(res.data);
          alert("login success");
          sessionStorage.setItem("admin", JSON.stringify(res.data.success));
          window.location.assign("/dashboard");
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      alert("Invalid email or password");
      console.log(error.response.data.error);
    }
  };
  return (
    <div style={{ background: "#fff" }}>
      <div className="container pt-5 pb-5">
        <div className="row" style={{ padding: " 50px 30px" }}>
          <div className="col-md-3" style={{ padding: "10em 0px" }}>
            {/* <img src="/door.jpg"></img> */}
          </div>
          <div className="col-md-6">
            <form>
              <div className="row ">
                <div className="col-md-12 t-c ">
                  <img src={Logo} height="200px" width="200px" style={{}} />

                  <h5
                    className=""
                    style={{
                      marginTop: "-20px",
                      fontSize: "2rem",
                      color: "Navyblue",
                    }}
                  >
                    {" "}
                    <span>Admin Sign In</span>{" "}
                  </h5>
                </div>
                <div className="col-md-12">
                  <div className="form-group pb-2">
                    <label className="f-w-700 ">Email ID</label>
                    <input
                      className="form-control "
                      type="text"
                      style={{
                        background: "#fff",
                        padding: "10px;",
                        height: "60px",
                      }}
                      placeholder="Enter Email"
                      onChange={(e) => setEmail(e.target.value)}
                    ></input>
                  </div>
                  <div className="form-group pb-2">
                    <label className="f-w-700 ">Password</label>
                    <input
                      className="form-control "
                      type="password"
                      style={{
                        background: "#fff",
                        padding: "10px;",
                        height: "60px",
                      }}
                      placeholder="Enter Password"
                      onChange={(e) => setPassword(e.target.value)}
                    ></input>
                  </div>
                </div>
                <div
                  className="col-md-12 pt-2"
                  style={{ padding: "20px 20px 10px", float: "right" }}
                >
                  <div className="form-gropu pt-2">
                    <button
                      className="btn btn-primary form-control"
                      style={{
                        padding: "5px",
                        background: "Navyblue",
                        height: "60px",
                        fontSize: "20px",
                        fontWeight: "600",
                      }}
                      onClick={login}
                    >
                      Sign In
                    </button>
                  </div>

                  {/* <p className="t-c pt-2" style={{textDecoration:" underline "}} > Don't have an account? <span style={{color:"blue"}}> Sign Up</span> </p> */}
                  {/* <p className="t-c"> Or Continue with</p> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Adminsignin1;
