import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const SkillDevelopment = () => {
  const [addskill, setaddSkill] = useState({});
  const [show88, setShow88] = useState(false);

  const handleClose88 = () => setShow88(false);
  const handleShow88 = () => setShow88(true);
  const [addplacement, setaddplacement] = useState({});
  const [show99, setShow99] = useState(false);

  const handleClose99 = () => setShow99(false);
  const handleShow99 = () => setShow99(true);
  //   //integrating get  method
  const [AddPlacements, setAddPlacements] = useState([]);
  const getAddPlacements = async () => {
    try {
      let res = await axios.get("https://doorstepedu.info/api/user/getPlacements");
      if (res.status === 200) {
        setAddPlacements(res.data.getPlacements);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // integrating get method for companies
  const [AddCompaniess, setAddCompaniesss] = useState([]);
  const getAddCompanies = async () => {
    try {
      let res = await axios.get("https://doorstepedu.info/api/user/getCompanies");
      if (res.status === 200) {
        setAddCompaniesss(res.data.getCompanies);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // integrating get method for pdp course

  const [Addpdp, setAddpdp] = useState([]);
  const getAddPDP = async () => {
    try {
      let res = await axios.get("https://doorstepedu.info/api/user/getPDP");
      if (res.status === 200) {
        setAddpdp(res.data.getPDP);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [counterOn, setCounterOn] = useState(false);
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    getAddPlacements();
    getAddCompanies();
    getAddPDP();
  }, []);
  console.log(AddPlacements);
  console.log(AddCompaniess);
  console.log(Addpdp);
  return (
    <>
      <div className="">
        <div
          class="module mid"
          style={{
            height: "80px",
            background: "#d1ccc0",
            overflow: " hidden",
            // background: " #C33764",
            //   background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
            //  url("Header 2.jpg")`,
            backgroundSize: "cover",
          }}
        >
          <h2 style={{ right: "-2%" }}>Skill Development</h2>
          <ul
            class=" container breadcrumbs bc3 pt-3 pb-3"
            role="menubar"
            aria-label="breadcrumbs"
          >
            <li>
              <a href="/">Home</a>{" "}
              <span style={{ color: "#092746" }}>&raquo;</span>
            </li>
            <li style={{ color: "#092746" }}> Skill Development</li>
          </ul>
        </div>
      </div>
      <div className="container mt-2">
        <div className="row hjklo_9">
          <div className="col-md-12">
            <h1>
              Personal Development plan
              <span style={{ color: "#00a695" }}>(PDP Course) </span>
            </h1>
          </div>
        </div>
      </div>
      <div className="container ">
        {Addpdp?.map((item) => {
          return (
            <div className="row">
              <div className="col-md-6">
                <img
                  src={`https://doorstepedu.info/PDP/${item?.PDPImage}`}
                  alt=""
                  style={{ width: "100%", height: "200px" }}
                />
              </div>
              <div className="col-md-6 text-center tyujk_0">
                <p className="card_text_p" style={{ textAlign: "justify" }}>
                  {item?.PDPText.slice(0, 500) < item?.PDPText.slice(0, 501) ? (<>{item?.PDPText.slice(0, 500)}... <br />
                    <span
                      className="viemore"
                      onClick={() => {
                        handleShow88();
                        setaddSkill(item);
                      }}
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        float: "right",
                      }}
                    >
                      View More
                    </span>
                  </>) : (<>{item?.PDPText}</>)}

                </p>
              </div>
            </div>
          );
        })}
      </div>
      {/* <div style={{ backgroundColor: "#141522" }}>
        <div className="container mt-2 ">
          <div className="row hjklo_9">
            <div className="col-md-12 text-white pt-3">
              <h1>
                Our &nbsp;
                <span style={{ color: "#00a695" }}>Trainers </span>
              </h1>
            </div>
          </div>
        </div>
        <div className="container pb-5">
          <div className="row">
            <Slider {...settings}>
              <div className="col-md-4">
                <div className="box">
                  <img src="tutor2-740x792.jpg" alt="" />
                  <div className="box-content">
                    <span className="ribbon">$ 30 /hr</span> <br />
                    <h3 className="name">
                      CHEMISTRY,CODING,COMMUNICATION <br />
                      TAYLOR JONES
                    </h3>
                    <a href="">
                      <div id="container">
                        <button className="btn4"></button>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="box">
                  <img src="tutor4-740x792.jpg" alt="" />
                  <div className="box-content">
                    <span className="ribbon">$ 30 /hr</span> <br />
                    <h3 className="name">
                      ENGLISH,MARKETING,MATHEMATICS <br />
                      TAYLOR JONES
                    </h3>
                    <a href="">
                      <div id="container">
                        <button className="btn4"></button>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="box">
                  <img src="tutor6-740x792.jpg" alt="" />
                  <div className="box-content">
                    <span className="ribbon">$ 30 /hr</span> <br />
                    <h3 className="name">
                      ENGLISH,MARKETING,MATHEMATICS <br />
                      TAYLOR JONES
                    </h3>
                    <a href="">
                      <div id="container">
                        <button className="btn4"></button>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="box">
                  <img src="tutor6-740x792.jpg" alt="" />
                  <div className="box-content">
                    <span className="ribbon">$ 30 /hr</span> <br />
                    <h3 className="name">
                      ENGLISH,MARKETING,MATHEMATICS <br />
                      TAYLOR JONES
                    </h3>
                    <a href="">
                      <div id="container">
                        <button className="btn4"></button>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div> */}
      {/* <div
        style={{
          backgroundImage: "url('./it company.jpg')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          height: "250px",
        }}
      >
        <div className="ghjkl_0">
          <div className="container mt-2 ">
            <div className="container mt-2 ">
              <div className="row hjklo_9">
                <div className="col-md-12 text-white pt-3 ">
                  <h1>
                    Companies
                    <span style={{ color: "#C4E538" }}> </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-4 ">
            <Slider {...settings}>
              <div>
                <img src="./tata.png" alt="" width={200} height={100} />
              </div>
              <div>
                <img src="./infosys.png" alt="" width={200} height={100} />
              </div>
              <div>
                <img src="./wipro.png" alt="" width={200} height={100} />
              </div>
              <div>
                <img src="./netlink.png" alt="" width={200} height={100} />
              </div>
              <div>
                <img src="./MPHASIS.NS.png" alt="" width={200} height={100} />
              </div>
            </Slider>
          </div>
        </div>
      </div> */}
      {/* <div className="container">
        <div className="row  hjklo_9">
          <div className="col-md-12 text-center">
            <h1> Placements 2022-23</h1>
            <h2>
              3,118 Placements <span style={{ color: "#00a695" }}>|</span> 406
              Companies
            </h2>
            <h3>
              including{" "}
              <span
                style={{
                  //   border: "1px solid black",
                  padding: "3px",
                  borderRadius: "18px",
                  backgroundColor: "#00a695",
                }}
              >
                1,587
              </span>{" "}
              Selections in{" "}
            </h3>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row fghjm_0">
          <div className="col-md-3">
            <h4>
              DXC <span>17</span>
            </h4>
          </div>
          <div className="col-md-3">
            <h4>
              Infosys <span>18</span>{" "}
            </h4>
          </div>
          <div className="col-md-3">
            <h4>
              TCS <span>20</span>
            </h4>
          </div>
          <div className="col-md-3">
            <h4>
              Wipro <span>23</span>
            </h4>
          </div>
        </div>
        <div className="row fghjm_0">
          <div className="col-md-3">
            <h4>
              DXC <span>17</span>
            </h4>
          </div>
          <div className="col-md-3">
            <h4>
              Infosys <span>18</span>{" "}
            </h4>
          </div>
          <div className="col-md-3">
            <h4>
              TCS <span>20</span>
            </h4>
          </div>
          <div className="col-md-3">
            <h4>
              Wipro <span>23</span>
            </h4>
          </div>
        </div>
      </div> */}
      {/* <div className="container mt-2 ">
        <div className="row hjklo_9">
          <div className="col-md-12">
            <h1>
              Gallery
              <span style={{ color: "#C4E538" }}> </span>
            </h1>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row  hjklo_9 ">
          <div className="col-md-3 text-center hhjou_0">
            <img src="./tutor2-740x792.jpg" alt="" style={{ width: "100%" }} />
            <h5>Tutor</h5>
          </div>
          <div className="col-md-3 text-center hhjou_0 ">
            <img src="./tutor2-740x792.jpg" alt="" style={{ width: "100%" }} />
            <h5>Tutor</h5>
          </div>
          <div className="col-md-3 text-center  hhjou_0">
            <img src="./tutor2-740x792.jpg" alt="" style={{ width: "100%" }} />
            <h5>Tutor</h5>
          </div>
          <div className="col-md-3 text-center  hhjou_0">
            <img src="./tutor2-740x792.jpg" alt="" style={{ width: "100%" }} />
            <h5>Tutor</h5>
          </div>
        </div>
      </div> */}
      <div className="container mt-2">
        <div className="row hjklo_9">
          <div className="col-md-12">
            <h1>
              Placements
              <span style={{ color: "#00a695" }}> </span>
            </h1>
          </div>
        </div>
      </div>
      <div className="container">
        {AddPlacements?.map((item) => {
          return (
            <div className="row">
              <div className="col-md-12 dfghs_0">
                <img
                  src={`https://doorstepedu.info/Placements/${item?.PImage}`}
                  alt=""
                  style={{ width: "100%", height: "450px" }}
                />
                <div className="jksd_0">
                  <h4>{item?.PHeader}</h4>
                  <p className="card_text_p" style={{ textAlign: "justify" }}>
                    {item?.PText1.slice(0, 500) < item?.PText1.slice(0, 501) ? (<>{item?.PText1.slice(0, 500)}... <br />
                      <span
                        className="viewmore text-dark"
                        onClick={() => {
                          handleShow99();
                          setaddplacement(item);
                        }}
                        style={{
                          cursor: "pointer",

                        }}
                      >
                        View More
                      </span>
                    </>) : (<>{item?.PText1}</>)}

                  </p>
                  <div className="container ">
                    <div className="row">
                      <div className="col-md-3 cvbnm_0 ">
                        <ScrollTrigger
                          onEnter={() => setCounterOn(true)}
                          onExit={() => setCounterOn(false)}
                        >
                          <h5>
                            {counterOn && (
                              <CountUp
                                start={0}
                                end={item?.PCounts}
                                duration={2}
                                delay={0}
                              />
                            )}
                          </h5>
                          Placed Students
                        </ScrollTrigger>
                      </div>

                      <div className="col-md-3">
                        <ScrollTrigger
                          onEnter={() => setCounterOn(true)}
                          onExit={() => setCounterOn(false)}
                        >
                          <h5>
                            {counterOn && (
                              <CountUp
                                start={0}
                                end={item?.CCount}
                                duration={2}
                                delay={0}
                              />
                            )}
                          </h5>
                          Companies
                        </ScrollTrigger>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="container mt-5">
        <div className="row hjklo_9">
          <div className="col-md-12">
            <h1>
              Companies
              <span style={{ color: "#00a695" }}> </span>
            </h1>
          </div>
        </div>
      </div>
      <div className="container mt-2 ghghg_4 ">
        <div className="row">
          {AddCompaniess?.map((item) => {
            return (
              <div className="col-md-2">
                <img
                  src={`https://doorstepedu.info/Companies/${item?.CLogo}`}
                  alt=""
                  style={{ width: "150px" }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <Modal show={show88} onHide={handleClose88} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Personal Development plan(PDP Course)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            {" "}
            <img
              src={`https://doorstepedu.info/PDP/${addskill?.PDPImage}`}
              alt=""
              style={{ width: "100%", height: "250px" }}
            />
          </span>{" "}
          <p className="card_text_p">{addskill?.PDPText}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose88}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show99} onHide={handleClose99} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{addplacement?.PHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <p className="card_text_p">{addplacement?.PText1}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose99}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SkillDevelopment;
