import React, { useState, useEffect } from "react";
import { Card, Form, Button } from "react-bootstrap";
import axios from "axios";

function Addyoutube() {
  const [data, setdata] = useState([]);
  const [image, setimage] = useState();
  const [type, settype] = useState(" ");
  const [youtubetitle, setyoutubetitle] = useState(" ");
  const [youtubedate, setyoutubedate] = useState();
  const [youtubelink, setyoutubelink] = useState(" ");
  const [standard, setstandard] = useState(" ");
  const [youtubesubject, setyoutubesubject] = useState(" ");
  const [studentname, setstudentname] = useState(null);
  const user = JSON.parse(sessionStorage.getItem("admin"));
  const postimage = async () => {
    if (
      !youtubetitle ||
      !youtubesubject ||
     
      !standard ||
      !youtubelink ||
      !type 

     
     
    ) {
      alert('Please fill mandatory fields');
    
    } else {
    try {
      const config = {
        url: "/admin/addyoutube",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "content-type": "application/json" },
        data: {
          youtubetitle: youtubetitle,

          standard: standard,
          adminid: user._id,
          studentid: studentname,
          type: type,

          youtubedate: youtubedate,
          youtubelink: youtubelink,
          youtubesubject: youtubesubject,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("youtube add successfully");
        window.location.assign("/admin/youtube");
      }
    } catch (error) {
      console.log(error);
    }
  }
  };
  useEffect(() => {
    getusers();
  }, []);

  const getusers = async () => {
    try {
      const config = {
        url: '/user/getAllUser1',
        method: 'get',
        baseURL: 'https://doorstepedu.info/api',
        headers: {'content-type': 'application/json'},
      };
      await axios(config)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);

            setdata(response.data.success);
            return;
          } else {
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          // //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      // alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };

  useEffect(() => {
    filter();
  }, [standard]);

  const filter = () => {
    if (standard != "") {
      let fliterdata = data.filter((item) => item["standard"] === standard);
      console.log(fliterdata);
      setdata(fliterdata);
    }
  };

  return (
    <div className="container mt-5">
      <Card
        className="text-center"
        style={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <h5 className="pt-5">Create Youtube Link</h5>
        <Card.Body>
          <Form className="w-50 mx-auto">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>youtube Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                onChange={(e) => setyoutubetitle(e.target.value)}
              />
              {/* <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text> */}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Link"
                onChange={(e) => setyoutubelink(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Date"
                onChange={(e) => setyoutubedate(e.target.value)}
              />
            </Form.Group>
            <div className="form-group basic">
              <div className="input-wrapper">
                <label className="label" for="select4">
                  Type
                </label>
                <select
                  className="form-control custom-select"
                  id="select4"
                  onChange={(e) => settype(e.target.value)}
                >
                  <option value="">Select Type</option>
                  <option value="standard">Whole class</option>
                  <option value="student">Individual student Name</option>
                </select>
              </div>
            </div>
            <div className="form-group basic">
              <div className="input-wrapper">
                <label className="label" for="select4">
                  ClassName list
                </label>
                <select
                  className="form-control custom-select"
                  id="select4"
                  onChange={(e) => setstandard(e.target.value)}
                >
                  <option value="Class 1">Class 1</option>
                  <option value="Class 2">Class 2</option>
                  <option value="Class 3">Class 3</option>
                  <option value="Class 4">Class 4</option>
                  <option value="Class 5">Class 5</option>
                  <option value="Class 6">Class 6</option>
                  <option value="Class 7">Class 7</option>
                  <option value="Class 8">Class 8</option>
                  <option value="Class 9">Class 9</option>
                  <option value="Class 10">Class 10</option>
                  <option value="Class 11">Class 11</option>
                  <option value="Class 12">Class 12</option>
                </select>
              </div>
            </div>

            {type == "student" ? (
              <>
                {" "}
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="select4">
                      Student Name list
                    </label>
                    <select
                      className="form-control custom-select"
                      id="select4"
                      onChange={(e) => setstudentname(e.target.value)}
                    >
                      <option value="">Select Student </option>
                      {data.filter((data)=> data.standard === standard).map((name) => (
                        <option value={name._id}>{name.name}</option>
                      ))}
                    </select>
                  </div>
                </div>{" "}
              </>
            ) : (
              ""
            )}
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                placeholder="Subject"
                onChange={(e) => setyoutubesubject(e.target.value)}
              />
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>classname</Form.Label>
              <Form.Control
                type="text"
                placeholder="Date"
                onChange={(e) => setstandard(e.target.value)}
              />
            </Form.Group> */}
          </Form>

          <button className="btn btn-primary" onClick={postimage}>
            Create Youtube Link
          </button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Addyoutube;
