import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import axios from "axios";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

function Adminnoteteacher() {

  const [isOpen, setIsOpen] = useState(false);
  const [data, setdata] = useState([]);
  const [noticeboardtitle, setnoticeboardtitle] = useState(" ");
  const [noticeboarddate, setnoticeboarddate] = useState();
  const [noticeboardclassname, setnoticeboardclassname] = useState(" ");
  const [noticeboardsubject, setnoticeboardsubject] = useState(" ");
  const [noticeboardcontent, setnoticeboardcontent] = useState(" ");
  const [type, settype] = useState("");
  
  const formdata = new FormData();
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };
  const postnoticeboard = async () => { 
    try {
      const config = {
        url: "/admin/addnoticeboard",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "content-type": "application/json" },
        data: {
           type : type,
     noticeboardtitle : noticeboardtitle,
     noticeboardclassname : noticeboardclassname,
     noticeboarddate : noticeboarddate,
     noticeboardsubject : noticeboardsubject,
     noticeboardcontent : noticeboardcontent,
        },
        
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("Data successfully");
       hideModal()
       getnoticeboard();
      }
    } catch (error) {
      console.log(error);
      alert("Data failed");
    }
  };

  
  const remove = async (data) => {
    try {
      axios
        .post(`https://doorstepedu.info/api/admin/deletenoticeboard/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");

      return error;
    }
  };
  useEffect(() => {
    getnoticeboard();
  }, []);

  const getnoticeboard = async () => {
    let res = await axios.get("https://doorstepedu.info/api/admin/getnoticeboard");
    if ((res.status = 200)) {
      console.log(res);
      setdata(res.data?.noticeboard.filter((data)=>data.type === "Teacher"));
    }
  };

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  function imageFormatter(cell, row) {
    return (
      <img
        src={`https://doorstepedu.info/noticeboard/${cell}`}
        height="50px"
        width="50px"
        style={{ borderRadius: "100%" }}
      />
    );
  }
  const columns = [
    {
      dataField: "type",
      text: "User Type",
      sort: true,
    },
    {
      dataField: "noticeboarddate",
      text: "Date",
      sort: true,
    },
    {
      dataField: "noticeboardtitle",
      text: "Title",
      sort: true,
    },
    {
      dataField: "noticeboardsubject",
      text: "Subject",
      sort: true,
    },
    {
      dataField: "noticeboardcontent",
      text: "Content",
      sort: true,
    },
    
    // {
    //   dataField: "image",
    //   text: "Image",
    //   formatter: imageFormatter,
    // },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button className="btn btn-danger" onClick={() => remove(row)}>
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="mt-3">
        <h2 style={{ color: "#01a695", textAlign: "center" }}> Teacher Noticeboard </h2>
      {/* <div className="d-flex">
        <Button variant="success" onClick={showModal} className=" me-2" style={{marginLeft:"20px"}}>
          Add notice
        </Button>
      </div> */}
      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>noticeboard</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label for="type">
            Select User Type:<span style={{ color: "red" }}>*</span>
          </label>

          <select
            className="ms-5"
            name="type"
            id="type"
            onChange={(e) => settype(e.target.value)}
          >
            
            <option value="">Select User Type</option>
            <option value="All">All</option>

            <option value="Parent">Parent</option>
            <option value="Teacher">Teacher</option>
            <option value="Student">Student</option>
            
          </select>
          <div>
            <label className="mt-2">Title</label>
            <input
              type="text"
              placeholder="Title"
              className="form-control"
              onChange={(e) => setnoticeboardtitle(e.target.value)}
            ></input>
             <label className="mt-2">ClassName</label>
             <select
                      className="form-control custom-select"
                      id="select4"
                      onChange={(e) => setnoticeboardclassname(e.target.value)}
                    >
                      <option value="">Select class</option>
                      <option value="Class 1">Class 1</option>
                  <option value="Class 2">Class 2</option>
                  <option value="Class 3">Class 3</option>
                  <option value="Class 4">Class 4</option>
                  <option value="Class 5">Class 5</option>
                  <option value="Class 6">Class 6</option>
                  <option value="Class 7">Class 7</option>
                  <option value="Class 8">Class 8</option>
                  <option value="Class 9">Class 9</option>
                  <option value="Class 10">Class 10</option>
                  <option value="Class 11">Class 11</option>
                  <option value="Class 12">Class 12</option>
                    </select>
            <label className="mt-2"> Subject</label>
            <input
              type="text"
              placeholder="Subject"
              className="form-control"
              onChange={(e) => setnoticeboardsubject(e.target.value)}
            ></input>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                Content<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e) => setnoticeboardcontent(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Date"
                onChange={(e) => setnoticeboarddate(e.target.value)}
              />
            </Form.Group>
            {/* <label>Image/Video<span style={{ color: "red" }}>*</span></label>
            <input
              type="file"
              placeholder="Enter Country Name"
              className="form-control"
              accept="image/png, image/gif, image/jpeg"
              onChange={(e) => setimage(e.target.files[0])}
            ></input> */}
          </div>
          {/* <div className="ms-3 mt-3">
            <span style={{ color: "red" }}>*</span>Required Fiels
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={()=>postnoticeboard()}>
            Save{" "}
          </Button>
        </Modal.Footer>
      </Modal>
      <div>
        <ToolkitProvider
          keyField="id"
          data={data}
          columns={columns}
          search
          exportCSV={{
            fileName: "patient.csv",
            blobType: "text/csv;charset=ansi",
          }}
        >
          {(props) => (
            <div className="mt-5">
              <span className="pl-5 ms-5 me-5 mt-5">
                <SearchBar className="form-control " {...props.searchProps} />
              </span>
              <ClearSearchButton
                className="btn-outline-info"
                {...props.searchProps}
              />

              <hr />
              <div className="table-responsive" style={{margin:30}}>
                <BootstrapTable
                  keyField="patient"
                  responsive
                  hover
                  columns={columns}
                  data={data}
                  pagination={paginationFactory(options)}
                  {...props.baseProps}
                />
              </div>
            </div>
          )}
        </ToolkitProvider>
      </div>
    </div>
  );
}

export default Adminnoteteacher;
