import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {Search,CSVExport} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import exportFromJSON from "export-from-json";

import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { dateFilter } from "react-bootstrap-table2-filter";
import DatePicker from "react-multi-date-picker";
import {  Form, Modal} from "react-bootstrap";
import { AiFillDelete } from 'react-icons/ai';
import moment from "moment";
function Adminexam() {
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const [data2, setdata2] = useState([]);
  const [image, setimage] = useState();
  const [examtitle, setexamtitle] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);

  const [examdate, setexamdate] = useState();
  const [examclassname, setexamclassname] = useState("");
  const [examsubject, setexamsubject] = useState("");
  const [mark, setmark] = useState("");
  const [others, setothers] = useState("");
  const [type, settype] = useState("");
  const [standard, setstandard] = useState("");
 const [studentname, setstudentname] = useState(null);
  const [examinstruction, setexaminstruction] = useState("");
  const [time, settime] = useState("");
  const user = JSON.parse(sessionStorage.getItem("admin"));

  const [data1, setdata1] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;

  const [endDate, setendDate] = useState(new Date());
  const [startDate, setstartDate] = useState(new Date());
  const [selected1, setselected1] = useState({});
  const [isOpen1, setIsOpen1] = useState(false);
  const formdata2 = new FormData();
  useEffect(() => {
    getusers();
    getusers1();

  }, []);
  const showModal1 = () => {
    setIsOpen1(true);
  };

  const hideModal1 = () => {
    setIsOpen1(false);
  };
  const getusers1 = async () => {
    try {
      const config = {
        url: '/user/getAllUser1',
        method: 'get',
        baseURL: 'https://doorstepedu.info/api',
        headers: {'content-type': 'application/json'},
      };
      await axios(config)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);

            setdata1(response.data.success);
            return;
          } else {
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          // //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      // alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };
  const handleFileChange = (event) => {
    const files = event.target.files;
    const newSelectedImages = [...selectedImages, ...files];
    setSelectedImages(newSelectedImages);
  };
  const uploadFiles=async(id)=>{
   
    try {
     
      for (let i = 0; i < selectedImages.length; i++) {
       
        formdata2.append("id", id);
        formdata2.append("examfile", selectedImages[i]
          );
        await fetch('https://doorstepedu.info/api/admin/addExamFiles/', {
          method: 'post',
          body: formdata2
          
        })
      }
      
    } catch (error) {
      console.log(error);
    }
  }
  const updateProduct = async () => {
    // formdata2.append("techid", selected1._id);
    
    // formdata2.append("name", assignmenttitle ? assignmenttitle : selected1.assignmenttitle);
   
    // formdata2.append("assignmentsubject", assignmentsubject ? assignmentsubject : selected1.assignmentsubject);

    // formdata2.append("others", others ? others : selected1.others);
    // formdata2.append("assignmentdate", assignmentdate ? moment(assignmentdate).format('DD/MM/YYYY') : selected1.assignmentdate);
    // formdata2.append("mark", mark ? mark : selected1.mark);
    // formdata2.append("standard", standard ? standard : selected1.standard);
    // formdata2.append("type", type ? type : selected1.type);
   
  
    // formdata.append("banner", photo);

    try {
      const config = {
        url: "/admin/updateExam" ,
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "application/json" },
        data:{
          techid: selected1._id,
          examtitle: examtitle ? examtitle : selected1.examtitle,
   
        examsubject: examsubject ? examsubject : selected1.examsubject,
        studentid:type === "student" ? (studentname ? studentname : selected1.studentid) : (studentname ? studentname : null),
        others: others ? others : selected1.others,
        examdate: examdate ? moment(examdate).format('DD/MM/YYYY') : selected1.examdate,
        mark: mark ? mark : selected1.mark,
        standard: standard ? standard : selected1.standard,
        examtype: type ? type : selected1.examtype,
        examinstruction: examinstruction ? examinstruction : selected1.examinstruction,
        examtime : time ? time : selected1.examtime
        }
      };
      await axios(config).then(function (res) {
        if (res.status === 200) {
          uploadFiles(selected1._id)
          alert("Exam updated");
          console.log(res.data);
          setIsOpen1(false);
          getusers();
          
          window.location.reload();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  const data = [];
  if (data2?.length !== 0) {
    for (let i = 0; i < data2.length; i++) {
      data.push({
        Title: data2[i]?.examtitle,
        DueDate: data2[i]?.examdate,
        Subject: data2[i]?.examsubject,
        Class: data2[i]?.standard,
        Instruction:data2[i].examinstruction,
        Duration:data2[i].examtime,
        examType:
          data2[i]?.examtype === "standard"
            ? "Whole Class"
            : "Individual Student",
        StudentName: data2[i]?.studentid
          ? data2[i]?.studentid?.name
          : "Whole Class",
        Others: data2[i]?.others,

        TeacherName: data2[i]?.teacherid ? data2[i]?.teacherid?.name : "Admin",
        Marks: data2[i]?.mark,
      });
    }
  }
  const fileName = "exam";
  const exportType = "xls";

  const ExportToExcel = () => {
    exportFromJSON({ data, fileName, exportType });
  };
  const removeSkill = async (id1,id) => {
    
    try {
      const config = {
        url: `/removeExamfile/${id1}/${id}`,
        method: "delete",
        baseURL: "https://doorstepedu.info/api/admin",
        headers: { "content-type": "application/json" },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert("successfully removed");
        getusers()
        
      } else {
        alert("Something Wrong");
      }
    } catch (error) {
      if (error.response) {
        alert(error.response.data.error);
      }
    }
  };
  const getusers = () => {
    try {
      axios
        .get(`https://doorstepedu.info/api/admin/getallexam`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata2(response.data.success);
            // setdata1(response.data.exam);
            return;
          } else {
            //alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };

  const postdeleteimage = async (data) => {
    try {
      const config = {
        url: "/admin/deleteexam/" + data._id,
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "content-type": "multipart/form-data" },

        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("exam deleted successfully");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      dataField: "examtitle",
      text: "Title",
    },
    {
      dataField: "examdate",
      text: "Date",
    },
    {
      dataField: "examsubject",
      text: "Subject",
    },
  
    {
      dataField: "standard",
      text: "Class ",
    },
    {
      dataField: "examtype",
      text: "Type",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p
             style={{fontSize:"16px",marginTop:'-0px'}}
            >
            {row.examtype === "standard" ? "Whole Class" : "Individual Student"}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "studentid.name",
      text: "Student Name",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p
             style={{fontSize:"16px",marginTop:'-0px'}}
            >
            {row.studentid  ? row.studentid?.name : "Whole Class"}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "examinstruction",
      text: "Instruction ",
    },
    {
      dataField: "others",
      text: "Others ",
    },
    {
      dataField: "examtime",
      text: "Duration ",
    },
    {
      dataField: "teacherid.name",
      text: "Teacher Name",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p
             style={{fontSize:"16px",marginTop:'-0px'}}
            >
            {row.teacherid   ? row.teacherid?.name  : "Admin"}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "mark",
      text: "Mark",
    },
    {
      dataField: "",
      text: "File",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
           {row.examfile.map((data)=>(  <a
              className="btn btn-success"
              style={{marginBottom:10}}
              // href={
              //   "https://doorstepedu.info/Exam/" +
              //   data.file
              // }
            >
              {data.file} <AiFillDelete onClick={()=>removeSkill(row._id,data._id)}/>
            </a>))}
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Edit",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn btn-outline-success"
              onClick={() => {
                setselected1(row);
                showModal1();
              }}
            >
              Edit
            </button>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn btn-danger"
              onClick={() => postdeleteimage(row)}
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total px-4">
      Showing {from} to {to} of {size} Results
    </span>
  );
  function getDateObject(datevalue) {
    //  return new Date(datevalue);
    let d = new Date(datevalue);
    //let d=moment(datevalue).format('DD/MM/YYYY');
    let v = d.getDate() + "/" + d.getMonth() + "/" + d.getFullYear();
    return v;
  }

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data2.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  // data.filter((data) => {
  //   console.log(new Date(data.date.split("-").reverse().join("-")));
  //   console.log(Date.parse(data.date));
  // });
  // const search = () => {
  //   const filteredData = data1.filter(
  //     (data) =>
  //       new Date(data.date.split("-").reverse().join("-")) >=
  //         new Date(startDate.split("-").reverse().join("-")) &&
  //       new Date(data.date.split("-").reverse().join("-")) <=
  //         new Date(endDate.split("-").reverse().join("-"))
  //   );
  //   setdata(filteredData);
  // };

  return (
    <div style={{ background: "#fff" }}>
      <h2 style={{ color: "#01a695", textAlign: "center" }}> Exam </h2>
      <div className="row pb-3 px-3">
        <div className="col-md-2">
          {/* <h7>Date From</h7> */}
          {/* <DatePicker
            style={{ height: "35px", width: "140px", marginRight: "-5px" }}
            placeholder="DD-MM-YYYY"
            format="DD-MM-YYYY"
            type="input-icon"
            onChange={(date) => setstartDate(date.format("DD-MM-YYYY"))}
          />
        </div>
        <div className="col-md-2">
          <h7>Date To</h7>
          <DatePicker
            style={{ height: "35px", width: "140px", marginRight: "-5px" }}
            placeholder="DD-MM-YYYY"
            format="DD-MM-YYYY"
            type="input-icon"
            onChange={(date) => setendDate(date.format("DD-MM-YYYY"))}
          /> */}
        </div>
        {/* <div className="col-md-2">
          <button
            className="btn btn-primary"
            style={{ marginTop: "20px" }}
            onClick={search}
          >
            filter
          </button>
        </div> */}
      </div>
      <ToolkitProvider
        keyField="id"
        data={data2}
        columns={columns}
        search
        exportCSV={{
          fileName: "transaction.csv",
          blobType: "text/csv;charset=ansi",
        }}
      >
        {(props) => (
          <div>
            <span className="px-3 mr-auto ">
            <ExportCSVButton
                className="btn-outline-success"
                onClick={ExportToExcel}
              >
                Export CSV
              </ExportCSVButton>
            </span>
            <span className="pl-4 mr-5 mt-5" style={{marginLeft:"20px"}}>
              <SearchBar className="form-control " {...props.searchProps} />
            </span>
            <span className="pl-4 mr-5 mt-5" style={{marginLeft:"20px"}}>
            <span className="pl-4 mr-5 mt-5" style={{marginLeft:"20px"}}>
            <ClearSearchButton
              className="btn-outline-info"
              {...props.searchProps}
            />
</span>
</span>
            <hr />
            <div className="table-responsive" style={{margin:'30px'}}>
              <BootstrapTable
                keyField="id"
                striped
                hover
                condensed
                columns={columns}
                data={data2}
                pagination={paginationFactory(options)}
                {...props.baseProps}
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
      <Modal
        style={{ fontFamily: "Georgia, 'Times New Roman', Times, serif" }}
        show={isOpen1}
        onHide={hideModal1}
      >
        <Modal.Header closeButton className="mt-4">
          <Modal.Title>Edit Exam </Modal.Title>
        </Modal.Header>

        <div className="ms-2 me-2">
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">Exam Title</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setexamtitle(e.target.value)}
              placeholder={selected1.examtitle}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">Exam Subject</Form.Label>
            <Form.Control
             type="text"
             className="form-control"
            
             placeholder={selected1.examsubject}
             onChange={(e) => setexamsubject(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">
            Exam Type
            </Form.Label>
            <select
                      className="form-control custom-select"
                      id="select4"
                      onChange={(e) => settype(e.target.value)}
                    >
                      <option value="">Select Exam Type</option>
                      <option value="standard">Whole class</option>
                      <option value="student">Individual student Name</option>
                    </select>
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">
            ClassName list
            </Form.Label>
            <select
                      className="form-control custom-select"
                      id="select4"
                      onChange={(e) => setstandard(e.target.value)}
                    >
                       <option value="">Select Class</option>
                     <option value="Class 1">Class 1</option>
                  <option value="Class 2">Class 2</option>
                  <option value="Class 3">Class 3</option>
                  <option value="Class 4">Class 4</option>
                  <option value="Class 5">Class 5</option>
                  <option value="Class 6">Class 6</option>
                  <option value="Class 7">Class 7</option>
                  <option value="Class 8">Class 8</option>
                  <option value="Class 9">Class 9</option>
                  <option value="Class 10">Class 10</option>
                  <option value="Class 11">Class 11</option>
                  <option value="Class 12">Class 12</option>
                    </select>
          </Form.Group>
          {type == "student" ? (
                  <>
                    {" "}   <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">
            Student Name list
            </Form.Label>
            <select
                          className="form-control custom-select"
                          id="select4"
                          onChange={(e) => setstudentname(e.target.value)}
                        >
                         <option value="">Select Student </option>
                          {data1.filter((data)=> data.standard === standard).map((name) => (
                            <option value={name._id}>{name.name}</option>
                          ))}
                        </select>
          </Form.Group>
          </>
                ) : (
                  ""
                )}
                 
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">Others</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setothers(e.target.value)}
              placeholder={selected1.others}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">Mark</Form.Label>
            <Form.Control
              type="number"
              onChange={(e) => setmark(e.target.value)}
              placeholder={selected1.mark}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold"> Due Date</Form.Label>
            <Form.Control
               type="Date"
               className="form-control"
               id="password4"
               autocomplete="off"
              onChange={(e) => setexamdate(e.target.value)}
              placeholder={selected1.examdate}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">Exam Instruction</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setexaminstruction(e.target.value)}
              placeholder={selected1.examinstruction}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">Duration</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => settime(e.target.value)}
              placeholder={selected1.examtime}
            />
          </Form.Group>
          {selected1?.examfile?.map((data)=>( <a
              className="btn btn-success"
              style={{marginBottom:10}}
             href={
                "https://doorstepedu.info/Exam/" +
                data.file
              }
            >
              {data.file}
            </a>))}
          <div className="section-title ">Upload File</div>
            <div className="">
              <div className="-body">
                <form>
                  <div className="" id="fileUpload1">
                    <input
                      type="file"
                      id="fileuploadInput"
                      multiple 
                     
                      onChange={(e)=>handleFileChange(e)}
                    />
                     {/* {selectedImages.map((imageFile, index) => (
          <img key={index} src={URL.createObjectURL(imageFile)} alt={`Image ${index}`} />
        ))} */}
                    
                  </div>
                </form>
              </div>
           </div></div>
         
       
        <Modal.Footer>
          <Button variant="success" onClick={hideModal1}>
            Close
          </Button>
          <Button variant="danger" onClick={updateProduct}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Adminexam;
