import {
  faAddressCard,
  faBusinessTime,
  faChalkboardTeacher,
  faGauge,
  faHouse,
  faHundredPoints,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { PiExamBold } from "react-icons/pi";
import { FcAbout } from "react-icons/fc";
import {
  AiFillSliders,
  AiOutlineBranches,
  AiOutlineComment,
  AiOutlineQuestionCircle,
} from "react-icons/ai";
import {
  MdBookOnline,
  MdModelTraining,
  MdOutlineReviews,
} from "react-icons/md";
import { BsTruck } from "react-icons/bs";
import {
  BiLogoDiscourse,
  BiSolidContact,
  BiSolidInstitution,
} from "react-icons/bi";
import { RiPresentationLine } from "react-icons/ri";
import { GiSkills } from "react-icons/gi";
import { FaChalkboardTeacher, FaHandshake } from "react-icons/fa";
import { LiaCertificateSolid } from "react-icons/lia";

function SideBar() {
  let [state, setState] = useState(true);

  let handlestate = () => {
    setState(!state);
  };
  let [state1, setState1] = useState(true);
  let handlestate1 = () => {
    setState1(!state1);
  };

  let [state2, setState2] = useState(true);
  let handlestate2 = () => {
    setState2(!state2);
  };
  let [state3, setState3] = useState(true);
  let handlestate3 = () => {
    setState3(!state3);
  };
  let [state4, setState4] = useState(true);
  let handlestate4 = () => {
    setState4(!state4);
  };
  let [state5, setState5] = useState(true);
  let handlestate5 = () => {
    setState5(!state5);
  };
  let [state6, setState6] = useState(true);
  let handlestate6 = () => {
    setState6(!state6);
  };
  let [state7, setState7] = useState(true);
  let handlestate7 = () => {
    setState7(!state7);
  };
  const [acc, setacc] = useState(true);
  const [acc1, setacc1] = useState(false);
  const [acc2, setacc2] = useState(false);
  const [acc3, setacc3] = useState(false);
  const [acc4, setacc4] = useState(false);
  const [acc5, setacc5] = useState(false);
  const [acc6, setacc6] = useState(false);
  const [acc7, setacc7] = useState(false);
  const [acc8, setacc8] = useState(false);
  const [acc9, setacc9] = useState(false);
  const [acc10, setacc10] = useState(false);
  const [acc11, setacc11] = useState(false);
  const [acc21, setacc21] = useState(false);
  const [acc22, setacc22] = useState(false);
  const [acc23, setacc23] = useState(false);
  const [acc24, setacc24] = useState(false);
  const [acc16, setacc16] = useState(false);
  const [acc17, setacc17] = useState(false);
  const [acc18, setacc18] = useState(false);
  const [acc19, setacc19] = useState(false);
  const [acc20, setacc20] = useState(false);

  const [isShown, setIsShown] = useState(false);
  const handleClick = (event) => {
    setIsShown((current) => !current);
  };
  const [isShown1, setIsShown1] = useState(false);

  const handleClick1 = (event) => {
    setIsShown1((current) => !current);
  };
  const [isShown2, setIsShown2] = useState(false);

  const handleClick2 = (event) => {
    setIsShown2((current) => !current);
  };

  const [isShown3, setIsShown3] = useState(false);

  const handleClick3 = (event) => {
    setIsShown3((current) => !current);
  };

  const [isShown4, setIsShown4] = useState(false);
  const handleClick4 = (e) => {
    setIsShown4((current) => !current);
  };
  const [isShown5, setIsShown5] = useState(false);
  const handleClick5 = (e) => {
    setIsShown5((current) => !current);
  };
  const [isShown6, setIsShown6] = useState(false);
  const handleClick6 = (e) => {
    setIsShown6((current) => !current);
  };
  const [isShown7, setIsShown7] = useState(false);
  const handleClick7 = (e) => {
    setIsShown7((current) => !current);
  };
  return (
    <div className="si09">
      <div className="lo-ad">
        <figure
          style={{
            width: "100%",
            height: "100px",
            padding: "10px 11px",
            textAlign: "center",
          }}
        >
          <img src="./logo.jpeg" alt="adminlogo" className="img-fluid" />
        </figure>
      </div>
      <ul>
        <Link to="/dashboard" className="" id="nbhj_0">
          <li
            className={`a-ele  ${acc ? "active-0 " : ""}`}
            onClick={() => {
              setacc(true);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              // setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(true);
            }}
          >
            {" "}
            <FontAwesomeIcon
              icon={faGauge}
              style={{ marginLeft: "5px", fontSize: "20px" }}
            />{" "}
            DASHBOARD
          </li>
        </Link>
        <Link to="">
          <li
            className={`a-ele  ${acc1 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(true);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              // setacc9(false);
              setacc10(false);

              setacc11(false);

              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);

              handlestate();
              handleClick3(true);
            }}
          >
            {" "}
            <FontAwesomeIcon
              icon={faHouse}
              style={{
                marginLeft: "5px",
                marginRight: "5px",
                fontSize: "20px",
              }}
            />
            HOME
            {state ? (
              <i
                class="fa fa-caret-down"
                aria-hidden="true"
                style={{
                  float: "right",
                  fontSize: "20px",
                  paddingRight: "20px",
                }}
              ></i>
            ) : (
              <i
                class="fa-solid fa-caret-up"
                v
                aria-hidden="true"
                style={{
                  float: "right",
                  fontSize: "20px",
                  paddingRight: "20px",
                }}
              ></i>
            )}
          </li>
        </Link>

        {isShown3 && (
          <div>
            <Link to="/admngraph" className="">
              <li
                className={`a-ele sub ${acc2 ? "active-0 " : ""}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(true);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(false);
                  setacc7(false);
                  setacc8(false);
                  // setacc9(false);
                  setacc10(false);
                  setacc11(false);

                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                }}
              >
                {" "}
                <AiFillSliders
                  style={{
                    fontSize: "20px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                />{" "}
                SLIDER
                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{
                    float: "right",
                    fontSize: "20px",
                    paddingRight: "20px",
                  }}
                ></i>
              </li>
            </Link>
            <Link to="/adminhomecontent" className="">
              <li
                className={`a-ele sub ${acc2 ? "active-0 " : ""}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(true);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(false);
                  setacc7(false);
                  setacc8(false);
                  // setacc9(false);
                  setacc10(false);
                  setacc11(false);

                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                }}
              >
                {" "}
                <i
                  class="fa fa-percent"
                  aria-hidden="true"
                  style={{
                    fontSize: "20px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                ></i>{" "}
                GUARANTEE
                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{
                    float: "right",
                    fontSize: "20px",
                    paddingRight: "20px",
                  }}
                ></i>
              </li>
            </Link>
            <Link to="/admintestimonaqls" className="">
              <li
                className={`a-ele sub ${acc3 ? "active-0 " : ""}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(true);
                  setacc4(false);
                  setacc5(false);
                  setacc6(false);
                  setacc7(false);
                  setacc8(false);
                  // setacc9(false);
                  setacc10(false);

                  setacc11(false);

                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                }}
              >
                <MdOutlineReviews
                  style={{
                    fontSize: "20px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                />{" "}
                TESTIMONAILS
                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{
                    float: "right",
                    fontSize: "20px",
                    paddingRight: "20px",
                  }}
                ></i>
              </li>
            </Link>
            <Link to="/adminbranches" className="">
              <li
                className={`a-ele sub ${acc4 ? "active-0 " : ""}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(true);
                  setacc5(false);
                  setacc6(false);
                  setacc7(false);
                  setacc8(false);
                  // setacc9(false);
                  setacc10(false);
                  setacc11(false);

                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                }}
              >
                {" "}
                <AiOutlineBranches
                  style={{
                    fontSize: "20px",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                />{" "}
                BRANCHES
                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{
                    float: "right",
                    fontSize: "20px",
                    paddingRight: "20px",
                  }}
                ></i>
              </li>
            </Link>
          </div>
        )}
        <Link to="">
          <li
            className={`a-ele ${acc5 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(true);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              // setacc9(false);
              setacc10(false);
              setacc11(false);

              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              handlestate1();
              handleClick2(true);
            }}
          >
            {" "}
            <FontAwesomeIcon
              icon={faAddressCard}
              style={{
                marginLeft: "5px",
                marginRight: "5px",
                fontSize: "20px",
              }}
            />{" "}
            ABOUT
            {state1 ? (
              <i
                class="fa fa-caret-down"
                aria-hidden="true"
                style={{
                  float: "right",
                  fontSize: "20px",
                  paddingRight: "20px",
                }}
              ></i>
            ) : (
              <i
                class="fa-solid fa-caret-up"
                v
                aria-hidden="true"
                style={{
                  float: "right",
                  fontSize: "20px",
                  paddingRight: "20px",
                }}
              ></i>
            )}
          </li>
        </Link>
        {isShown2 && (
          <div>
            <Link to="/adminaboutus" className="">
              <li
                className={`a-ele sub ${acc6 ? "active-0 " : ""}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(true);
                  setacc7(false);
                  setacc8(false);
                  // setacc9(false);
                  setacc10(false);

                  setacc11(false);

                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                }}
              >
                {" "}
                <FontAwesomeIcon
                  icon={faAddressCard}
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontSize: "20px",
                  }}
                />{" "}
                ABOUT US
                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{
                    float: "right",
                    fontSize: "20px",
                    paddingRight: "20px",
                  }}
                ></i>
              </li>
            </Link>
            <Link to="/adminfaculty" className="">
              <li
                className={`a-ele sub ${acc6 ? "active-0 " : ""}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(true);
                  setacc7(false);
                  setacc8(false);
                  // setacc9(false);
                  setacc10(false);

                  setacc11(false);

                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                }}
              >
                <FontAwesomeIcon
                  icon={faChalkboardTeacher}
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontSize: "20px",
                  }}
                />{" "}
                FACULTY
                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{
                    float: "right",
                    fontSize: "20px",
                    paddingRight: "20px",
                  }}
                ></i>
              </li>
            </Link>
            <Link to="/adminwhychooseus" className="">
              <li
                className={`a-ele sub ${acc6 ? "active-0 " : ""}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(true);
                  setacc7(false);
                  setacc8(false);
                  // setacc9(false);
                  setacc10(false);

                  setacc11(false);

                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                }}
              >
                <BsTruck
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontSize: "20px",
                  }}
                />
                WHY CHOOSE US
                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{
                    float: "right",
                    fontSize: "20px",
                    paddingRight: "20px",
                  }}
                ></i>
              </li>
            </Link>
            <Link to="/admingallery " className="">
              <li
                className={`a-ele sub ${acc7 ? "active-0 " : ""}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(false);
                  setacc7(true);
                  setacc8(false);
                  // setacc9(false);
                  setacc10(false);

                  setacc11(false);

                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                }}
              >
                <FontAwesomeIcon
                  icon={faImage}
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontSize: "20px",
                  }}
                />{" "}
                GALLERY
                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{
                    float: "right",
                    fontSize: "20px",
                    paddingRight: "20px",
                  }}
                ></i>
              </li>
            </Link>
          </div>
        )}

        <Link to="" className="">
          <li
            className={`a-ele ${acc8 ? "active-0 " : ""}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(true);
              // setacc9(false);
              setacc10(false);

              setacc11(false);

              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              handlestate3();
              handleClick(true);
            }}
          >
            <PiExamBold
              style={{
                marginLeft: "5px",
                marginRight: "5px",
                fontSize: "30px",
              }}
            />
            EXAM DESK
            {state3 ? (
              <i
                class="fa fa-caret-down"
                aria-hidden="true"
                style={{
                  float: "right",
                  fontSize: "20px",
                  paddingRight: "20px",
                }}
              ></i>
            ) : (
              <i
                class="fa-solid fa-caret-up"
                v
                aria-hidden="true"
                style={{
                  float: "right",
                  fontSize: "20px",
                  paddingRight: "20px",
                }}
              ></i>
            )}
          </li>
        </Link>

        {isShown && (
          <div>
            <Link to="/adminmcq">
              <li
                className={`a-ele sub ${acc9 ? "active-0 " : ""}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(false);
                  setacc7(false);
                  setacc8(false);
                  // setacc9(true);
                  setacc10(false);

                  setacc11(false);

                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                }}
              >
                <span style={{ marginTop: "-10px" }}>
                  <AiOutlineQuestionCircle
                    style={{
                      marginLeft: "5px",
                      marginRight: "5px",
                      fontSize: "30px",
                    }}
                  />
                  MCQ / SUBJECTIVE <br />{" "}
                  <span style={{ marginLeft: "45px" }}>CONTENT</span>
                </span>

                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{
                    float: "right",
                    fontSize: "20px",
                    paddingRight: "20px",
                    marginTop: "-5px",
                  }}
                ></i>
              </li>
            </Link>
            {/* <Link to="/adminsubjective" className="">
              <li className={`a-ele sub ${acc10 ? "active-0 " : ""}`} onClick={() => {
                setacc(false);
                setacc1(false);
                setacc2(false);
                setacc3(false);
                setacc4(false);
                setacc5(false);
                setacc6(false);
                setacc7(false);
                setacc8(false);
                setacc10(true);
                setacc16(false);
                setacc17(false);
                setacc18(false);
                setacc19(false)
                setacc20(false)
              }}  >  SUBJECTIVE TEST
              </li>
            </Link> */}
            <Link to="/adminexamdesk" className="">
              <li
                className={`a-ele sub ${acc11 ? "active-0 " : ""}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(false);
                  setacc7(false);
                  setacc8(false);
                  // setacc9(false);
                  setacc10(false);
                  setacc11(true);

                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                }}
              >
                {" "}
                <AiOutlineComment
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontSize: "30px",
                  }}
                />{" "}
                ENQUIRY
                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{
                    float: "right",
                    fontSize: "20px",
                    paddingRight: "20px",
                  }}
                ></i>
              </li>
            </Link>
            {/* <Link to="/unicriteria05" className="">
              <li className={`a-ele sub ${acc12 ? "active-0 " : ""}`} onClick={() => {
                setacc(false);
                setacc1(false);
                setacc2(false);
                setacc3(false);
                setacc4(false);
                setacc5(false);
                setacc6(false);
                setacc7(false);
                setacc8(false);
                // setacc9(false);
                setacc10(false);
                
                
               
                setacc11(false);
                
                setacc16(false);
                setacc17(false);
                setacc18(false);
                setacc19(false)
                setacc20(false)


              }}  >  Criteria 5
              </li>
            </Link> */}
            {/* <Link to="/unicriteria07" className="">
              <li className={`a-ele sub ${acc13 ? "active-0 " : ""}`} onClick={() => {
                setacc(false);
                setacc1(false);
                setacc2(false);
                setacc3(false);
                setacc4(false);
                setacc5(false);
                setacc6(false);
                setacc7(false);
                setacc8(false);
                // setacc9(false);
                setacc10(false);
                
                setacc13(true);
               
                setacc11(false);
                
                setacc16(false);
                setacc17(false);
                setacc18(false);
                setacc19(false)
                setacc20(false)


              }}  >  Criteria 7
              </li>
            </Link> */}
          </div>
        )}
        <Link to="">
          <li
            className={`a-ele ${acc21 ? "active-0 " : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(true);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              // setacc9(false);
              setacc10(false);

              setacc11(false);

              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              setacc21(true);
              handlestate4();
              handleClick4(true);
            }}
            style={{ paddingTop: "16px" }}
          >
            <BiSolidInstitution
              style={{
                marginLeft: "5px",
                marginRight: "5px",
                fontSize: "30px",
              }}
            />{" "}
            TUITION
            {state4 ? (
              <i
                class="fa fa-caret-down"
                aria-hidden="true"
                style={{
                  float: "right",
                  fontSize: "20px",
                  paddingRight: "20px",
                }}
              ></i>
            ) : (
              <i
                class="fa-solid fa-caret-up"
                v
                aria-hidden="true"
                style={{
                  float: "right",
                  fontSize: "20px",
                  paddingRight: "20px",
                }}
              ></i>
            )}
          </li>
        </Link>

        {isShown4 && (
          <div>
            <Link to="/admintution" className="">
              <li
                className={`a-ele sub ${acc22 ? "active-0 " : ""}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(false);
                  setacc7(false);
                  setacc8(false);
                  // setacc9(false);
                  setacc10(false);

                  setacc11(false);

                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                  setacc21(false);
                  setacc22(true);
                }}
              >
                {" "}
                <BiSolidInstitution
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontSize: "30px",
                  }}
                />{" "}
                TUITION <br />{" "}
                <span style={{ marginLeft: "45px" }}>CONTENT</span>
                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{
                    float: "right",
                    fontSize: "20px",
                    paddingRight: "20px",
                    marginTop: "-15px",
                  }}
                ></i>
              </li>
            </Link>
            <Link to="/admintutiononline" className="">
              <li
                className={`a-ele sub ${acc22 ? "active-0 " : ""}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(false);
                  setacc7(false);
                  setacc8(false);
                  // setacc9(false);
                  setacc10(false);

                  setacc11(false);

                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                  setacc21(false);
                  setacc22(true);
                }}
              >
                {" "}
                <MdBookOnline
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontSize: "30px",
                  }}
                />{" "}
                ONLINE <br />
                <span style={{ marginLeft: "30px" }}>ENQUIRY</span>
                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{
                    float: "right",
                    fontSize: "20px",
                    paddingRight: "20px",
                    marginTop: "-15px",
                  }}
                ></i>
              </li>
            </Link>
            <Link to="/admnhometution" className="">
              <li
                className={`a-ele sub ${acc23 ? "active-0 " : ""}`}
                onClick={() => {
                  setacc(true);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(false);
                  setacc7(false);
                  setacc8(false);
                  // setacc9(false);
                  setacc10(false);

                  setacc11(false);

                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                  setacc21(false);
                  setacc22(false);
                  setacc23(true);
                }}
              >
                {" "}
                <RiPresentationLine
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontSize: "30px",
                  }}
                />{" "}
                HOME TUITION/ <span>INSTITUTE ENQUIRY</span>
                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{
                    float: "right",
                    fontSize: "20px",
                    paddingRight: "20px",
                    marginTop: "-15px",
                  }}
                ></i>
              </li>
            </Link>
            {/* <Link to="/autocriteria03" className="">
              <li className={`a-ele sub ${acc24 ? "active-0 " : ""}`} onClick={() => {
                setacc(true);
                setacc1(false);
                setacc2(false);
                setacc3(false);
                setacc4(false);
                setacc5(false);
                setacc6(false);
                setacc7(false);
                setacc8(false);
                // setacc9(false);
                setacc10(false);



                setacc11(false);

                setacc16(false);
                setacc17(false);
                setacc16(false);
                setacc18(false);
                setacc19(false);
                setacc20(false);
                setacc21(false)
                setacc22(false)
                setacc23(false)
                setacc24(true)


              }}  >  INSTITUTE
              </li>
            </Link> */}

            {/* <Link to="/autocriteria07" className="">
              <li className={`a-ele sub ${acc19 ? "active-0 " : ""}`} onClick={() => {
                setacc(false);
                setacc1(false);
                setacc2(false);
                setacc3(false);
                setacc4(false);
                setacc5(false);
                setacc6(false);
                setacc7(false);
                setacc8(false);
                // setacc9(false);
                setacc10(false);
                
                
               
               
                
                setacc16(false);
                setacc17(false);
                setacc18(false);
                setacc19(true);
                setacc20(false);


              }}  >  Criteria 7
              </li>
            </Link> 
          </div>

        )}


        <Link to="">
          <li className={`a-ele ${acc14 ? "active-0 " : "null"}`} onClick={() => {
            setacc(false);
            setacc1(false);
            setacc2(true);
            setacc3(false);
            setacc4(false);
            setacc5(false);
            setacc6(false);
            setacc7(false);
            setacc8(false);
            // setacc9(false);
            setacc10(false);
            
            setacc13(false)
            setacc14(true);
            setacc11(false);
            
            setacc16(false);
            setacc17(false);
            setacc18(false);
            setacc19(false);
            setacc20(false);


            handleClick1(true)

          }} style={{paddingTop:"16px"}}>TEACHERS TRAINING AND PLACEMENTS

          </li>
        </Link>

        {isShown1 && (
          <div>
            <Link to="/autocriteria01" className="">
              <li className={`a-ele sub ${acc15 ? "active-0 " : ""}`} onClick={() => {
                setacc(false);
                setacc1(false);
                setacc2(false);
                setacc3(false);
                setacc4(false);
                setacc5(false);
                setacc6(false);
                setacc7(false);
                setacc8(false);
                // setacc9(false);
                setacc10(false);
                
                
               
                setacc11(false);
                setacc15(true);
                setacc16(false);
                setacc17(false);
                setacc18(false);
                setacc19(false);
                setacc20(false);
              }}  >  PLACEMENTS
              </li>
            </Link>
            {/* <Link to="/autocriteria02" className="">
              <li className={`a-ele sub ${acc16 ? "active-0 " : ""}`} onClick={() => {
                setacc(true);
                setacc1(false);
                setacc2(false);
                setacc3(false);
                setacc4(false);
                setacc5(false);
                setacc6(false);
                setacc7(false);
                setacc8(false);
                // setacc9(false);
                setacc10(false);
                
                
               
                setacc11(false);
                
                setacc16(true);
                setacc17(false);
                setacc18(false);
                setacc19(false);
                setacc20(false);
              }}  >  Criteria 2
              </li>
            </Link>
            <Link to="/autocriteria03" className="">
              <li className={`a-ele sub ${acc17 ? "active-0 " : ""}`} onClick={() => {
                setacc(true);
                setacc1(false);
                setacc2(false);
                setacc3(false);
                setacc4(false);
                setacc5(false);
                setacc6(false);
                setacc7(false);
                setacc8(false);
                // setacc9(false);
                setacc10(false);
                
                
               
                setacc11(false);
                
                setacc16(false);
                setacc17(true);
                setacc16(false);
                setacc18(false);
                setacc19(false);
                setacc20(false);


              }}  >  Criteria 3
              </li>
            </Link>
            <Link to="/autocriteria05" className="">
              <li className={`a-ele sub ${acc18 ? "active-0 " : ""}`} onClick={() => {
                setacc(true);
                setacc1(false);
                setacc2(false);
                setacc3(false);
                setacc4(false);
                setacc5(false);
                setacc6(false);
                setacc7(false);
                setacc8(false);
                // setacc9(false);
                setacc10(false);
                
                
               
                setacc11(false);
                
                setacc16(false);
                setacc17(false);
                setacc18(true);
                setacc19(false);
                setacc20(false);




              }}  >  Criteria 5
              </li>
            </Link> */}
            {/* <Link to="/autocriteria07" className="">
              <li className={`a-ele sub ${acc19 ? "active-0 " : ""}`} onClick={() => {
                setacc(false);
                setacc1(false);
                setacc2(false);
                setacc3(false);
                setacc4(false);
                setacc5(false);
                setacc6(false);
                setacc7(false);
                setacc8(false);
                // setacc9(false);
                setacc10(false);
                
                
               
               
                
                setacc16(false);
                setacc17(false);
                setacc18(false);
                setacc19(true);
                setacc20(false);


              }}  >  Criteria 7
              </li>
            </Link> */}
          </div>
        )}
        <Link to="">
          <li
            className={`a-ele ${acc5 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(true);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              // setacc9(false);
              setacc10(false);
              setacc11(false);

              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              handlestate5();
              handleClick5(true);
            }}
          >
            {" "}
            <GiSkills
              style={{
                marginLeft: "5px",
                marginRight: "5px",
                fontSize: "30px",
              }}
            />{" "}
            <span style={{ marginTop: "-20px" }}>
              SKILL <span style={{ marginLeft: "45px" }}>DEVELOPMENT</span>{" "}
            </span>
            {state5 ? (
              <i
                class="fa fa-caret-down"
                aria-hidden="true"
                style={{
                  float: "right",
                  fontSize: "20px",
                  paddingRight: "20px",
                  marginTop: "-20px",
                }}
              ></i>
            ) : (
              <i
                class="fa-solid fa-caret-up"
                v
                aria-hidden="true"
                style={{
                  float: "right",
                  fontSize: "20px",
                  paddingRight: "20px",
                  marginTop: "-20px",
                }}
              ></i>
            )}
          </li>
        </Link>
        {isShown5 && (
          <div>
            <Link to="/adminpdp" className="">
              <li
                className={`a-ele sub ${acc6 ? "active-0 " : ""}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(true);
                  setacc7(false);
                  setacc8(false);
                  // setacc9(false);
                  setacc10(false);

                  setacc11(false);

                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                }}
              >
                {" "}
                <BiLogoDiscourse
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontSize: "30px",
                  }}
                />
                PDP COURSE
                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{
                    float: "right",
                    fontSize: "20px",
                    paddingRight: "20px",
                  }}
                ></i>
              </li>
            </Link>
            <Link to="/adminplacements" className="">
              <li
                className={`a-ele sub ${acc6 ? "active-0 " : ""}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(true);
                  setacc7(false);
                  setacc8(false);
                  // setacc9(false);
                  setacc10(false);

                  setacc11(false);

                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                }}
              >
                {" "}
                <FaHandshake
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontSize: "30px",
                  }}
                />{" "}
                PLACEMENTS
                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{
                    float: "right",
                    fontSize: "20px",
                    paddingRight: "20px",
                  }}
                ></i>
              </li>
            </Link>
            <Link to="/admincompanies " className="">
              <li
                className={`a-ele sub ${acc7 ? "active-0 " : ""}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(false);
                  setacc7(true);
                  setacc8(false);
                  // setacc9(false);
                  setacc10(false);
                  setacc11(false);
                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                }}
              >
                {" "}
                <FontAwesomeIcon
                  icon={faBusinessTime}
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontSize: "25px",
                  }}
                />{" "}
                COMPANIES{" "}
                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{
                    float: "right",
                    fontSize: "20px",
                    paddingRight: "20px",
                  }}
                ></i>
              </li>
            </Link>
          </div>
        )}
        <Link to="">
          <li
            className={`a-ele ${acc5 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(true);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              // setacc9(false);
              setacc10(false);
              setacc11(false);
              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              handleClick6(true);
              handlestate6();
            }}
          >
            {" "}
            <MdModelTraining
              style={{
                marginLeft: "5px",
                marginRight: "5px",
                fontSize: "25px",
              }}
            />{" "}
            TEACHERS T & P{" "}
            {/* <span style={{ marginLeft: "45px" }}>& PLACEMENTS</span> */}
            {state6 ? (
              <i
                class="fa fa-caret-down"
                aria-hidden="true"
                style={{
                  float: "right",
                  fontSize: "20px",
                  paddingRight: "20px",
                }}
              ></i>
            ) : (
              <i
                class="fa-solid fa-caret-up"
                v
                aria-hidden="true"
                style={{
                  float: "right",
                  fontSize: "20px",
                  paddingRight: "20px",
                }}
              ></i>
            )}
          </li>
        </Link>
        {isShown6 && (
          <div>
            <Link to="/admintpcontent" className="">
              <li
                className={`a-ele sub ${acc6 ? "active-0 " : ""}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(true);
                  setacc7(false);
                  setacc8(false);
                  // setacc9(false);
                  setacc10(false);

                  setacc11(false);

                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                }}
              >
                {" "}
                <MdModelTraining
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontSize: "25px",
                  }}
                />{" "}
                T & P <span> CONTENT</span>{" "}
                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{
                    float: "right",
                    fontSize: "20px",
                    paddingRight: "20px",
                  }}
                ></i>
              </li>
            </Link>
            <Link to="/admincertificates" className="">
              <li
                className={`a-ele sub ${acc6 ? "active-0 " : ""}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(true);
                  setacc7(false);
                  setacc8(false);
                  // setacc9(false);
                  setacc10(false);

                  setacc11(false);

                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                }}
              >
                {" "}
                <LiaCertificateSolid
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontSize: "25px",
                  }}
                />{" "}
                CERTIFICATES
                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{
                    float: "right",
                    fontSize: "20px",
                    paddingRight: "20px",
                  }}
                ></i>
              </li>
            </Link>
            <Link to="/admintraining" className="">
              <li
                className={`a-ele sub ${acc6 ? "active-0 " : ""}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(true);
                  setacc7(false);
                  setacc8(false);
                  // setacc9(false);
                  setacc10(false);

                  setacc11(false);

                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                }}
              >
                {" "}
                <AiOutlineComment
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontSize: "30px",
                  }}
                />{" "}
                ENQUIRY
                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{
                    float: "right",
                    fontSize: "20px",
                    paddingRight: "20px",
                  }}
                ></i>
              </li>
            </Link>
          </div>
        )}
        <Link to="">
          <li
            className={`a-ele ${acc5 ? "active-0" : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(true);
              setacc6(false);
              setacc7(false);
              setacc8(false);
              // setacc9(false);
              setacc10(false);
              setacc11(false);

              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(false);
              handlestate7();
              handleClick7(true);
            }}
          >
            {" "}
            <FaChalkboardTeacher
              style={{
                marginLeft: "5px",
                marginRight: "5px",
                fontSize: "25px",
              }}
            />{" "}
            BECOME A <br /> <span style={{ marginLeft: "35px" }}>TUTOR </span>
            {state7 ? (
              <i
                class="fa fa-caret-down"
                aria-hidden="true"
                style={{
                  float: "right",
                  fontSize: "20px",
                  paddingRight: "20px",
                  marginTop: "-20px",
                }}
              ></i>
            ) : (
              <i
                class="fa-solid fa-caret-up"
                v
                aria-hidden="true"
                style={{
                  float: "right",
                  fontSize: "20px",
                  paddingRight: "20px",
                  marginTop: "-20px",
                }}
              ></i>
            )}
          </li>
        </Link>
        {isShown7 && (
          <div>
            <Link to="/adminbecomeatutorcontent" className="">
              <li
                className={`a-ele sub ${acc6 ? "active-0 " : ""}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(true);
                  setacc7(false);
                  setacc8(false);
                  // setacc9(false);
                  setacc10(false);

                  setacc11(false);

                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                }}
              >
                {" "}
                <FaChalkboardTeacher
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontSize: "25px",
                  }}
                />{" "}
                BECOME A <br />{" "}
                <span style={{ marginLeft: "20px" }}>TUTOR CONTENT</span>{" "}
                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{
                    float: "right",
                    fontSize: "20px",
                    paddingRight: "20px",
                    marginTop: "-20px",
                  }}
                ></i>
              </li>
            </Link>
            <Link to="/adminbecomeatutor" className="">
              <li
                className={`a-ele sub ${acc6 ? "active-0 " : ""}`}
                onClick={() => {
                  setacc(false);
                  setacc1(false);
                  setacc2(false);
                  setacc3(false);
                  setacc4(false);
                  setacc5(false);
                  setacc6(true);
                  setacc7(false);
                  setacc8(false);
                  // setacc9(false);
                  setacc10(false);

                  setacc11(false);

                  setacc16(false);
                  setacc17(false);
                  setacc18(false);
                  setacc19(false);
                  setacc20(false);
                }}
              >
                {" "}
                <AiOutlineComment
                  style={{
                    marginLeft: "5px",
                    marginRight: "5px",
                    fontSize: "30px",
                  }}
                />{" "}
                ENQUIRY{" "}
                <i
                  class="fa fa-angle-right"
                  aria-hidden="true"
                  style={{
                    float: "right",
                    fontSize: "20px",
                    paddingRight: "20px",
                  }}
                ></i>
              </li>
            </Link>
          </div>
        )}
        <Link to="/admincontactus">
          <li
            className={`a-ele ${acc20 ? "active-0 " : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc7(false);
              setacc8(false);
              // setacc9(false);
              setacc10(false);

              setacc11(false);

              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(true);
            }}
          >
            {" "}
            <BiSolidContact
              style={{
                marginLeft: "5px",
                marginRight: "5px",
                fontSize: "30px",
              }}
            />
            CONTACT US{" "}
            <i
              class="fa fa-angle-right"
              aria-hidden="true"
              style={{
                float: "right",
                fontSize: "20px",
                paddingRight: "20px",
              }}
            ></i>
          </li>
        </Link>
        <Link to="/enquiryform">
          <li
            className={`a-ele ${acc20 ? "active-0 " : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc7(false);
              setacc8(false);
              // setacc9(false);
              setacc10(false);

              setacc11(false);

              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(true);
            }}
          >
            {" "}
            <AiOutlineComment
              style={{
                marginLeft: "5px",
                marginRight: "5px",
                fontSize: "30px",
              }}
            />
            ENQUIRY{" "}
            <i
              class="fa fa-angle-right"
              aria-hidden="true"
              style={{
                float: "right",
                fontSize: "20px",
                paddingRight: "20px",
              }}
            ></i>
          </li>
        </Link>
        <Link to="/branchenquiry">
          <li
            className={`a-ele ${acc20 ? "active-0 " : "null"}`}
            onClick={() => {
              setacc(false);
              setacc1(false);
              setacc2(false);
              setacc3(false);
              setacc4(false);
              setacc5(false);
              setacc7(false);
              setacc8(false);
              // setacc9(false);
              setacc10(false);

              setacc11(false);

              setacc16(false);
              setacc17(false);
              setacc18(false);
              setacc19(false);
              setacc20(true);
            }}
          >
            {" "}
            <AiOutlineComment
              style={{
                marginLeft: "5px",
                marginRight: "5px",
                fontSize: "30px",
              }}
            />
            BRANCH <br /> <span style={{ marginLeft: "30px" }}>ENQUIRY</span>{" "}
            <i
              class="fa fa-angle-right"
              aria-hidden="true"
              style={{
                float: "right",
                fontSize: "20px",
                paddingRight: "20px",
              }}
            ></i>
          </li>
        </Link>
      </ul>
    </div>
  );
}

export default SideBar;
