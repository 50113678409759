import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {Search,CSVExport} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import paginationFactory from "react-bootstrap-table2-paginator";

import { DatesRangeInput } from "semantic-ui-calendar-react";

import filterFactory, { dateFilter } from "react-bootstrap-table2-filter";
import { DateRange } from "@material-ui/icons";
import moment from "moment";

import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
//import { SampleBase } from '../Component/Samplebase';
import { enableRipple } from "@syncfusion/ej2-base";
import { Menu } from "@material-ui/core";
import DatePicker from "react-multi-date-picker";

function Adminspecialtransaction() {
  // const { DateInput } = SemanticUiCalendarReact;
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);

  const [endDate, setendDate] = useState(new Date());
  const [startDate, setstartDate] = useState(new Date());
  const apiURL = process.env.REACT_APP_API_URL;
  //const { dateFilter }  = datefilter;
  enableRipple(true);

  useEffect(() => {
    getusers();
  }, []);

  const getusers = () => {
    try {
      axios
        .get(`https://doorstepedu.info/api/allspecialeventbooking`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata(response.data.booking);
            setdata1(response.data.booking);
            return;
          } else {
            //alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };

  const columns = [
    {
      dataField: "",
      text: "ID",
    },
    {
      dataField: "specialevent[0].specialeventtitle",
      text: "Event Name",
    },
    {
      dataField: "specialevent[0].specialeventamount",
      text: "Event amount",
    },
    {
      dataField: "user[0].email",
      text: "User Email",
    },
    {
      dataField: "user[0].name",
      text: "User name",
    },
    {
      dataField: "bookeddate",
      text: "Booked Date ",
    },
    {
      dataField: "bookedtime",
      text: "Booked Time ",
    },
    {
      dataField: "paymentmethod",
      text: "payment method",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  function getDateObject(datevalue) {
    //  return new Date(datevalue);
    let d = new Date(datevalue);
    //let d=moment(datevalue).format('DD/MM/YYYY');
    let v = d.getDate() + "/" + d.getMonth() + "/" + d.getFullYear();
    return v;
  }

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  // const handleChange = (event, {name, value}) => {
  //   this.setState({ [name] : value});
  //   }

  // const blurDates = () => {

  //   const dateRanges = this.state.datesRange.split(" - ")
  //   const fromADate = new Date ( dateRanges [0])
  //   let userTimezoneOffset = toADate.getTimezoneOffset() * 60000
  //   const offsetFromADate = new Date(fromADate.getTime() + userTimezoneOffset)
  //   const toADate = new Date(dateRanges[1])
  //   userTimezoneOffset = toADate.getTimezoneOffset() * 60000
  //   const offsettoADate = new Date(toADate.getTime() + userTimezoneOffset)

  //   const myDate = data.filter(f =>{
  //     const fDate = new Date(f.bookedtime)
  //     userTimezoneOffset = fDate.getTimezoneOffset() * 60000
  //     const offsetfDate = new Date (fDate.getTime() + userTimezoneOffset)

  //     if ( offsetfDate > offsetFromADate && offsetfDate <= offsettoADate){
  //     return f
  //     } else {
  //       return null
  //     }
  //     })
  //     // this.setState({
  //     //   foursomes: myDates
  //     // })
  //     setdata(myDate)
  //     }

  data.filter((data) => {
    console.log(new Date(data.bookeddate.split("/").reverse().join("/")));
    console.log(Date.parse(data.bookeddate));
  });
  const search = () => {
    const filteredData = data1.filter(
      (data) =>
        new Date(data.bookeddate.split("/").reverse().join("/")) >=
          new Date(startDate.split("/").reverse().join("/")) &&
        new Date(data.bookeddate.split("/").reverse().join("/")) <=
          new Date(endDate.split("/").reverse().join("/"))
    );
    setdata(filteredData);
  };
  // const filteredData = data.filter(data => newate(data.bookeddate.format("DD/MM/YYYY")) >= new Date("09/30/2019") && new Date(data.bookedtime.format("DD/MM/YYYY")) <= new Date("10/07/2019"))

  return (
    <div style={{ background: "#fff" }}>
      <h2 style={{ color: "#01a695", textAlign: "center" }}>
        Special event{" "}
      </h2>
      <div className="row">
        <div className="col-md-2">
          <h7>Date From</h7>
          <DatePicker
            style={{ height: "35px", width: "140px", marginRight: "-5px" }}
            placeholder="DD/MM/YYYY"
            format="DD/MM/YYYY"
            type="input-icon"
            onChange={(date) => setstartDate(date.format("DD/MM/YYYY"))}
          />
        </div>
        <div className="col-md-2">
          <h7>Date To</h7>
          <DatePicker
            style={{ height: "35px", width: "140px", marginRight: "-5px" }}
            placeholder="DD/MM/YYYY"
            format="DD/MM/YYYY"
            type="input-icon"
            onChange={(date) => setendDate(date.format("DD/MM/YYYY"))}
          />
        </div>
        <div className="col-md-2">
          <button
            className="btn btn-primary"
            style={{ marginTop: "20px" }}
            onClick={search}
          >
            Filter
          </button>
        </div>
      </div>
      {/* <div className='control-pane'>
                <div className='control-section'>
                    <div className='daterangepicker-control-section'>
                        <DateRangePickerComponent></DateRangePickerComponent>
                    </div>
                </div>
            </div> */}
      {/* <Menu>
        <Menu.Item>
          <DatesRangeInput name="datesrange" place holder="from-to" value={this.sate.DatesRange}
          iconPosition="left" onchange={this.handlechange} />
        </Menu.Item>
        </Menu> */}
      {/* <Menu.Item>
	<DatesRangeInput
	name="datesRange"
	Placeholder="From - To"
	// Value={datesRange}
	iconPosition="left"
	onChange={handleChange}
	dateFormat={"MM/DD/YYYY"}
	onBlur={blurDates}
	style={{ width: 180}}
	/>
	</Menu.Item> */}

      <ToolkitProvider
        keyField="id"
        data={data}
        columns={columns}
        search
        exportCSV={{
          fileName: "transaction.csv",
          blobType: "text/csv;charset=ansi",
        }}
      >
        {(props) => (
          <div>
            <span className="pr-5 mr-auto">
              <ExportCSVButton
                className="btn-outline-success"
                {...props.csvProps}
              >
                Export to Excel
              </ExportCSVButton>
            </span>
            <span className="pl-5 ml-5 mr-5 mt-5">
              <SearchBar className="form-control " {...props.searchProps} />
            </span>
            <span className="pl-4 mr-5 mt-5" style={{marginLeft:"20px"}}>
            <ClearSearchButton
              className="btn-outline-info"
              {...props.searchProps}
            />
</span>

            <hr />
            <div className="table-responsive" style={{margin:'30px'}}>
              <BootstrapTable
                keyField="id"
                columns={columns}
                filter={filterFactory()}
                striped
                hover
                condensed
                // columns={columns}
                data={data}
                pagination={paginationFactory(options)}
                {...props.baseProps}
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
}

export default Adminspecialtransaction;
