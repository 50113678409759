import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import axios from "axios";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

function Contact() {
  const [EName, setEName] = useState("");
  const [EPhone, setEPhone] = useState("");
  const [EEmail, setEEmail] = useState("");
  const [Emsg, setEmsg] = useState("");

  const Enquiryf = async () => {
    try {
      const config = {
        url: "/user/enquiry",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "application/json" },
        data: {
          EName: EName,
          EPhone: EPhone,
          EEmail: EEmail,
          Emsg: Emsg,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // integrating get method for contact us
  const [AddContactuse, setAddContactuse] = useState([]);
  const getAddContactuss = async () => {
    try {
      // alert("fdffdd")
      let res = await axios.get(
        "https://doorstepedu.info/api/user/getallContactus"
      );
      if (res.status == 200) {
        setAddContactuse(res.data.getContactusnow);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddContactuss();
  }, []);
  console.log(AddContactuse);
  return (
    <>
      <div className="contact_us mt-4 mb-3" style={{ background: "white" }}>
        <div className="container">
          <div className="row me-0">
            <div className="col-lg-6 p-0 ">
              <div className="map-contact-0">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.8162832693433!2d77.6442936!3d12.9195265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15cfb478bd83%3A0x1ff8c1818ebb7d76!2sAFS%20CNC%20%26%20LASER!5e0!3m2!1sen!2sin!4v1684832161613!5m2!1sen!2sin"
                  style={{
                    width: "100%",
                    borderRadius: "10px",
                    height: "475px",
                  }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 ">
              <div>
                <Form
                  style={{
                    // border: "1px solid rgb(0,0,0,0.2)",
                    padding: "20px",
                    boxShadow: "0 8px 32px 0 rgba(19.19.20,0.37)",
                    background: "rgba(255,255,255,0.24)",
                    backdropFilter: "blur (4px)",
                    marginLeft: "75px",
                    width: "100%",
                  }}
                  className="hdji_0"
                >
                  <h1>Enquire Now </h1>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Enter Your Name"
                  >
                    <Form.Control
                      type="text"
                      placeholder="name@example.com"
                      className="rtyui_0"
                      onChange={(e) => {
                        setEName(e.target.value);
                      }}
                    />
                  </FloatingLabel>
                  <FloatingLabel controlId="floatingInput" label="Mobile No">
                    <Form.Control
                      type="number"
                      placeholder="name@example.com"
                      className="rtyui_0"
                      onChange={(e) => {
                        setEPhone(e.target.value);
                      }}
                    />
                  </FloatingLabel>
                  <FloatingLabel controlId="floatingInput" label="Email">
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      className="rtyui_0"
                      onChange={(e) => {
                        setEEmail(e.target.value);
                      }}
                    />
                  </FloatingLabel>
                  <FloatingLabel controlId="floatingTextarea2" label="Message">
                    <Form.Control
                      as="textarea"
                      placeholder="Leave a comment here"
                      style={{ height: "100px" }}
                      className="rtyui_0"
                      onChange={(e) => {
                        setEmsg(e.target.value);
                      }}
                    />
                  </FloatingLabel>
                  <button className="btn10 mt-2" onClick={Enquiryf}>
                    Send Request
                  </button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container"
        style={{
          padding: "20px",
          boxShadow: "0 8px 32px 0 rgba(19.19.20,0.37)",
          background: "rgba(255,255,255,0.24)",
          backdropFilter: "blur (4px)",
        }}
      >
        {AddContactuse?.map((item) => {
          return (
            <div className="row">
              <div className="col-md-3">
                <div style={{ fontSize: "20px", fontWeight: "600" }}>
                  <FontAwesomeIcon
                    icon={faHouse}
                    style={{ marginRight: "15px" }}
                  />
                  Address:
                </div>
                <span className="px-2">
                  <a
                    href="#"
                    style={{
                      color: "black",
                      textDecoration: "none",
                      fontSize: "18px",
                    }}
                  >
                    {item?.Address}
                  </a>
                </span>
              </div>
              <div className="col-md-3">
                <div style={{ fontSize: "20px", fontWeight: "600" }}>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ marginRight: "15px" }}
                  />{" "}
                  Email :
                </div>

                <span className="px-2">
                  <a
                    href="#"
                    style={{
                      color: "black",
                      textDecoration: "none",
                      fontSize: "18px",
                    }}
                  >
                    {item?.Email}
                  </a>
                </span>
              </div>
              <div className="col-md-3">
                <div
                  className=""
                  style={{ fontSize: "17px", fontWeight: "600" }}
                >
                  {" "}
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ marginRight: "15px" }}
                  />
                  Contact number
                </div>

                <span className="px-2">
                  <a
                    href="#"
                    style={{
                      color: "black",
                      textDecoration: "none",
                      fontSize: "18px",
                    }}
                  >
                    +91-{item?.Phone}
                  </a>
                </span>
              </div>
              <div className="col-md-3">
                <div
                  className=""
                  style={{ fontSize: "17px", fontWeight: "600" }}
                >
                  {" "}
                  <WhatsAppIcon style={{ marginRight: "15px", fontWeight: "bold" }} />
                  WhatsApp number{" "}
                </div>

                <span className="px-2"> +91-{item?.whatsapp} </span>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default Contact;
