import React, { useEffect, useState } from "react";
import { Button, Table, Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import axios from "axios";

const AdminBranches = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // integrating post method
  const formdata = new FormData();
  const [BImg, setBImg] = useState("");
  const [BName, setBName] = useState("");
  const [BAddress, setBAddress] = useState("");
  const [BMobile, setBMobile] = useState("");

  const Addbranchess = async () => {
    formdata.append("BImg", BImg);
    formdata.append("BName", BName);
    formdata.append("BAddress", BAddress);
    formdata.append("BMobile", BMobile);

    try {
      if (!BImg) {
        return alert("please add Image");
      }
      if (!BName) {
        return alert("Please add Name");
      }
      if (!BAddress) {
        return alert("please add Address");
      }
      if (!BMobile) {
        return alert("Please add Mobile Number");
      }
      if (BMobile.length > 10) {
        return alert("Please enter a valid mobile no.");
      }
      const config = {
        url: "/user/branch",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddbranches();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [Addbranches, setAddbranches] = useState([]);
  const getAddbranches = async () => {
    try {
      let res = await axios.get("https://doorstepedu.info/api/user/getbranch");
      if (res.status === 200) {
        setAddbranches(res.data.getbranch);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data, setData] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setData(item);
  };
  const deletebranches = async () => {
    try {
      const config = {
        url: "user/deletebranch/" + Data,
        method: "delete",
        baseURL: "https://doorstepedu.info/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getAddbranches();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [Data1, setData1] = useState("");
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setData1(item);
  };

  const editbranches = async (e) => {
    e.preventDefault();

    // if (!BImg) {
    //   alert("Select the image");
    // } else {
    formdata.append("BImg", BImg);
    formdata.append("BName", BName);
    formdata.append("BAddress", BAddress);
    formdata.append("BMobile", BMobile);

    formdata.append("id", Data1?._id);
    try {
      if (BMobile.length > 10) {
        return alert("Please enter a valid mobile no.");
      }
      const config = {
        url: "/user/editbranch",
        method: "put",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose1();
          getAddbranches();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  //  integrating post method for background image
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  // integrating post method

  const [BrImage, setBrImage] = useState("");
  const [BrText, setBrText] = useState("");
  const BranchBg = async () => {
    formdata.set("BrImage", BrImage);
    formdata.set("BrText", BrText);

    try {
      const config = {
        url: "/user/BranchBg",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getBranchBg();
        handleClose4();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddBranchBg, setAddBranchBg] = useState([]);
  const getBranchBg = async () => {
    try {
      let res = await axios.get("https://doorstepedu.info/api/user/getBranchBg");
      if (res.status === 200) {
        setAddBranchBg(res.data.getBranchBg);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data5, setData5] = useState("");
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = (item) => {
    setShow5(true);
    setData5(item);
  };
  const DeleteBranchBg = async () => {
    try {
      const config = {
        url: "user/DeleteBranchBg/" + Data5,
        method: "delete",
        baseURL: "https://doorstepedu.info/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getBranchBg();
          handleClose5();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [Data6, setData6] = useState("");
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = (item) => {
    setShow6(true);
  };

  const editBranchBg = async (e) => {
    e.preventDefault();
    //  if (!BrImage) {
    //    alert("Select the image");
    //  } else {
    formdata.set("BrImage", BrImage);
    formdata.set("BrText", BrText);
    formdata.set("id", Data6?._id);
    try {
      const config = {
        url: "/user/editBranchBg",
        method: "put",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose6();
          getBranchBg();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  // };
  useEffect(() => {
    getAddbranches();
    getBranchBg()
  }, []);
  console.log(Addbranches);
  console.log(AddBranchBg);
  return (
    <div>
      <div className="add-gr">
        <div className="container">
          <div className="ad-b mt-4 mb-4">
            <button className="btn btn-success" onClick={handleShow}>
              Add Branches
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra mt-5">
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Image</th>
                <th>Branch Name</th>
                <th>Address</th>
                <th>Mobile No</th>

                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {Addbranches?.map((item, i) => {
                return (
                  <tr>
                    <td>{++i}</td>
                    <td>
                      <Image
                        src={`https://doorstepedu.info/Branches/${item?.BImg}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      />
                    </td>
                    <td style={{ paddingTop: "20px" }}>{item?.BName}</td>

                    <td style={{ paddingTop: "20px" }}>{item?.BAddress}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.BMobile}</td>

                    <td>
                      <div className="ad-b ">
                        <span>
                          <i
                            class="fas fa-edit"
                            style={{
                              fontSize: "20px",
                              color: "green",
                              paddingTop: "20px",
                            }}
                            onClick={() => handleShow1(item)}
                          // title="edit"
                          ></i>
                        </span>
                      </div>
                    </td>
                    <td>
                      <i
                        class="fa fa-trash"
                        aria-hidden="true"
                        style={{
                          color: "red",
                          fontSize: "20px",
                          paddingTop: "20px",
                        }}
                        onClick={() => handleShow2(item?._id)}
                      // title="delete"
                      ></i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <div className="add-gr">
        <div className="container rtyui_1">
          <h4>Add Branch BackgroundImage</h4>
          <div className="ad-b mt-4 mb-4">
            {AddBranchBg.length !== 0 ? (
              <></>
            ) : (
              <>
                <button className="btn btn-success" onClick={handleShow4}>
                  Add Branches BackGround Images
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra mt-5">
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>BackGround Image</th>
                <th>Text</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {AddBranchBg?.map((item, i) => {
                return (
                  <tr>
                    <td>{++i}</td>
                    <td>
                      <img
                        src={`https://doorstepedu.info/Branchesbg/${item?.BrImage}`}
                        style={{ width: "75px", height: "75px" }}
                        alt=""
                      />
                    </td>

                    <td style={{ paddingTop: "20px" }}>{item?.BrText}</td>

                    <td>
                      <div className="ad-b ">
                        <span>
                          <i
                            class="fas fa-edit"
                            style={{
                              fontSize: "20px",
                              color: "green",
                              paddingTop: "20px",
                            }}
                            onClick={() => {
                              handleShow6(item);
                              setData6(item);
                            }}
                          // title="edit"
                          ></i>
                        </span>
                      </div>
                    </td>
                    <td>
                      <i
                        class="fa fa-trash"
                        aria-hidden="true"
                        style={{
                          color: "red",
                          fontSize: "20px",
                          paddingTop: "20px",
                        }}
                        onClick={() => {
                          handleShow5(item?._id);
                        }}
                      // title="delete"
                      ></i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>Add Branches</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="floatingInput" label="Image">
            <Form.Control
              type="file"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setBImg(e.target.files[0])}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingInput" label="Enter Branch Name">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setBName(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Address">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setBAddress(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Mobile No">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setBMobile(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#00a695", border: "none" }}
            onClick={Addbranchess}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={deletebranches}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>Update Branches</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="floatingInput" label="Image">
            <Form.Control
              type="file"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setBImg(e.target.files[0])}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingInput" label="Enter Branch Name">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setBName(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Address">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setBAddress(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Mobile No">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setBMobile(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={editbranches}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }} > Add Branches BackGround Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="floatingInput" label="Image">
            <Form.Control
              type="file"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setBrImage(e.target.files[0])}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingInput" label="Enter Text">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setBrText(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#00a695", border: "none" }}
            onClick={BranchBg}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show6} onHide={handleClose6}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>Update Branches Background image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="floatingInput" label="Image">
            <Form.Control
              type="file"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setBrImage(e.target.files[0])}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingInput" label="Enter Text">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setBrText(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose6}>
            Close
          </Button>
          <Button variant="primary" onClick={editBranchBg}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show5} onHide={handleClose5}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose5}>
            Close
          </Button>
          <Button variant="primary" onClick={DeleteBranchBg}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AdminBranches;
