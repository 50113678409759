import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {Search,CSVExport} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { dateFilter } from "react-bootstrap-table2-filter";
import DatePicker from "react-multi-date-picker";

function Adminfeepaid() {
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;

  const [endDate, setendDate] = useState(new Date());
  const [startDate, setstartDate] = useState(new Date());

  const columns = [
    {
      dataField: "noticeboardtitle",
      text: "Name",
    },
    {
      dataField: "noticeboarddate",
      text: "Date",
    },
    {
      dataField: "noticeboardsubject",
      text: "Standard",
    },
    {
      dataField: "noticeboardcontent",
      text: "Amount",
    },
    {
      dataField: "noticeboardclassname",
      text: "Paid",
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total px-4">
      Showing {from} to {to} of {size} Results
    </span>
  );
  function getDateObject(datevalue) {
    //  return new Date(datevalue);
    let d = new Date(datevalue);
    //let d=moment(datevalue).format('DD/MM/YYYY');
    let v = d.getDate() + "/" + d.getMonth() + "/" + d.getFullYear();
    return v;
  }

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  // data.filter((data) => {
  //   console.log(new Date(data.date.split("-").reverse().join("-")));
  //   console.log(Date.parse(data.date));
  // });
  // const search = () => {
  //   const filteredData = data1.filter(
  //     (data) =>
  //       new Date(data.date.split("-").reverse().join("-")) >=
  //         new Date(startDate.split("-").reverse().join("-")) &&
  //       new Date(data.date.split("-").reverse().join("-")) <=
  //         new Date(endDate.split("-").reverse().join("-"))
  //   );
  //   setdata(filteredData);
  // };

  return (
    <div style={{ background: "#fff" }}>
      <h2 style={{ color: "#01a695", textAlign: "center" }}>Fee Paid</h2>
      <div className="row pb-3 px-3">
        <div className="col-md-2">
          {/* <h7>Date From</h7> */}
          {/* <DatePicker
            style={{ height: "35px", width: "140px", marginRight: "-5px" }}
            placeholder="DD-MM-YYYY"
            format="DD-MM-YYYY"
            type="input-icon"
            onChange={(date) => setstartDate(date.format("DD-MM-YYYY"))}
          />
        </div>
        <div className="col-md-2">
          <h7>Date To</h7>
          <DatePicker
            style={{ height: "35px", width: "140px", marginRight: "-5px" }}
            placeholder="DD-MM-YYYY"
            format="DD-MM-YYYY"
            type="input-icon"
            onChange={(date) => setendDate(date.format("DD-MM-YYYY"))}
          /> */}
        </div>
        {/* <div className="col-md-2">
          <button
            className="btn btn-primary"
            style={{ marginTop: "20px" }}
            onClick={search}
          >
            filter
          </button>
        </div> */}
      </div>
      <ToolkitProvider
        keyField="id"
        data={data}
        columns={columns}
        search
        exportCSV={{
          fileName: "transaction.csv",
          blobType: "text/csv;charset=ansi",
        }}
      >
        {(props) => (
          <div>
            <span className="px-3 mr-auto ">
              <ExportCSVButton
                className="btn-outline-success"
                {...props.csvProps}
              >
                Export to Excel
              </ExportCSVButton>
            </span>
            <span className="pl-4 mr-5 mt-5">
              <SearchBar className="form-control " {...props.searchProps} />
            </span>
            <span className="pl-4 mr-5 mt-5" style={{marginLeft:"20px"}}>
            <ClearSearchButton
              className="btn-outline-info"
              {...props.searchProps}
            />
</span>

            <hr />
            <div className="table-responsive" style={{margin:'30px'}}>
              <BootstrapTable
                keyField="id"
                striped
                hover
                condensed
                columns={columns}
                data={data}
                pagination={paginationFactory(options)}
                {...props.baseProps}
              />
            </div>
          </div>
        )}
      </ToolkitProvider>

      {/* <Table striped bordered hover size="sm">
  <thead>
    <tr>
      <th>S.no</th>
      <th>noticeboard Name</th>
      <th>Amount</th>
      <th>User name</th>
      <th>User Email</th>
      
      <th >Booked Date</th>
      <th >noticeboard Date</th>
      <th>Payment Method</th>
      
    </tr>
  </thead>
  <tbody>
    {data?.map((e)=>(
       <tr>
       <td>1</td>
       <td>{e.noticeboard[0].noticeboardtitle}</td>
       <td>{e.noticeboard[0].noticeboardamount}</td>
       <td>{e.user[0].name}</td>
    <td>{e.user[0].email}</td>
    <td>{e.noticeboarddate}</td>
    <td>{e.bookedtime}</td>
    <td>{e.paymentmethod}</td>
       
     </tr>
    ))}
   
    

  <a href="https://wa.me/country_code_and_your_phone_number" className="whatsapp_float" target="_blank"> <i className="fa fa-whatsapp whatsapp-icon"></i></a>

    
   
  </tbody>
</Table> */}
    </div>
  );
}

export default Adminfeepaid;
