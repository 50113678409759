import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Card from "react-bootstrap/Card";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
// import OwlCarousel from "react-owl-carousel";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import { useNavigate } from "react-router-dom";


const Aboutus = () => {
  const [viewTutor, setviewTutor] = useState("");
  const navigate = useNavigate();
  const handlenavigate = () => {
    console.log("hjhjhj", viewTutor);
    navigate("/tutor", { state: viewTutor });
  };
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  // integrating get method for about us
  const [aboutusview, setaboutusview] = useState({});
  const [show55, setShow55] = useState(false);

  const handleClose55 = () => setShow55(false);
  const handleShow55 = () => setShow55(true);
  const [Adddaboutus, setAdddaboutus] = useState([]);
  const getaddaboutus = async () => {
    try {
      let res = await axios.get("https://doorstepedu.info/api/User/getallaboutus");
      if (res.status === 200) {
        setAdddaboutus(res.data.getAddaboutus);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating get  method for faculty page
  const [Addfaculty, setAddfaculty] = useState([]);
  const getAddfaculty = async () => {
    try {
      let res = await axios.get("https://doorstepedu.info/api/user/getfaculty");
      if (res.status === 200) {
        setAddfaculty(res.data.getfaculty);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating get  method
  const [AddGalery, setAddGalery] = useState([]);
  const getAddGallery = async () => {
    try {
      let res = await axios.get("https://doorstepedu.info/api/user/getgallery");
      if (res.status === 200) {
        setAddGalery(res.data.getgallery);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating get  method for whychooseus
  const [whychooseus, setwhychooseus] = useState({});
  const [show66, setShow66] = useState(false);

  const handleClose66 = () => setShow66(false);
  const handleShow66 = () => setShow66(true);
  const [AddWhyChooseus, setAddWhyChooseus] = useState([]);
  const getAddWhyChooseus = async () => {
    try {
      let res = await axios.get(
        "https://doorstepedu.info/api/user/getWhyChooseus"
      );
      if (res.status === 200) {
        setAddWhyChooseus(res.data.getWhyChooseus);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getaddaboutus();
    getAddfaculty();
    getAddGallery();
    getAddWhyChooseus();
  }, []);
  console.log(Adddaboutus);
  console.log(Addfaculty);
  console.log(AddGalery);
  console.log(AddWhyChooseus);
  return (
    <>
      <div className="">
        <div
          class="module mid"
          style={{
            height: "80px",
            background: "#d1ccc0",
            overflow: " hidden",
            // background: " #C33764",
            //   background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
            //  url("Header 2.jpg")`,
            backgroundSize: "cover",
          }}
        >
          <h2>About Us</h2>
          <ul
            class=" container breadcrumbs bc3 pt-3 pb-3"
            role="menubar"
            aria-label="breadcrumbs"
          >
            <li>
              <a href="/">Home</a>{" "}
              <span style={{ color: "#092746" }}>&raquo;</span>
            </li>
            <li style={{ color: "#092746" }}> About Us</li>
          </ul>
        </div>
      </div>

      <div className="container mt-4">
        {Adddaboutus?.map((item) => {
          return (
            <div className="row">
              <div className="col-md-8" style={{ height: "100%" }}>
                <p style={{ textAlign: "justify" }} className="card_text_p">
                  <span className="capital">{item?.ACapital}</span>
                  {item?.AText.slice(0, 500) < item?.AText.slice(0, 501) ? (<>{item?.AText.slice(0, 500)}... <br />
                    <span
                      className="viewmore text-dark"
                      onClick={() => {
                        handleShow55();
                        setaboutusview(item);
                      }}
                      style={{
                        cursor: "pointer",


                      }}
                    >
                      View More
                    </span>
                  </>) : (<>{item?.AText}</>)} <br />

                </p>
              </div>
              <div className="col-md-4">
                <img
                  src={`https://doorstepedu.info/Aboutus/${item?.AImage}`}
                  alt="pic"
                  style={{ width: "100%", height: "250px" }}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{ backgroundColor: "#141522", paddingBottom: "30px" }}
        id="faculty"
      >
        <div className="container mt-2 pt-3">
          <div className="row hjklo_9">
            <div className="col-md-8 text-white">
              <h1>
                Our <span style={{ color: " rgb(0, 166, 149)" }}> Team</span>
              </h1>
            </div>
            <div className="col-md-4">
              <div id="container">
                <button className="btn11" onClick={handleShow4}></button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Carousel responsive={responsive} autoPlay={true} autoPlaySpeed={2000}>
            {Addfaculty?.map((item) => {
              return (
                <div>
                  <Card style={{ width: '20rem', height: "415px" }} className="ms-4">
                    <Card.Img variant="top" src={`https://doorstepedu.info/Faculty/${item?.FImg}`} style={{ height: "200px" }} />
                    <Card.Body>

                      <Card.Text className="card_text_p">
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                          }}
                        >
                          Name :
                        </span>{" "}
                        {item?.FName}
                        <br />
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                          }}
                        >
                          Qualification:
                        </span>
                        {item?.FQualification}
                        <br />
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                          }}
                        >
                          Expreience:
                        </span>{" "}
                        {item?.FExprience}
                        <br />
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                          }}
                        >
                          Specialization:
                        </span>
                        {item?.FSpecialization}
                        <br />
                      </Card.Text>
                      <div id="container">
                        <button
                          className="btn4"
                          onClick={() => {
                            setviewTutor(item)
                            handleShow2();
                          }}
                        ></button>
                      </div>
                    </Card.Body>
                  </Card>
                  {/* <Card style={{ width: "20rem", marginLeft: "30px" }}>
                    <div className="wrapper">
                      <div className="image">
                        <img
                          src={`https://doorstepedu.info/Faculty/${item?.FImg}`}
                          alt=""
                          style={{ width: "100%" }}
                        />
                        <div className="content1">
                          <p>
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              Name :
                            </span>{" "}
                            {item?.FName}
                          </p>
                          <p>
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              Qualification:
                            </span>
                            {item?.FQualification}
                          </p>
                          <p>
                            {" "}
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              Expreience:
                            </span>{" "}
                            {item?.FExprience}
                          </p>
                          <p>
                            {" "}
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              Specialization:
                            </span>
                            {item?.FSpecialization}
                          </p>
                          <div id="container">
                            <button
                              className="btn4"
                              onClick={() => {
                                setviewTutor(item)
                                handleShow2();
                              }}
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card> */}
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
      <div className="container mt-2 ">
        <div className="row hjklo_9">
          <div className="col-md-12">
            <h1>
              Why Choose<span style={{ color: " rgb(0, 166, 149)" }}> Us</span>
            </h1>
          </div>
        </div>
      </div>
      <div className="container">
        {AddWhyChooseus?.map((item) => {
          return (
            <div className="row">
              <div className="col-md-4">
                <img
                  src={`https://doorstepedu.info/WhyChooseus/${item?.WImage}`}
                  alt=""
                  style={{ width: "100%", height: "200px" }}
                />
              </div>
              <div className="col-md-8">
                <p style={{ textAlign: "justify" }} className="card_text_p">
                  {item?.WText.slice(0, 500) < item?.WText.slice(0, 501) ? (<>{item?.WText.slice(0, 500)}...
                    <br />
                    <span
                      className="viewmore text-dark"
                      onClick={() => {
                        handleShow66();
                        setwhychooseus(item);
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      View More
                    </span>
                  </>) : (<>{item?.WText}</>)} <br />

                </p>
              </div>
            </div>
          );
        })}
      </div>
      <br />
      <div className="container mt-3 " id="gallery">
        <div className="row hjklo_9">
          <div className="col-md-8">
            <h1>Gallery</h1>
          </div>
          <div className="col-md-4">
            <a href="/gallery">
              <div id="container">
                <button className="btn11"></button>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row  hjklo_9 ">
          {AddGalery?.slice(0, 8)?.map((item) => {
            return (
              <div className="col-md-3 text-center hhjou_0">
                <img
                  src={`https://doorstepedu.info/Gallery/${item?.GImage}`}
                  alt=""
                  style={{ width: "100%", height: "250px" }}
                />
                <h5 style={{ fontFamily: "initial" }}>{item?.GText}</h5>
              </div>
            );
          })}
        </div>
      </div>
      <Modal show={show55} onHide={handleClose55} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>About Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          {aboutusview?.ACapital}
          {aboutusview?.AText}
        </Modal.Body>
        <Modal.Footer className="aboutus">
          <Button variant="secondary" onClick={handleClose55}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <FloatingLabel controlId="floatingInput" label="Mobile No">
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button style={{ backgroundColor: "#00a695", border: "none" }} onClick={handleShow3}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <FloatingLabel controlId="floatingInput" label="Enter OTP">
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#00a695", border: "none" }}
            onClick={handlenavigate}
          >
            Validate
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show4} onHide={handleClose4}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <FloatingLabel controlId="floatingInput" label="Mobile No">
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
          <Button style={{ backgroundColor: "#00a695", border: "none" }} onClick={handleShow5} >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show66} onHide={handleClose66} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Why Choose Us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <img
            src={`https://doorstepedu.info/WhyChooseus/${whychooseus?.WImage}`}
            alt=""
            style={{ width: "100%", height: "250px" }}
          />
          {whychooseus?.WText}
        </Modal.Body>
        <Modal.Footer className="aboutus">
          <Button variant="secondary" onClick={handleClose66}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show5} onHide={handleClose5}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <FloatingLabel controlId="floatingInput" label="Enter OTP">
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose5}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#00a695", border: "none" }}

          >
            <a href="/faculty" style={{ textDecoration: "none", color: "white" }}>Validate</a>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Aboutus;
