import React from "react";

const Mcqtest = () => {
  return (
    <>
       <div className="">
          <div
            class="module mid"
            style={{
              height: "80px",
              background: "#d1ccc0",
              overflow: " hidden",
              // background: " #C33764",
            //   background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
            //  url("Header 2.jpg")`,
              backgroundSize: "cover",
            }}
          >
            <h2>MCQ Tests</h2>
            <ul
              class=" container breadcrumbs bc3 pt-3 pb-3"
              role="menubar"
              aria-label="breadcrumbs"
            >
              <li>
                <a href="/">Home</a>{" "}
                <span style={{ color: "#092746" }}>&raquo;</span>
              </li>
              <li style={{ color: "#092746" }}> MCQ Tests</li>
            </ul>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              
            </div>
          </div>
        </div>


    
    </>
  );
};

export default Mcqtest;
