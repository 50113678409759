import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const AdminHometution = () => {
  const [getHometutionn, setgetHometution] = useState([]);

  const gethometution = async () => {
    let res = await axios.get("https://doorstepedu.info/api/user/getHometution");
    if (res.status === 200) {
      setgetHometution(res.data.gethometutionn);

    }
  };
  const [show4, setShow4] = useState(false);
  const [Data, setData] = useState("");
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (data) => {
    setShow4(true);
    setData(data);
  };
  const Deletehometution = async () => {
    try {
      const config = {
        url: "/user/deleteHometution/" + Data,
        method: "delete",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          gethometution();
          handleClose4();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    gethometution();
  }, []);
  console.log(getHometutionn);
  return (
    <>
      <div className="container  mt-5">

        <div className="row">
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Class Type</th>
                <th>Name</th>
                <th>Grade</th>
                <th>Board</th>
                <th>Subject</th>
                <th>Email</th>
                <th>Contact No.</th>
                <th>Address</th>
                <th>Message</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {getHometutionn?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{item?.EClassType}</td>
                    <td>{item?.ETutionName}</td>
                    <td>{item?.ETutionGrade}</td>
                    <td>{item?.ETutionBoard}</td>
                    <td>{item?.ETutionSubject}</td>
                    <td>{item?.ETutionEmail}</td>
                    <td>{item?.ETutionPhone}</td>
                    <td>{item?.EtutionAddress}</td>
                    <td>{item?.ETutionMsg}</td>
                    <td>
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={handleShow4(item?._id)}
                        style={{ color: "red" }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal
        show={show4}
        onHide={handleClose4}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
          <Button onClick={Deletehometution}>Delete </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminHometution;
