import { Button } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";

function Adminsubadmin() {
  const [data, setdata] = useState([]);
  const [show, setshow] = useState(false);
  const [selected, setselected] = useState({});
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;

  useEffect(() => {
    getdisease();
  }, []);

  const getdisease = () => {
    try {
      axios
        .get(`https://doorstepedu.info/api/getallsubadmin`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata(response.data.subadmin);
            return;
          } else {
            //alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };

  const remove = async (data) => {
    try {
      axios
        .post(`https://doorstepedu.info/api/deletesubadmin/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };
  return (
    <div style={{ background: "" }}>
      <div className="container pb-5">
        <h1 className="pt-4 pb-3" style={{ color: "#28328c" }}>
          Sub-Admin
        </h1>
        <div className="container">
          <div className="card card-body">
            <div>
              {/* <h5 className="pb-3">Latest Orders</h5> */}
              <div className="container text-center">
                <Table responsive hover size="md">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile Number</th>
                      <th>Access Modules</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((datas) => (
                      <tr>
                        <td>{datas.name}</td>
                        <td>{datas.email}</td>
                        <td>{datas.phoneNumber}</td>
                        <td>
                          <Button
                            onClick={() => {
                              setshow(true);
                              setselected(datas);
                            }}
                          >
                            View
                          </Button>
                        </td>
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => remove(datas)}
                          >
                            Delete
                          </button>
                        </td>
                        {/* <td>{datas.diseases}</td>                                                                                                                        */}
                        {/* <td><div className="d-flex"> <button className="btn btn-outline-success" onClick={()=>approve(datas)}>Approve</button></div></td> */}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={show}
          onHide={() => setshow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Selected Access Module
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div>{selected.summary ? selected.summary : "No Summary found"}</div>                     */}
            <div className="row ml-5">
              <div className="col-md-6">Sub-Admin</div>
              {selected.subadmin ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Slider</div>
              {selected.slider ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Events</div>
              {selected.events ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Special Events</div>
              {selected.specialevent ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Booking</div>
              {selected.booking ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Transactions</div>
              {selected.transaction ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">User</div>
              {selected.user ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Create Events</div>
              {selected.createevent ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
              <div className="col-md-6">Donate</div>
              {selected.donate ? (
                <div className="col-md-6">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </div>
              ) : (
                <div className="col-md-6">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default Adminsubadmin;
