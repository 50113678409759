import React, { useEffect, useState } from "react";
import { Button, Table, Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import axios from "axios";

const AdminPlacements = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // integrating post method
  const formdata = new FormData();
  const [PHeader, setPHeader] = useState("");
  const [PImage, setPImage] = useState("");
  const [PText1, setPText1] = useState("");
  const [PCounts, setPCounts] = useState("");
  const [CCount, setCCount] = useState("");

  const AdddPlacements = async () => {
    formdata.append("PHeader", PHeader);
    formdata.append("PImage", PImage);
    formdata.append("PText1", PText1);
    formdata.append("PCounts", PCounts);
    formdata.append("CCount", CCount);

    try {
      const config = {
        url: "/user/Placements",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddPlacements();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //   //integrating get  method
  const [AddPlacements, setAddPlacements] = useState([]);
  const getAddPlacements = async () => {
    try {
      let res = await axios.get("https://doorstepedu.info/api/user/getPlacements");
      if (res.status === 200) {
        setAddPlacements(res.data.getPlacements);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data, setData] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setData(item);
  };
  const DeletePlacements = async () => {
    try {
      const config = {
        url: "user/DeletePlacements/" + Data,
        method: "delete",
        baseURL: "https://doorstepedu.info/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getAddPlacements();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [Data1, setData1] = useState("");
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setData1(item);
  };

  const editPlacements = async (e) => {
    e.preventDefault();
    // if (!PHeader) {
    //   alert("Select the image");
    // } else {
    formdata.append("PHeader", PHeader);
    formdata.append("PImage", PImage);
    formdata.append("PText1", PText1);
    formdata.append("PCounts", PCounts);
    formdata.append("CCount", CCount);

    formdata.append("id", Data1?._id);
    try {
      const config = {
        url: "/user/editPlacements",
        method: "put",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose1();
          getAddPlacements();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  }
  // };

  useEffect(() => {
    getAddPlacements();
  }, []);
  console.log(AddPlacements);
  return (
    <>

      <div className="add-gr">
        <div className="container">
          <div className="ad-b mt-4 mb-4">
            {AddPlacements?.length !== 0 ? (
              <></>
            ) : (
              <>
                <button className="btn btn-success" onClick={handleShow}>
                  Add Placements Data
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra mt-5">
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Placement Image</th>
                <th>Header</th>
                <th>Placed Students Counts</th>
                <th>No. of Companies Counts</th>
                <th>Placement Description</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {AddPlacements?.map((item, i) => {
                return (
                  <tr>
                    <td>{++i}</td>
                    <td>
                      <img
                        src={`https://doorstepedu.info/Placements/${item?.PImage}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      />
                    </td>
                    <td>{item?.PHeader}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.PCounts}</td>
                    <td style={{ paddingTop: "20px" }}>{item?.CCount}</td>

                    <td>
                      <p className="card_text_p" style={{ overflow: "hidden auto", height: "100px" }}>
                        {item?.PText1}
                      </p>
                    </td>

                    <td>
                      <div className="ad-b ">
                        <span>
                          <i
                            class="fas fa-edit"
                            style={{
                              fontSize: "20px",
                              color: "green",
                              paddingTop: "20px",
                            }}
                            onClick={() => handleShow1(item)}
                          // title="edit"
                          ></i>
                        </span>
                      </div>
                    </td>
                    <td>
                      <i
                        class="fa fa-trash"
                        aria-hidden="true"
                        style={{
                          color: "red",
                          fontSize: "20px",
                          paddingTop: "20px",
                        }}
                        onClick={() => handleShow2(item?._id)}
                      // title="delete"
                      ></i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>
            {" "}
            Add Placements Data
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="floatingInput" label="Placement Image">
            <Form.Control
              type="file"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setPImage(e.target.files[0])}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingInput" label="Header">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setPHeader(e.target.value)}
            />
          </FloatingLabel>{" "}
          <FloatingLabel
            controlId="floatingInput"
            label="Placed Students Counts"
          >
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setPCounts(e.target.value)}
            />
          </FloatingLabel>{" "}
          <FloatingLabel
            controlId="floatingInput"
            label="No of Companies Counts"
          >
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setCCount(e.target.value)}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingTextarea2" label="Text">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              className="rtyui_0"
              onChange={(e) => setPText1(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#00a695", border: "none" }}
            onClick={AdddPlacements}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>Update Placements Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="floatingInput" label="Placement Image">
            <Form.Control
              type="file"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setPImage(e.target.files[0])}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingInput" label="Header">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setPHeader(e.target.value)}
            />
          </FloatingLabel>{" "}
          <FloatingLabel
            controlId="floatingInput"
            label="Placed Students Counts"
          >
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setPCounts(e.target.value)}
            />
          </FloatingLabel>{" "}
          <FloatingLabel
            controlId="floatingInput"
            label="No of Companies Counts"
          >
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setCCount(e.target.value)}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingTextarea2" label="Text">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              className="rtyui_0"
              onChange={(e) => setPText1(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#00a695", border: "none" }}
            onClick={editPlacements}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={DeletePlacements}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminPlacements;
