import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap/cjs";
import { Card } from "react-bootstrap";
import { BsArrowUp } from "react-icons/bs";
import axios from "axios";

function Admindashboard() {
  // no of faculty
  const [Addfaculty, setAddfaculty] = useState([]);
  const getAddfaculty = async () => {
    try {
      let res = await axios.get("https://doorstepedu.info/api/user/getfaculty");
      if (res.status === 200) {
        setAddfaculty(res.data.getfaculty);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // no of mcq/subjective enquireies
  const [gettestEnquiry, setgettestEnquiry] = useState([]);
  const getalltestEnquiry = async () => {
    let res = await axios.get(
      "https://doorstepedu.info/api/user/gettestenquiry"
    );
    if (res.status === 200) {
      setgettestEnquiry(res.data.gettestEnquiry);
    }
  };
  // no of online tution
  const [getTutiononline, setgetTutiononline] = useState([]);
  const getalltutuion = async () => {
    let res = await axios.get(
      "https://doorstepedu.info/api/user/getonlinetutionn"
    );
    if (res.status === 200) {
      setgetTutiononline(res.data.getallonlinetution);
    }
  };
  // no of home tution
  const [getHometutionn, setgetHometution] = useState([]);

  const gethometution = async () => {
    let res = await axios.get(
      "https://doorstepedu.info/api/user/getHometution"
    );
    if (res.status === 200) {
      setgetHometution(res.data.gethometutionn);
    }
  };
  // no. of training and placement enquiries

  const [getTrainingEnquiryy, setgetTrainingEnquiryy] = useState([]);

  const getTrainingEnquiry = async () => {
    let res = await axios.get(
      "https://doorstepedu.info/api/user/getTrainingEnquiry"
    );
    if (res.status === 200) {
      setgetTrainingEnquiryy(res.data.getTrainingEnquiry);
    }
  };
  // no of become a tutor
  const [getBcTutor, setgetBcTutor] = useState([]);
  const getBcTutors = async () => {
    let res = await axios.get("https://doorstepedu.info/api/user/getBcTutor");
    if (res.status === 200) {
      setgetBcTutor(res.data.getBcTutornow);
    }
  };

  useEffect(() => {
    getAddfaculty();
    getalltestEnquiry();
    getalltutuion();
    gethometution();
    getTrainingEnquiry();
    getBcTutors();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Card
            className="dashcard"
            style={{
              width: "18rem",
              height: "10rem",
              backgroundColor: "rgb(8 163 147)",
              margin: "5px",
            }}
          >
            <Card.Body>
              <Card.Title
                style={{ color: "white", textAlign: "center" }}
                className="card_text_p"
              >
                Number of Faculties
              </Card.Title>
              <hr />
              <br />
              <Card.Text
                style={{
                  color: "white",
                  fontSize: "20px",
                  textAlign: "center",
                }}
                className="card_text_p card_textt"
              >
                <span>
                  ( {Addfaculty.length} +) <BsArrowUp />
                </span>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card
            className="dashcard"
            style={{
              width: "18rem",
              height: "10rem",
              backgroundColor: "#321fdb",
              margin: "5px",
            }}
          >
            <Card.Body>
              <Card.Title
                style={{ color: "white", textAlign: "center" }}
                className="card_text_p"
              >
                Number of MCQ/ Subjective Test Enquiries
              </Card.Title>
              <hr />
              <br />
              <Card.Text
                style={{
                  color: "white",
                  fontSize: "20px",
                  textAlign: "center",
                }}
                className="card_text_p"
              >
                <span>
                  ( {gettestEnquiry.length} +) <BsArrowUp />
                </span>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card
            className="dashcard"
            style={{
              width: "18rem",
              height: "10rem",
              backgroundColor: "#3399ff",
              margin: "5px",
            }}
          >
            <Card.Body>
              <Card.Title
                style={{ color: "white", textAlign: "center" }}
                className="card_text_p"
              >
                Number of Online Tuition Enquiries
              </Card.Title>
              <hr />
              <br />
              <Card.Text
                style={{
                  color: "white",
                  fontSize: "20px",
                  textAlign: "center",
                }}
                className="card_text_p"
              >
                <span>
                  ( {getTutiononline.length}+) <BsArrowUp />
                </span>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card
            className="dashcard"
            style={{
              width: "18rem",
              height: "10rem",
              backgroundColor: "#f9b115",
              margin: "5px",
            }}
          >
            <Card.Body>
              <Card.Title
                style={{ color: "white", textAlign: "center" }}
                className="card_text_p"
              >
                No. of Home Tuition / Institute Enquiries
              </Card.Title>
              <hr />
              <br />
              <Card.Text
                style={{
                  color: "white",
                  fontSize: "20px",
                  textAlign: "center",
                }}
                className="card_text_p"
              >
                <span>
                  ( {getHometutionn.length} +) <BsArrowUp />
                </span>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card
            className="dashcard"
            style={{
              width: "18rem",
              height: "10rem",
              backgroundColor: "#e55353",
              margin: "5px",
            }}
          >
            <Card.Body>
              <Card.Title
                style={{ color: "white", textAlign: "center" }}
                className="card_text_p"
              >
                Number of Training & Placement Enquiries
              </Card.Title>
              <hr />
              <br />
              <Card.Text
                style={{
                  color: "white",
                  fontSize: "20px",
                  textAlign: "center",
                }}
                className="card_text_p"
              >
                <span>
                  ( {getTrainingEnquiryy.length} +) <BsArrowUp />
                </span>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card
            className="dashcard"
            style={{
              width: "18rem",
              height: "10rem",
              backgroundColor: "#050748",
              margin: "5px",
            }}
          >
            <Card.Body>
              <Card.Title
                style={{ color: "white", textAlign: "center" }}
                className="card_text_p"
              >
                Number of Tutor Enquiries
              </Card.Title>
              <hr />
              <br />
              <Card.Text
                style={{
                  color: "white",
                  fontSize: "20px",
                  textAlign: "center",
                }}
                className="card_text_p"
              >
                <span>
                  ( {getBcTutor.length} +) <BsArrowUp />
                </span>
              </Card.Text>
            </Card.Body>
          </Card>
          {/* <Card
            className="dashcard"
            style={{
              width: "18rem",
              height: "10rem",
              backgroundColor: "#0dcaf0",
              margin: "5px",
            }}
          >
            <Card.Body>
              <Card.Title
                style={{ color: "white", textAlign: "center" }}
                className="card_text_p"
              >
                Number of Branch Enquiries
              </Card.Title>
              <hr />
              <br />
              <Card.Text
                style={{
                  color: "white",
                  fontSize: "20px",
                  textAlign: "center",
                }}
                className="card_text_p"
              >
                <span>
                  ( 20 +) <BsArrowUp />
                </span>
              </Card.Text>
            </Card.Body>
          </Card> */}

          {/* <Card
            className="dashcard"
            style={{
              width: "18rem",
              height: "10rem",
              backgroundColor: "#198754",
              margin: "5px",
            }}
          >
            <Card.Body>
              <Card.Title
                style={{ color: "white", textAlign: "center" }}
                className="card_text_p"
              >
                Number of Enquiries
              </Card.Title>
              <hr />
              <br />
              <Card.Text
                style={{
                  color: "white",
                  fontSize: "20px",
                  textAlign: "center",
                }}
                className="card_text_p"
              >
                <span>
                  ( 20 +) <BsArrowUp />
                </span>
              </Card.Text>
            </Card.Body>
          </Card> */}
        </div>
      </div>
    </>
  );
}

export default Admindashboard;
