import React, { useEffect, useState } from "react";
import { Button, Table, Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import axios from "axios";

const AdminMcq = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // integrating post method
  const formdata = new FormData();
  const [MImage1, setMImage1] = useState("");
  const [MImage2, setMImage2] = useState("");
  const [MText1, setMText1] = useState("");
  const [MHeader1, setMHeader1] = useState("");
  const [MHeader2, setMHeader2] = useState("");
  const [MText2, setMText2] = useState("");

  const AdddMcq = async () => {
    formdata.append("MImage1", MImage1);
    formdata.append("MImage2", MImage2);
    formdata.append("MText1", MText1);
    formdata.append("MHeader1", MHeader1);
    formdata.append("MHeader2", MHeader2);
    formdata.append("MText2", MText2);

    try {
      const config = {
        url: "/user/Mcq",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddMcq();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //   //integrating get  method
  const [AddMcq, setAddMcq] = useState([]);
  const getAddMcq = async () => {
    try {
      let res = await axios.get("https://doorstepedu.info/api/user/getMcq");
      if (res.status === 200) {
        setAddMcq(res.data.getMcq);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data, setData] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setData(item);
  };
  const DeleteMcq = async () => {
    try {
      const config = {
        url: "user/DeleteMcq/" + Data,
        method: "delete",
        baseURL: "https://doorstepedu.info/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getAddMcq();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [Data1, setData1] = useState("");
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
  };

  const editMcq = async (e) => {
    e.preventDefault();
    // if (!MImage1) {
    //   alert("Select the image");
    // } else {
    formdata.append("MImage1", MImage1);
    formdata.append("MImage2", MImage2);
    formdata.append("MText1", MText1);
    formdata.append("MHeader1", MHeader1);
    formdata.append("MHeader2", MHeader2);
    formdata.append("MText2", MText2);
    formdata.append("id", Data1?._id);
    try {
      const config = {
        url: "/user/editMcq",
        method: "put",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose1();
          getAddMcq();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  // };
  useEffect(() => {
    getAddMcq();
  }, []);
  console.log(AddMcq);
  return (
    <>

      <div className="add-gr">
        <div className="container">
          <div className="ad-b mt-4 mb-4">
            {AddMcq?.length !== 0 ? (
              <></>
            ) : (
              <>
                {" "}
                <button className="btn btn-success" onClick={handleShow}>
                  Add Mcq/Subjective Content
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra mt-5">
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>MCQ Image</th>
                <th>MCQ Description</th>
                <th>Subjective Image</th>
                <th>Subjective Description</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {AddMcq?.map((item, i) => {
                return (
                  <tr>
                    <td>{++i}</td>
                    <td>
                      <Image
                        src={`https://doorstepedu.info/MCQ/${item?.MImage1}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      />
                    </td>

                    <td style={{ paddingTop: "20px" }}>{item?.MText1}</td>

                    <td>
                      <Image
                        src={`https://doorstepedu.info/MCQ/${item?.MImage2}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      />
                    </td>
                    <td style={{ paddingTop: "20px" }}>{item?.MText2}</td>

                    <td>
                      <div className="ad-b ">
                        <span>
                          <i
                            class="fas fa-edit"
                            style={{
                              fontSize: "20px",
                              color: "green",
                              paddingTop: "20px",
                            }}
                            onClick={() => {
                              handleShow1();
                              setData1(item);
                            }}
                            title="edit"
                          ></i>
                        </span>
                      </div>
                    </td>
                    <td>
                      <i
                        class="fa fa-trash"
                        aria-hidden="true"
                        style={{
                          color: "red",
                          fontSize: "20px",
                          paddingTop: "20px",
                        }}
                        onClick={() => handleShow2(item?._id)}
                        title="delete"
                      ></i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>Add MCQ & Subjective Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="floatingInput" label=" MCQ Image">
            <Form.Control
              type="file"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setMImage1(e.target.files[0])}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingTextarea2" label="Description (MCQ)">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: '100px' }}
              className="rtyui_0"
              onChange={(e) => setMText1(e.target.value)}
            />
          </FloatingLabel>

          {/* <FloatingLabel controlId="floatingInput" label="Enter Header For MCQ">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setMHeader1(e.target.value)}
            />
          </FloatingLabel> */}
          <FloatingLabel controlId="floatingInput" label=" Subjective Image">
            <Form.Control
              type="file"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setMImage2(e.target.files[0])}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingTextarea2" label="Description (Subjective)">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: '100px' }}
              className="rtyui_0"
              onChange={(e) => setMText2(e.target.value)}
            />
          </FloatingLabel>

          {/* <FloatingLabel
            controlId="floatingInput"
            label="Enter Text Subjective"
          >
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setMHeader2(e.target.value)}
            />
          </FloatingLabel>{" "} */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#00a695", border: "none" }}
            onClick={AdddMcq}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>Update MCQ & Subjective Content </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="floatingInput" label=" MCQ Image">
            <Form.Control
              type="file"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setMImage1(e.target.files[0])}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingTextarea2" label="Description (MCQ)">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: '100px' }}
              className="rtyui_0"
              onChange={(e) => setMText1(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label=" Subjective Image">
            <Form.Control
              type="file"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setMImage2(e.target.files[0])}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingTextarea2" label="Description (Subjective)">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: '100px' }}
              className="rtyui_0"
              onChange={(e) => setMText2(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#00a695", border: "none" }}
            onClick={editMcq}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={DeleteMcq}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminMcq;
