import React, { useEffect, useState } from "react";
// import Carousel from "react-bootstrap/Carousel";
import AOS from "aos";
import "aos/dist/aos.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Slider from "react-slick";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Anglearrow from "./Anglearrow";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Whatsapp from "./Whatsapp/Whatsapp";
// import OwlCarousel from "react-owl-carousel";
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';

const Home = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const responsive1 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const [viewTutor, setviewTutor] = useState("");
  const navigate = useNavigate();
  const handlenavigate = () => {
    console.log("hjhjhj", viewTutor);
    navigate("/tutor", { state: viewTutor });
  };
  //integrating get  method for faculty page
  const [Addfaculty, setAddfaculty] = useState([]);
  const getAddfaculty = async () => {
    try {
      let res = await axios.get("https://doorstepedu.info/api/user/getfaculty");
      if (res.status === 200) {
        setAddfaculty(res.data.getfaculty);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating get  method for carousel
  const [AddGraph, setAddGraph] = useState([]);
  const getAddGraph = async () => {
    try {
      let res = await axios.get("https://doorstepedu.info/api/user/getgraph");
      if (res.status === 200) {
        setAddGraph(res.data.getgraph);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating get  method for Guarantee
  const [guaranteeview, setguaranteeview] = useState({});
  const [show55, setShow55] = useState(false);

  const handleClose55 = () => setShow55(false);
  const handleShow55 = () => setShow55(true);
  const [AddGuarantee, setAddGuarantee] = useState([]);
  const getAddGuarantees = async () => {
    try {
      // alert("fdffdd")
      let res = await axios.get("https://doorstepedu.info/api/user/getguarantee");
      if (res.status == 200) {
        setAddGuarantee(res.data.getguarantee);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating get  method for testimonials
  const [Testimonialview, setTestimonialview] = useState({});
  const [show44, setShow44] = useState(false);

  const handleClose44 = () => setShow44(false);
  const handleShow44 = () => setShow44(true);
  const [AddTestimonial, setAddTestimonial] = useState([]);
  const getAddTestimonials = async () => {
    try {
      // alert("fdffdd")
      let res = await axios.get(
        "https://doorstepedu.info/api/user/gettestimonials"
      );
      if (res.status === 200) {
        setAddTestimonial(res.data.gettestimonials);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating get  method for branches
  const [Addbranches, setAddbranches] = useState([]);
  const getAddbranches = async () => {
    try {
      let res = await axios.get("https://doorstepedu.info/api/user/getbranch");
      if (res.status === 200) {
        setAddbranches(res.data.getbranch);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // integrating post method for enquiry form
  const [EName, setEName] = useState("");
  const [EPhone, setEPhone] = useState("");
  const [EEmail, setEEmail] = useState("");
  const [Emsg, setEmsg] = useState("");

  const Enquiryf = async () => {
    try {
      const config = {
        url: "/user/enquiry",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "application/json" },
        data: {
          EName: EName,
          EPhone: EPhone,
          EEmail: EEmail,
          Emsg: Emsg,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // integrating post method for enquiry form
  const [EBranch, setEBranch] = useState("");
  const [ENamee, setENamee] = useState("");
  const [EPhonee, setEPhonee] = useState("");
  const [EEmaill, setEEmaill] = useState("");
  const [Emsgg, setEmsgg] = useState("");

  const branchEnquiryf = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/user/branchenquiry",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "content-type": "application/json" },
        data: {
          EBranch: EBranch,
          EName: ENamee,
          EPhone: EPhonee,
          EEmail: EEmaill,
          Emsg: Emsgg,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose66();
          window.location.reload();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // integrating post method for bctutor form
  const [BcTutor, setBcTutor] = useState("");
  const [BctPhone, setBctPhone] = useState("");
  const [BctEmail, setBctEmail] = useState("");
  const [Bctmsg, setBctmsg] = useState("");

  const EnquiryBct = async () => {
    try {

      const config = {
        url: "/user/BcTutor",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "application/json" },
        data: {
          EName: BcTutor,
          EPhone: BctPhone,
          EEmail: BctEmail,
          Emsg: Bctmsg,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose1();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // intgrating get method for guarantee background
  const [Addguaranteee, setAddguaranteee] = useState([]);
  const getAddguaranteee = async () => {
    try {
      let res = await axios.get("https://doorstepedu.info/api/user/getGuaranteee");
      if (res.status === 200) {
        setAddguaranteee(res.data.getGuarantee);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating get  method for branch background
  const [AddBranchBg, setAddBranchBg] = useState([]);
  const getBranchBg = async () => {
    try {
      let res = await axios.get("https://doorstepedu.info/api/user/getBranchBg");
      if (res.status === 200) {
        setAddBranchBg(res.data.getBranchBg);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  const [show66, setShow66] = useState(false);
  const handleClose66 = () => setShow66(false);
  const handleShow66 = () => setShow66(true);
  const [show22, setShow22] = useState(false);
  const handleClose22 = () => setShow22(false);
  const handleShow22 = () => setShow22(true);
  const [show34, setShow34] = useState(false);
  const handleClose34 = () => setShow34(false);
  const handleShow43 = () => setShow34(true);
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    getAddfaculty();
    getAddGraph();
    getAddGuarantees();
    getAddguaranteee();
    getAddTestimonials();
    getAddbranches();
    getBranchBg()
  }, []);
  console.log(Addfaculty);
  console.log(AddGraph);
  console.log(AddGuarantee);
  console.log(AddTestimonial);
  console.log(Addbranches);
  console.log(Addguaranteee);
  console.log(AddBranchBg);


  return (
    <>
      <Carousel responsive={responsive1} autoPlay={true} autoPlaySpeed={2000}>
        {AddGraph?.map((item) => {
          return (
            <div>
              <img
                className="d-block w-100"
                src={`https://doorstepedu.info/Homegraph/${item?.GrImage}`}
                alt="First slide"
                style={{ height: "450px" }}
              />
              <div className="carousel-caption" data-aos="fade-up">
                <h3>{item?.GrText}</h3>
                <p>{item?.GrText2}</p>
                <a href="/faculty">
                  <div id="container">
                    <button className="btn2"></button>
                  </div>
                </a>
              </div >
            </div>
          );
        })}
      </Carousel>

      <div className="container">
        <div className="row">
          <div className="col-md-12 rtyui_1">
            <h4>
              Favourable{" "}
              <span style={{ color: "#00a695" }}>Information........</span>{" "}
            </h4>
          </div>
        </div>
      </div>
      {Addguaranteee?.map((item) => {
        return (
          <div
            style={{
              backgroundImage: `url("https://doorstepedu.info/Guarantee/${item?.GuImage}")`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "440px",
              marginTop: "20px",
            }}
            className="nmghk_0"
          >
            <div className="container pt-5 ">
              <div className="row text-white hjklo_9 ">
                <h1>{item?.GuText}</h1>
              </div>
            </div>
            <div className="container pt-4 pb-4">
              <div className="row text-white">
                {AddGuarantee?.map((item, i) => {
                  return (
                    <div className="col-md-4 ertyu_0">
                      <span style={{ fontSize: "25px", fontFamily: "initial" }}>
                        {" "}

                        {item?.GName}

                      </span>
                      <p style={{ textAlign: "justify" }}>
                        {item?.GText1.slice(0, 200) < item?.GText1.slice(0, 201) ? (<>{item?.GText1.slice(0, 200)}...
                          <br />  <span
                            className="viewmore"
                            onClick={() => {
                              handleShow55();
                              setguaranteeview(item);
                            }}
                            style={{ cursor: "pointer", fontWeight: "bold" }}
                          >
                            View More
                          </span> </>) : (<>{item?.GText1}</>)}{" "}

                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}

      {/* <div style={{ backgroundColor: "#141522", padding: "10px 20px" }}>
        <div className="container mt-2 pt-3">
          <div className="row hjklo_9">
            <div className="col-md-8 text-white">
              <h1>
                Featured{" "}
                <span style={{ color: " rgb(0, 166, 149)" }}> Tutors </span>
              </h1>
            </div>
            <div className="col-md-4">
              <div id="container">
                <button className="btn11" onClick={handleShow22}></button>
              </div>
            </div>
          </div>
        </div>
        <Carousel responsive={responsive}autoPlay={true} autoPlaySpeed={2000}>
          {Addfaculty?.map((item) => {
            return (
              <div>
                <Card style={{ width: "20rem", marginLeft: "30px" }}>
                  <div className="wrapper">
                    <div className="image">
                      <img
                        src={`https://doorstepedu.info/Faculty/${item?.FImg}`}
                        alt=""
                        style={{ width: "100%" }}
                      />
                      <div className="content1">
                        <p>
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            Name :
                          </span>{" "}
                          {item?.FName}
                        </p>
                        <p>
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            Qualification:
                          </span>
                          {item?.FQualification}
                        </p>
                        <p>
                          {" "}
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            Expreience:
                          </span>{" "}
                          {item?.FExprience}
                        </p>
                        <p>
                          {" "}
                          <span
                            style={{
                              fontWeight: "bold",
                              fontSize: "20px",
                            }}
                          >
                            Specialization:
                          </span>
                          {item?.FSpecialization}
                        </p>
                        <div id="container">
                          <button
                            className="btn4"
                            onClick={() => {
                              setviewTutor(item);
                              handleShow2();
                            }}
                          ></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            );
          })}
        </Carousel>
        ;
      </div>
      <div className="container mt-4 mb-4">
        <div className="row hjklo_9">
          <div className="col-md-8">
            <h1>
              Want to become a tutor in{" "}
              <span style={{ color: "#00a695" }}> DoorStep EduGroup ? </span>
            </h1>
          </div>
          <div className="col-md-4">
            <div id="container">
              <button className="btn5" onClick={handleShow1}></button>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div
        style={{
          backgroundImage: "url('bg_cta.jpg')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "400px",
        }}
      >
        <div className="container">
          <div className="row hjklo_9">
            <div
              className="col-md text-white"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "10%",
              }}
            >
              <h5>Call Us 800-123-4567</h5>
              <h1>Do You Need Help ?</h1>
              <p>
                Our specialists can help you with finding the right tutor for
                you or your kids
              </p>

              <div id="container">
                <button className="btn6" onClick={handleShow}></button>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        style={{
          backgroundImage: "url('./bg5.jpg')",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100%",
          marginTop: "20px",
        }}
      >
        <div className="container pt-5">
          <div className="row text-white hjklo_9">
            <p style={{ marginLeft: "8px" }}>
              Happy Clients Said About Our Service
            </p>
            <h1>
              Our <span style={{ color: "#00a695" }}> Testimonials</span>
            </h1>
            <div className="col-md">
              <Carousel responsive={responsive} autoPlay={true} autoPlaySpeed={2000}>
                {AddTestimonial?.map((item) => {
                  return (
                    <div>
                      <Card
                        style={{
                          height: "214px",
                          marginLeft: "30px",
                          width: "90%",
                          // height: "100%",
                        }}
                      >
                        <Card.Body>
                          <Card.Text style={{ textAlign: "justify" }} className="card_text_p">
                            {item?.TText1.slice(0, 195) < item?.TText1.slice(0, 196) ? (<>{item?.TText1.slice(0, 195)}.. <br /> <span
                              onClick={() => {
                                handleShow44();
                                setTestimonialview(item);
                              }}
                              className="viewmore"
                              style={{ cursor: "pointer", fontWeight: "bold", color: "black" }}
                            >
                              View More
                            </span>  </>) : (<>{item?.TText1}</>)}

                            <br />
                            <div className="cvbn_0">
                              <span style={{ fontWeight: "bold" }}>
                                {item?.TName}
                              </span>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      {AddBranchBg?.map((item) => {
        return (
          <div
            style={{
              backgroundImage: `url("https://doorstepedu.info/Branchesbg/${item?.BrImage}")`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "100%",
            }}
          >

            <div className="container mt-4 pt-4">
              <div className="row hjklo_9 text-white  me-0">
                <div className="col-md-7">
                  <h1>
                    Our <span >Branches</span>
                  </h1>
                  <p style={{ marginLeft: "8px" }}>
                    {item?.BrText}
                  </p>

                </div>
                <div className="col-md-5">
                  <span style={{ float: "right" }}>
                    <div id="container">
                      <button
                        className="btn7"
                        onClick={() => {
                          handleShow66();
                        }}
                      ></button>
                    </div>
                  </span>
                </div>
              </div>
            </div>
            <div className="container mt-3 ">
              <Carousel responsive={responsive} autoPlay={true} autoPlaySpeed={2000}>
                {Addbranches?.map((item) => {
                  return (
                    <>
                      <Card style={{ width: '20rem', height: "410px" }} className="ms-2">
                        <Card.Img variant="top" src={`https://doorstepedu.info/Branches/${item?.BImg}`} style={{ height: "200px" }} />
                        <Card.Body>
                          <Card.Text className="card_text_p">
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              Branch Name:
                            </span>{" "}
                            {item?.BName} <br />
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              Address:
                            </span>
                            {item?.BAddress}
                            <br />
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              Mobile No:
                            </span>{" "}
                            {item?.BMobile}
                          </Card.Text>


                        </Card.Body>
                      </Card>

                      {/* <Card style={{ width: "20rem", marginLeft: "30px", height: "575px" }}>
                    <div className="wrapper">
                      <div className="image">
                        <img
                          src={`https://doorstepedu.info/Branches/${item?.BImg}`}
                          alt=""
                          style={{ width: "100%" }}
                        />
                        <div className="content1">
                          <p>
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              Branch Name:
                            </span>{" "}
                            {item?.BName}
                          </p>
                          <p>
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              Address:
                            </span>
                            {item?.BAddress}
                          </p>
                          <p>
                            {" "}
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              Mobile No:
                            </span>{" "}
                            {item?.BMobile}
                          </p>

                          <div id="container">
                            <button
                              className="btn7"
                              onClick={() => {
                                handleShow66();
                              }}
                            ></button>
                          </div>
                        </div>

                       
                      </div>
                    </div>
                  </Card> */}
                      {/* <div>
                  <div className="box ms-2">
                    <img
                      src={`https://doorstepedu.info/Branches/${item?.BImg}`}
                      alt="pic"
                      style={{ height: "200px" }}
                    />
                    <div className="box-content">
                      <h3 className="name">
                        <span style={{ fontWeight: "bold" }}>Branch Name:</span>{" "}
                        {item?.BName} <br />
                        <span style={{ fontWeight: "bold" }}>
                          Address:
                        </span>{" "}
                        {item?.BAddress}
                        <br />
                        <span style={{ fontWeight: "bold" }}>
                          Mobile No:
                        </span>{" "}
                        {item?.BMobile} <br />
                      </h3>

                      <div id="container">
                        <button className="btn7"></button>
                      </div>
                    </div>
                  </div>
                </div> */}
                    </>
                  );
                })}
              </Carousel>
            </div>
          </div>
        )
      })}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>Enquire Now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <FloatingLabel controlId="floatingInput" label="Enter Your Name">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              required
              onChange={(e) => {
                setEName(e.target.value);
              }}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Mobile No">
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
              required
              onChange={(e) => {
                setEPhone(e.target.value);
              }}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Email">
            <Form.Control
              type="email"
              placeholder="name@example.com"
              className="rtyui_0"
              required
              onChange={(e) => {
                setEEmail(e.target.value);
              }}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingTextarea2" label="Message">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              className="rtyui_0"
              required
              onChange={(e) => {
                setEmsg(e.target.value);
              }}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            onClick={Enquiryf}
            style={{ backgroundColor: "#00a695", border: "none" }}
          >
            Send Request
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>Enquire Now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <FloatingLabel controlId="floatingInput" label="Enter Your Name">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setBcTutor(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Mobile No">
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setBctPhone(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Email">
            <Form.Control
              type="email"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setBctEmail(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingTextarea2" label="Message">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              className="rtyui_0"
              onChange={(e) => setBctmsg(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            onClick={EnquiryBct}
            style={{ backgroundColor: "#00a695", border: "none" }}
          >
            Send Request
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <FloatingLabel controlId="floatingInput" label="Mobile No">
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            onClick={handleShow3}
            style={{ backgroundColor: "#00a695", border: "none" }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show3} onHide={handleClose3}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <FloatingLabel controlId="floatingInput" label="Enter OTP">
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#00a695", border: "none" }}
            onClick={handlenavigate}
          >
            Validate
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show44} onHide={handleClose44}>
        <Modal.Header closeButton>
          <Modal.Title>{Testimonialview?.TName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{Testimonialview?.TText1}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose44}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show55} onHide={handleClose55}>
        <Modal.Header closeButton>
          <Modal.Title>{guaranteeview?.GName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{guaranteeview?.GText1}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose55}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show66} onHide={handleClose66}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>
            {" "}
            Branch Enquire Now
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <FloatingLabel controlId="floatingInput" label="Enter Branch Name">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setEBranch(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Enter Your Name">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setENamee(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Mobile No">
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setEPhonee(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Email">
            <Form.Control
              type="email"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setEEmaill(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingTextarea2" label="Message">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              className="rtyui_0"
              onChange={(e) => setEmsgg(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose66}>
            Close
          </Button>
          <Button
            onClick={(e) => branchEnquiryf(e)}
            style={{ backgroundColor: "#00a695", border: "none" }}
          >
            Send Request
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show22} onHide={handleClose22}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <FloatingLabel controlId="floatingInput" label="Mobile No">
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose22}>
            Close
          </Button>
          <Button
            onClick={handleShow43}
            style={{ backgroundColor: "#00a695", border: "none" }}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show34} onHide={handleClose34}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <FloatingLabel controlId="floatingInput" label="Enter OTP">
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose34}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#00a695", border: "none" }}

          >
            <a href="/faculty" style={{ textDecoration: "none", color: "white" }}>Validate</a>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Home;
