import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import axios from "axios";

const BecomeTutor = () => {
  const [bcomtutor, setBecomtutor] = useState({});
  const [show99, setShow99] = useState(false);

  const handleClose99 = () => setShow99(false);
  const handleShow99 = () => setShow99(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // integrating post method for bctutor form
  const [BcTutor, setBcTutor] = useState("");
  const [BctPhone, setBctPhone] = useState("");
  const [BctEmail, setBctEmail] = useState("");
  const [Bctmsg, setBctmsg] = useState("");
  //integrating get  method for become a tutor content
  const [AddBecomee, setAddBecomee] = useState([]);
  const getAddBecomes = async () => {
    try {
      // alert("fdffdd")
      let res = await axios.get("https://doorstepedu.info/api/user/getBecome");
      if (res.status == 200) {
        setAddBecomee(res.data.getBecome);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const EnquiryBct = async () => {
    try {
      const config = {
        url: "/user/BcTutor",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "application/json" },
        data: {
          EName: BcTutor,
          EPhone: BctPhone,
          EEmail: BctEmail,
          Emsg: Bctmsg,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  useEffect(() => {
    getAddBecomes();
  }, []);
  console.log(AddBecomee);
  return (
    <>
      <div className="">
        <div
          class="module mid"
          style={{
            height: "80px",
            background: "#d1ccc0",
            overflow: " hidden",
            // background: " #C33764",
            //   background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
            //  url("Header 2.jpg")`,
            backgroundSize: "cover",
          }}
        >
          <h2>Become A Tutor</h2>
          <ul
            class=" container breadcrumbs bc3 pt-3 pb-3"
            role="menubar"
            aria-label="breadcrumbs"
          >
            <li>
              <a href="/">Home</a>{" "}
              <span style={{ color: "#092746" }}>&raquo;</span>
            </li>
            <li>
              {" "}
              <a href="/faculty">Teacher Training and Placements</a>{" "}
              <span style={{ color: "#092746" }}>&raquo;</span>
            </li>
            <li style={{ color: "#092746" }}>Become A Tutor</li>
          </ul>
        </div>
      </div>
      <div className="container mt-2 " id="mcq">
        <div className="row hjklo_9">
          <div className="col-md-12">
            <h1>Become A Tutor</h1>
          </div>
        </div>
      </div>
      <div className="container">

        <div className="row">
          {AddBecomee?.map((item) => {
            return (
              <div className="col-md-6">
                <h1>{item?.BHeader}</h1>
                <p className="card_text_p" style={{ textAlign: "justify" }}>
                  {item?.BText1.slice(0, 500) < item?.BText1.slice(0, 501) ? (<>{item?.BText1.slice(0, 500)}... <br />
                    <span
                      className="viewmore text-dark"
                      onClick={() => {
                        handleShow99();
                        setBecomtutor(item);
                      }}
                      style={{
                        cursor: "pointer",

                      }}
                    >View More</span>
                  </>) : (<>{item?.BText1}</>)}

                </p>
              </div>
            );
          })}
        </div>

      </div>
      <div className="container mt-4 mb-4">
        <div className="row hjklo_9">
          <div className="col-md-8">
            <h1>
              Want to become a tutor in{" "}
              <span style={{ color: "#00a695" }}> DoorStep EduGroup </span>
            </h1>
          </div>
          <div className="col-md-4">
            <div id="container">
              <button className="btn5" onClick={handleShow}></button>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>Enquire Now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <FloatingLabel controlId="floatingInput" label="Enter Your Name">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setBcTutor(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Mobile No">
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setBctPhone(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Email">
            <Form.Control
              type="email"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setBctEmail(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingTextarea2" label="Message">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              className="rtyui_0"
              onChange={(e) => setBctmsg(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            onClick={EnquiryBct}
            style={{ backgroundColor: "#00a695", border: "none" }}
          >
            Send Request
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show99} onHide={handleClose99} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{bcomtutor?.BHeader}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="card_text_p">{bcomtutor?.BText1}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose99}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BecomeTutor;
