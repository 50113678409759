import React, { useState } from 'react';
import './das.css';

function DashboardAdmin() {
  const [buttonClicked, setButtonClicked] = useState('');


  return (
    <div className="App">
      <h1 style={{color:'white'}}>Admin Management</h1>
      <div className="button-container">
        <button className="image-button" onClick={() => window.location.assign('/dashboard')}>
          <img src="https://media.istockphoto.com/id/1439477915/vector/website-under-construction-tiny-people-build-update-structure-of-site-create-content.jpg?s=1024x1024&w=is&k=20&c=nPi9svsjUhqqhGnKIOWj2BnGutV7E2WUpzrkCeMABXo=" alt="Web Management" />
          <p style={{color:'white'}}>Web Management</p>
        </button>
        <button className="image-button" onClick={() => window.location.assign('/admin/dashboard')}>
          <img src="https://media.istockphoto.com/id/1150386781/vector/successful-businessman-character-conduct-online-video-course-training-vector-flat-graphic.jpg?s=1024x1024&w=is&k=20&c=um3GoQFBRNouy7k0_z3qcP9xSe5PJ_OajVPFTOfbPaA=" alt="App Management" />
          <p style={{color:'white'}}>App Management</p>
        </button>
      </div>
      {/* {buttonClicked && <p>You clicked: {buttonClicked}</p>} */}
    </div>
  );
}

export default DashboardAdmin;