import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Adminhome() {
    const [data, setdata] = useState([]);
   
  
    const [data1, setdata1] = useState([]);
    const [data2, setdata2] = useState([]);
    useEffect(() => {
        getusers();
        getusers1();
        getusers2();

      }, []);
    
      const getusers = () => {
        try {
          axios
          .get(`https://doorstepedu.info/api/user/getallUser`)
          .then(function (response) {
            if (response.status === 200) {
              console.log(response.data);
              //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
              setdata(response.data.success);
              } else {
                //alert("Can't able to fetch ");
                //setdatacondition(true);
                return;
              }
            })
            .catch(function (error) {
              // setdatacondition(true);
              console.log(error.message.data);
              //alert("Can't able to fetch ");
              return;
            });
        } catch (error) {
          console.warn(error);
          alert("Can't able  to fetch ");
          // setdatacondition(true);
          return error;
        }
      };
      useEffect(() => {
        getusers();
      }, []);
    
      const getusers1 = () => {
        try {
          axios
          .get(`https://doorstepedu.info/api/user/getallUser1`)
          .then(function (response) {
            if (response.status === 200) {
              console.log(response.data);
              //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
              setdata1(response.data.success);
              } else {
                //alert("Can't able to fetch ");
                //setdatacondition(true);
                return;
              }
            })
            .catch(function (error) {
              // setdatacondition(true);
              console.log(error.message.data);
              //alert("Can't able to fetch ");
              return;
            });
        } catch (error) {
          console.warn(error);
          alert("Can't able  to fetch ");
          // setdatacondition(true);
          return error;
        }
      };
      useEffect(() => {
        getusers();
      }, []);
    
      const getusers2 = () => {
        try {
          axios
          .get(`https://doorstepedu.info/api/user/getallUser2`)
          .then(function (response) {
            if (response.status === 200) {
              console.log(response.data);
              //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
              setdata2(response.data.success);
              } else {
                //alert("Can't able to fetch ");
                //setdatacondition(true);
                return;
              }
            })
            .catch(function (error) {
              // setdatacondition(true);
              console.log(error.message.data);
              //alert("Can't able to fetch ");
              return;
            });
        } catch (error) {
          console.warn(error);
          alert("Can't able  to fetch ");
          // setdatacondition(true);
          return error;
        }
      };
    const admin = JSON.parse(sessionStorage.getItem("admin"));    return (
        <div className="ml-4" style={{marginLeft:"50px"}}>
        {admin ? <h1 className="pt-5">Hi {admin.name}  !!!</h1>: <h1 className="pt-5" ><Link to="/admin/signin">please login</Link></h1>}
        <Row className="pt-2" style={{margin:"30px"}}>
        <Col>
        <Card style={{width: '15rem', boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
            <Card.Body>
                <Card.Title style={{fontSize:"16px"}}>Active Teacher</Card.Title>
                <Card.Title>{data.length}</Card.Title>
            </Card.Body>
        </Card>
        </Col>
        <Col>
        <Card style={{ width: '15rem', boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
            <Card.Body>
                <Card.Title style={{fontSize:"16px"}}>Active Student</Card.Title>
                <Card.Title>{data1.length}</Card.Title>
            </Card.Body>
        </Card>
        </Col>
        <Col>
        <Card  style={{width: '15rem', boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}>
            <Card.Body>
                <Card.Title style={{fontSize:"16px"}}>Active Parent</Card.Title>
                <Card.Title>{data2.length}</Card.Title>
            </Card.Body>
        </Card>
       
        </Col>
       
    </Row>  
    </div>
)
    
}

export default Adminhome
