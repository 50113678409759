import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function Adminbooking() {
  const [data, setdata] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    getusers();
  }, []);

  const getusers = () => {
    try {
      axios
        .get(`https://doorstepedu.info/api/alleventbooking`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata(response.data.booking);
            return;
          } else {
            //alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };

  // const postdeleteimage =  async (data) => {

  //   try{
  //     const config ={
  //         url:"/deleteevent/"+data._id,
  //         method:'post',
  //         baseURL:"https://doorstepedu.info/api",
  //         headers:{ 'event-type': 'multipart/form-data' },

  //         //responseType:"application/json"
  //       }
  //     let res = await axios(config);
  //     if(res.status === 200){
  //         console.log(res.data);
  //         alert("event deleted successfully")
  //         window.location.reload();
  //     }
  // }catch(error){
  //   console.log(error);
  // }

  //   }

  return (
    <div style={{ background: "#fff" }}>
      <h2 style={{ color: "#01a695", textAlign: "center" }}> event </h2>

      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>S.no</th>
            <th>Event Name</th>
            <th>User name</th>
            <th>User Email</th>

            <th>Booked Date</th>
            <th>Event Date</th>
            <th>Payment Method</th>
            <th>Receipt</th>
            <th>status</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((e) => (
            <tr>
              <td>1</td>
              <td>{e.event[0].eventtitle}</td>
              <td>{e.user[0].name}</td>
              <td>{e.user[0].email}</td>
              <td>{e.eventdate}</td>
              <td>{e.bookedtime}</td>
              <td>{e.paymentmethod}</td>
              {/* <td> <Button variant="primary" className="bnt-border sc-hiCivh sc-eJwXpk iUXnyj jMA-dWh p-2" style={{fontSize:"20px",fontWeight: "700", background:"red"}} onClick={()=>postcancel(e)}>Cancel Booking </Button>{e.event[0].roomid !== ""?<Button onClick={()=>window.location.assign(`/room/${e.event[0].roomid}`)}>Join</Button>:""} </td> */}
              <td>
                {" "}
                <Link to={{ pathname: "/invoice", state: { data: e } }}>
                  <Button
                    variant="primary"
                    className="bnt-border sc-hiCivh sc-eJwXpk iUXnyj jMA-dWh p-2"
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      background: "Green",
                    }}
                  >
                    Download{" "}
                  </Button>
                </Link>{" "}
              </td>
              <td>{e.status} </td>
            </tr>
          ))}

          <a
            href="https://wa.me/country_code_and_your_phone_number"
            className="whatsapp_float"
            target="_blank"
          >
            {" "}
            <i className="fa fa-whatsapp whatsapp-icon"></i>
          </a>

          {/* <tr>
      <td>3</td>
      <td colSpan="2">Larry the Bird</td>
      <td>@twitter</td>
    </tr> */}
        </tbody>
      </Table>
    </div>
  );
}

export default Adminbooking;
