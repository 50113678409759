import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

function Adminuser() {
  const [data, setdata] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    getusers();
  }, []);

  const getusers = () => {
    try {
      axios
        .get(`https://doorstepedu.info/api/user/getallUser1`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata(response.data.success);
            return;
          } else {
            //alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };

  const approve = async (data) => {
    try {
      axios
        .post(`https://doorstepedu.info/api/blockuser/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  const unblock = async (data) => {
    try {
      axios
        .post(`https://doorstepedu.info/api/unblockuser/` + data._id)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            window.location.reload();
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          alert("Not able to complete");
        });
    } catch (error) {
      console.warn(error);
      alert("Not able to complete");
      // setdatacondition(true);
      return error;
    }
  };

  return (
    <div style={{ background: "#F4F4F4" }}>
      <div className="container pb-5">
        <h1
          className="pt-4 pb-3"
          style={{ color: "#01a695", textAlign: "center" }}
        >
          User list
        </h1>
        <div className="container">
          <div className="card card-body">
            <div>
              {/* <h5 className="pb-3">Latest Orders</h5> */}
              <div className="container text-center">
                <Table responsive hover size="md">
                  <thead>
                    <tr>
                      <th>Name</th>
                      {/* <th>Profile Picture</th> */}
                      <th>Email Id</th>
                      <th>Mobile No</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((datas) => (
                      <tr>
                        <td>{datas.name} </td>
                        {/* <td><img src={"http://bd.parnetslink.com/profile/" + datas.profileimage} height="50px" width="50px" style={{borderRadius:"100%"}}/></td>  */}
                        <td>{datas.email}</td>
                        <td>{datas.phoneNumber}</td>
                        <td>
                          {!datas.blockstatus ? (
                            <button
                              className="btn btn-outline-success"
                              onClick={() => approve(datas)}
                            >
                              Block
                            </button>
                          ) : (
                            <button
                              className="btn btn-outline-danger"
                              onClick={() => unblock(datas)}
                            >
                              Un-Block
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                    {/* <tr>
                                    <td>DR David</td>
                                    <td><img src="" height /></td>                                                               
                                    <td><div className=""> <button className="btn btn-danger">Cancel</button> </div></td>
                                </tr> */}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Adminuser;
