import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";

const AdminContactus = () => {
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  // integrating post method
  const [Address, setAddress] = useState("");
  const [Email, setEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");

  const AddContactus = async () => {
    try {
      const config = {
        url: "/user/Contactus",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "application/json" },
        data: {
          Address: Address,
          Email: Email,
          Phone: Phone,
          whatsapp: whatsapp,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getAddContactuss();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddContactuse, setAddContactuse] = useState([]);
  const getAddContactuss = async () => {
    try {
      // alert("fdffdd")
      let res = await axios.get(
        "https://doorstepedu.info/api/user/getallContactus"
      );
      if (res.status == 200) {
        setAddContactuse(res.data.getContactusnow);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [Acontact, setAcontact] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setShow(true);
    setAcontact(item);
  };
  const editContactuss = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/user/editContactus",
        method: "put",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "application/json" },
        data: {
          id: Acontact?._id,
          Address: Address,
          Email: Email,
          Phone: Phone,
          whatsapp: whatsapp,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose();
          getAddContactuss();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  useEffect(() => {
    getAddContactuss();
  }, []);
  console.log(AddContactuse);
  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="ad-b mt-4 mb-4">
            {AddContactuse.length !== 0 ? (
              <></>
            ) : (
              <>
                <button className="btn btn-success" onClick={handleShow1}>
                  Add Contact Us Details
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="container  mt-5">
        <div className="row">
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>

                <th>Address</th>
                <th>Email</th>
                <th>Mobile No.</th>
                <th>Whatsapp No.</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {AddContactuse?.map((item, i) => {
                return (
                  <tr>
                    <td>{++i}</td>
                    <td>
                      <p className="card_text_p" style={{ height: "50px", overflow: "hidden auto" }}>
                        {item?.Address}
                      </p>
                    </td>
                    <td>{item?.Email}</td>
                    <td>+91-{item?.Phone}</td>
                    <td>+91-{item?.whatsapp}</td>

                    <td>
                      <div className="ad-b ">
                        <span>
                          <i
                            class="fas fa-edit"
                            style={{
                              fontSize: "20px",
                              color: "green",
                              paddingTop: "20px",
                            }}
                            onClick={() => handleShow(item)}
                          // title="edit"
                          ></i>
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>
            {" "}
            Add Contact Us Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="floatingInput" label="Address">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setAddress(e.target.value)}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingInput" label="Email id">
            <Form.Control
              type="email"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setEmail(e.target.value)}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingInput" label="Mobile No.">
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setPhone(e.target.value)}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingInput" label="whatsapp No.">
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setWhatsapp(e.target.value)}
            />
          </FloatingLabel>{" "}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            onClick={AddContactus}
            style={{ backgroundColor: "#00a695", border: "none" }}
          >
            Add{" "}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>
            {" "}
            Update Contact Us Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="floatingInput" label="Address">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setAddress(e.target.value)}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingInput" label="Email id">
            <Form.Control
              type="email"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setEmail(e.target.value)}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingInput" label="Mobile No.">
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setPhone(e.target.value)}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingInput" label="whatsapp No.">
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setWhatsapp(e.target.value)}
            />
          </FloatingLabel>{" "}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#00a695", border: "none" }}
            onClick={editContactuss}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminContactus;
