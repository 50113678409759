import React, { useState } from "react";
import { Card, Form, Button } from "react-bootstrap";
import axios from "axios";
import { AiFillDelete } from 'react-icons/ai';
import moment from "moment";
function Addmcq() {
  const admin = JSON.parse(sessionStorage.getItem("admin"));

  const [examtitle, setexamtitle] = useState("");
  const [examtime, setexamtime] = useState("");
  const [ans, setans] = useState("");

  const [examdate, setexamdate] = useState();
  const [examtime1, setexamtime1] = useState("");
  const [image, setimage] = useState();
  const [questions, setQuestions] = useState([]);
  const [standard, setstandard] = useState(" ");
  const [examsubject, setexamsubject] = useState(" ");
  const [exammark, setexammark] = useState(" ");
  const [examinstruction, setexaminstruction] = useState(" ");
  const [question, setQuestion] = useState('');
  const [choices, setChoices] = useState(['']);
console.log(questions,"fdgdf");
  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };
  const handleAnswerChange = (e) => {
    setans(e.target.value);
  };
  const handleChoiceChange = (e, index) => {
    const updatedChoices = [...choices];
    updatedChoices[index] = e.target.value;
    setChoices(updatedChoices);
  };

  const handleAddChoice = () => {
    if(choices.length < 4){
      setChoices([...choices, '']);
      } else {
        alert("You can only add up to four choice");
      }
  };

  const handleRemoveChoice = (index) => {
    const updatedChoices = [...choices];
    updatedChoices.splice(index, 1);
    setChoices(updatedChoices);
  };
  const handleDeleteQuestion = (index) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };
  const handleFormSubmit = () => {
   
    // Push the question and choices to an array
    const questionData = {
      question,
      choices,
      ans
    };
    
    setQuestion('');
    setChoices(['']);
    setans("")

    setQuestions([...questions, questionData]);
  };
  const formdata = new FormData();

  const postimage = async () => { 
    if ( !examtitle ||
    !examsubject ||
    !examinstruction ||
    !exammark ||
    !examtime ||
    // !examtime1 ||
    !examdate ||
    !standard ||
   
    questions.length === 0
  ) {
    alert('Please fill mandatory fields');
  // } else if(examtime >= examtime1){
  //   alert ('Start time should be less than End Time') 
  } else {
    try {
      const config = {
        url: "/admin/addmcq",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "content-type": "application/json" },
        data: {
          examtitle : examtitle,
          standard : standard,
          examdate : moment(examdate).format('DD/MM/YYYY'),
          examsubject : examsubject,
     exammark : exammark,
     examtime : examtime,
     endtime : examtime,
     mcqfile : questions,
     examinstruction :examinstruction,
     adminid:admin._id,
        },
        
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("MCQ add successfully");
        window.location.assign("/admin/mcq");
    
      }
    } catch (error) {
      console.log(error);
      alert("Data failed");
    }
  }
  };
  // const postimage = async () => {
  //   formdata.append("examtitle", examtitle);
  //   formdata.append("standard", standard);
  //   formdata.append("examdate", examdate);
  //   formdata.append("examsubject", examsubject);
  //   formdata.append("exammark", exammark);
  //   formdata.append("examtime", examtime);
  //   formdata.append("endtime", examtime1);
  //   formdata.append("mcqfile", questions);
    


  //   formdata.append("examinstruction", examinstruction);

  //   try {
  //     const config = {
  //       url: "/admin/addmcq",
  //       method: "post",
  //       baseURL: "https://doorstepedu.info/api",
  //       headers: { "exam-type": "multipart/form-data" },
  //       data: formdata,
  //       //responseType:"application/json"
  //     };
  //     let res = await axios(config);
  //     if (res.status === 200) {
  //       console.log(res.data);
  //       alert("MCQ add successfully");
  //       window.location.assign("/admin/mcq");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  return (
    <div className="container mt-5">
      <Card
        className="text-center"
        style={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <h5 className="pt-5">Create Objective</h5>
        <Card.Body>
          <Form className="w-50 mx-auto">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label> Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                onChange={(e) => setexamtitle(e.target.value)}
              />
              {/* <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text> */}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                placeholder="subject"
                onChange={(e) => setexamsubject(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Date"
                onChange={(e) => setexamdate(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>examinstruction</Form.Label>
              <Form.Control
                type="text"
                placeholder="examinstruction"
                onChange={(e) => setexaminstruction(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
             
              <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="select4">
                      ClassName list
                    </label>
                    <select
                      className="form-control custom-select"
                      id="select4"
                      onChange={(e) => setstandard(e.target.value)}
                    >
                      <option value="Class 1">Class 1</option>
                  <option value="Class 2">Class 2</option>
                  <option value="Class 3">Class 3</option>
                  <option value="Class 4">Class 4</option>
                  <option value="Class 5">Class 5</option>
                  <option value="Class 6">Class 6</option>
                  <option value="Class 7">Class 7</option>
                  <option value="Class 8">Class 8</option>
                  <option value="Class 9">Class 9</option>
                  <option value="Class 10">Class 10</option>
                  <option value="Class 11">Class 11</option>
                  <option value="Class 12">Class 12</option>
                    </select>
                  </div>
                </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Duration</Form.Label>
              <Form.Control
                type="number"
                placeholder="Duration eg: 30 ,180 in minutes"
                // onKeyPress={(event) => {
                //   if (!/[0-9]/.test(event.key)) {
                //     event.preventDefault();
                //   }
                // }}
                onChange={(e) => setexamtime(e.target.value)}
              />
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>End time</Form.Label>
              <Form.Control
                type="time"
                placeholder="time"
                onChange={(e) => setexamtime1(e.target.value)}
              />
            </Form.Group> */}
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Exam Mark</Form.Label>
              <Form.Control
                type="number"
                placeholder="marks"
                onChange={(e) => setexammark(e.target.value)}
              />
            </Form.Group>
            <h3>Create Question</h3>
     
           
        {/* <label htmlFor="question">Question:</label>
        <input type="text" id="question" value={question} onChange={handleQuestionChange} required /> */}
        <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Question:</Form.Label>
              <Form.Control
                type="text" id="question" value={question} onChange={handleQuestionChange} required 
              />
            </Form.Group>
       
         <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Choices:</Form.Label>
              {choices.map((choice, index) => (
          <div key={index} style={{display:"flex",flexDirection:"row"}}> <Form.Control
                 type="text"
                 value={choice}
                 onChange={(e) => handleChoiceChange(e, index)}
                 required
              /> {index >= 0 && (
                <AiFillDelete style={{margin:"20px"}} color="red" onClick={() => handleRemoveChoice(index)} />
                
                
              )}
            </div>
          ))}
            </Form.Group>
            <button className="btn btn-primary" onClick={handleAddChoice}>
          Add Choice
        </button>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Correct Answer</Form.Label>
              <Form.Control
                type="text"
                placeholder="Correct Answer"
                value={ans}
                onChange={handleAnswerChange} required 
              />
            </Form.Group>
            <div style={{margin:"20px"}}>
       

        <button className="btn btn-primary" style={{marginLeft:"20px"}} onClick={()=>handleFormSubmit()}>Create Question</button>
      
        </div>
          </Form>

          <button className="btn btn-primary" onClick={postimage}>
            Submit
          </button>
          {/* <QuestionForm onQuestionSubmit={handleQuestionSubmit} /> */}


{questions.map((question, index) => (
  <div key={index}>
    <h2>Questions</h2>
    <h3>Question: {question.question}</h3>
    <ul>
      {question.choices.map((choice, choiceIndex) => (
        <li key={choiceIndex}>{choice}</li>
      ))}
    </ul>
    <h6 style={{color:"green"}}>Correct Answer: {question.ans}</h6>
    <button onClick={() => handleDeleteQuestion(index)} style={{color:"white",backgroundColor:'red',borderColor:'red'}}>Delete Question</button>
  </div>
))}

        </Card.Body>
      </Card>
    </div>
  );
}

export default Addmcq;
