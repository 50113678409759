import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {Search,CSVExport} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import exportFromJSON from "export-from-json";

import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { dateFilter } from "react-bootstrap-table2-filter";
import DatePicker from "react-multi-date-picker";
import {  Form, Modal} from "react-bootstrap";
import { AiFillDelete } from 'react-icons/ai';
import moment from "moment";
function Adminmcq() {
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const [data2, setdata2] = useState([]);
  const [data1, setdata1] = useState([]);
  const admin = JSON.parse(sessionStorage.getItem("admin"));

  const [examtitle, setexamtitle] = useState("");
  const [examtime, setexamtime] = useState("");
  const [ans, setans] = useState("");
  const [selected1, setselected1] = useState({});
  const [isOpen1, setIsOpen1] = useState(false);
  const [examdate, setexamdate] = useState();
  const [examtime1, setexamtime1] = useState("");
  const [image, setimage] = useState();
  const [questions, setQuestions] = useState([]);
  const [standard, setstandard] = useState("");
  const [examsubject, setexamsubject] = useState("");
  const [exammark, setexammark] = useState("");
  const [examinstruction, setexaminstruction] = useState("");
  const [question, setQuestion] = useState('');
  const [choices, setChoices] = useState(['']);

  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };
  const handleAnswerChange = (e) => {
    setans(e.target.value);
  };
  const handleChoiceChange = (e, index) => {
    const updatedChoices = [...choices];
    updatedChoices[index] = e.target.value;
    setChoices(updatedChoices);
  };

  const handleAddChoice = () => {
    if(choices.length < 4){
      setChoices([...choices, '']);
      } else {
        alert("You can only add up to four choice");
      }
  };

  const handleRemoveChoice = (index) => {
    const updatedChoices = [...choices];
    updatedChoices.splice(index, 1);
    setChoices(updatedChoices);
  };
  const handleDeleteQuestion = (index) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };
  console.log(questions,"ques");
  const handleFormSubmit = () => {
   
    // Push the question and choices to an array
    const questionData = {
      question,
      choices,
      ans,
     
    };
    
    setQuestion('');
    setChoices(['']);
    setans('');

    setQuestions([...questions,...selected1.mcqfile, questionData]);
  };

  const handleFormSubmit1 = () => {
   
    // Push the question and choices to an array
    const questionData = {
      question,
      choices,
      ans,
     
    };
    
    setQuestion('');
    setChoices(['']);
    setans('');

    setQuestions([...questions,...selected1.mcqfile, questionData]);
    updateProduct()
  };


  const formdata2 = new FormData();
  useEffect(() => {
    getusers();
    getusers1();

  }, []);
  const showModal1 = () => {
    setIsOpen1(true);
  };

  const hideModal1 = () => {
    setIsOpen1(false);
  };
  const data = [];
  if (data2?.length !== 0) {
    for (let i = 0; i < data2.length; i++) {
      data.push({
        Title: data2[i]?.examtitle,
        DueDate: data2[i]?.examdate,
        Subject: data2[i]?.examsubject,
        Class: data2[i]?.standard,
        Instruction:data2[i].examinstruction,
        Duration:data2[i].examtime,
        // examType:
        //   data2[i]?.examtype === "standard"
        //     ? "Whole Class"
        //     : "Individual Student",
        // StudentName: data2[i]?.studentid
        //   ? data2[i]?.studentid?.name
        //   : "Whole Class",
        

        TeacherName: data2[i]?.teacherid ? data2[i]?.teacherid?.name : "Admin",
        Marks: data2[i]?.exammark,
       
      });
    }
  }
  
  const fileName = "exam";
  const exportType = "xls";

  const ExportToExcel = () => {
    exportFromJSON({ data, fileName, exportType });
  };
  const getusers1 = async () => {
    try {
      const config = {
        url: '/user/getAllUser1',
        method: 'get',
        baseURL: 'https://doorstepedu.info/api',
        headers: {'content-type': 'application/json'},
      };
      await axios(config)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);

            setdata1(response.data.success);
            return;
          } else {
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          // //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      // alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };
 
  const updateProduct = async () => {
   
    // formdata2.append("techid", selected1._id);
    
    // formdata2.append("name", assignmenttitle ? assignmenttitle : selected1.assignmenttitle);
   
    // formdata2.append("assignmentsubject", assignmentsubject ? assignmentsubject : selected1.assignmentsubject);

    // formdata2.append("others", others ? others : selected1.others);
    // formdata2.append("assignmentdate", assignmentdate ? moment(assignmentdate).format('DD/MM/YYYY') : selected1.assignmentdate);
    // formdata2.append("mark", mark ? mark : selected1.mark);
    // formdata2.append("standard", standard ? standard : selected1.standard);
    // formdata2.append("type", type ? type : selected1.type);
   
  
    // formdata.append("banner", photo);

    try {
      const config = {
        url: "/admin/updateMcq" ,
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "application/json" },
        data:{
          techid: selected1._id,
          examtitle: examtitle ? examtitle : selected1.examtitle,
   
        examsubject: examsubject ? examsubject : selected1.examsubject,
     
        examdate: examdate ? moment(examdate).format('DD/MM/YYYY') : selected1.examdate,
        exammark: exammark ? exammark : selected1.exammark,
        standard: standard ? standard : selected1.standard,
        examtime: examtime ? examtime : selected1.examtime,
        examinstruction: examinstruction ? examinstruction : selected1.examinstruction,
        mcqfile : questions?.length <= 0 ? selected1.mcqfile : questions
        
        }
      };
      
      await axios(config).then(function (res) {
        if (res.status === 200) {
         
          alert("Exam updated");
          console.log(res.data);
          setIsOpen1(false);
          getusers();
          
          window.location.reload();
        } else {
          alert("Something Wrong");
        }
      });
    } catch (error) {
      alert(error.response.data.error);
    }
  };
 

  const getusers = () => {
    try {
      axios
        .get(`https://doorstepedu.info/api/admin/getallmcq`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata2(response.data.success);
            // setdata1(response.data.mcq);
            return;
          } else {
            //alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };

  const postdeleteimage = async (data) => {
    try {
      const config = {
        url: "/admin/deletemcq/" + data._id,
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "content-type": "multipart/form-data" },

        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("mcq deleted successfully");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      dataField: "examtitle",
      text: "Title",
    },
    {
      dataField: "examdate",
      text: "Date",
    },

    {
      dataField: "examsubject",
      text: "Subject",
    },
    {
      dataField: "standard",
      text: "Standard",
    },
    {
      dataField: "examinstruction",
      text: "Instruction",
    },
    {
      dataField: "teacherid.name",
      text: "Teacher Name",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p style={{fontSize:"16px",marginTop:'-0px'}}>{row.teacherid ? row.teacherid?.name : "Admin"}</p>
          </div>
        );
      },
    },
    {
      dataField: "examtime",
      text: "Duration",
    },
    // {
    //   dataField: "endtime",
    //   text: "End Time ",
    // },
    {
      dataField: "exammark",
      text: "Mark",
    },
    {
      dataField: "mcqfile",
      text: "MCQ Question",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div style={ {overflowY:'scroll',height:"300px",width:"300px"}}>
            {row.mcqfile.map((question, index) => (
  <div key={index}>
    <h5>Question:{index+1}) {question.question}</h5>
    <div>
      {question.choices.map((choice, choiceIndex) => (
      <p style={{fontSize:"16px",marginTop:'-0px'}}> {choiceIndex+1}){""}{choice}</p>
      ))}
      <h6 style={{color:"green"}}>Correct Answer: {question.ans}</h6>
    </div>
  </div>
))}
          </div>
        );
      },
    },
    // {
    //   dataField: "",
    //   text: "Questions",
    //   formatter: (cell, row) => {
    //     console.log(row);
    //     return (
    //       <div>
    //         <Link
    //           to={{
    //             pathname: "/admin/uploadmcq",
    //             state: { mcq: data._id },
    //           }}
    //         >
    //           <button className="btn btn-success">Upload</button>
    //         </Link>
    //       </div>
    //     );
    //   },
    // },
    {
      dataField: "",
      text: "Edit",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn btn-outline-success"
              onClick={() => {
                setselected1(row);
                showModal1();
              }}
            >
              Edit
            </button>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn btn-danger"
              onClick={() => postdeleteimage(row)}
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total px-4">
      Showing {from} to {to} of {size} Results
    </span>
  );
  function getDateObject(datevalue) {
    //  return new Date(datevalue);
    let d = new Date(datevalue);
    //let d=moment(datevalue).format('DD/MM/YYYY');
    let v = d.getDate() + "/" + d.getMonth() + "/" + d.getFullYear();
    return v;
  }

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data2.length,
      },
    ], // A numeric array is also available. the purpose of above mcqple is custom the text
  };

  // data.filter((data) => {
  //   console.log(new Date(data.date.split("-").reverse().join("-")));
  //   console.log(Date.parse(data.date));
  // });
  // const search = () => {
  //   const filteredData = data1.filter(
  //     (data) =>
  //       new Date(data.date.split("-").reverse().join("-")) >=
  //         new Date(startDate.split("-").reverse().join("-")) &&
  //       new Date(data.date.split("-").reverse().join("-")) <=
  //         new Date(endDate.split("-").reverse().join("-"))
  //   );
  //   setdata(filteredData);
  // };

  return (
    <div style={{ background: "#fff" }}>
      <h2 style={{ color: "#01a695", textAlign: "center" }}> MCQ Exam</h2>
      <div className="row pb-3 px-3">
        <div className="col-md-2">
          {/* <h7>Date From</h7> */}
          {/* <DatePicker
            style={{ height: "35px", width: "140px", marginRight: "-5px" }}
            placeholder="DD-MM-YYYY"
            format="DD-MM-YYYY"
            type="input-icon"
            onChange={(date) => setstartDate(date.format("DD-MM-YYYY"))}
          />
        </div>
        <div className="col-md-2">
          <h7>Date To</h7>
          <DatePicker
            style={{ height: "35px", width: "140px", marginRight: "-5px" }}
            placeholder="DD-MM-YYYY"
            format="DD-MM-YYYY"
            type="input-icon"
            onChange={(date) => setendDate(date.format("DD-MM-YYYY"))}
          /> */}
        </div>
        {/* <div className="col-md-2">
          <button
            className="btn btn-primary"
            style={{ marginTop: "20px" }}
            onClick={search}
          >
            filter
          </button>
        </div> */}
      </div>
      <ToolkitProvider
        keyField="id"
        data={data2}
        columns={columns}
        search
        exportCSV={{
          fileName: "transaction.csv",
          blobType: "text/csv;charset=ansi",
        }}
      >
        {(props) => (
          <div>
             <span className="px-3 mr-auto ">
              <ExportCSVButton
                className="btn-outline-success"
                onClick={ExportToExcel}
              >
                Export CSV
              </ExportCSVButton>
            </span>
            <span className="pl-4 mr-5 mt-5" style={{marginLeft:"20px"}}>
              <SearchBar className="form-control " {...props.searchProps} />
            </span>
            <span className="pl-4 mr-5 mt-5" style={{marginLeft:"20px"}}>
            <span className="pl-4 mr-5 mt-5" style={{marginLeft:"20px"}}>
            <ClearSearchButton
              className="btn-outline-info"
              {...props.searchProps}
            />
</span></span>
            <hr />
            <div className="table-responsive" style={{margin:'30px'}}>
              <BootstrapTable
                keyField="id"
                striped
                hover
                condensed
                columns={columns}
                data={data2}
                pagination={paginationFactory(options)}
                {...props.baseProps}
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
      <Modal
        style={{ fontFamily: "Georgia, 'Times New Roman', Times, serif" }}
        show={isOpen1}
        onHide={hideModal1}
      >
        <Modal.Header closeButton className="mt-4">
          <Modal.Title>Edit Exam </Modal.Title>
        </Modal.Header>

        <div className="ms-2 me-2">
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">Exam Title</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setexamtitle(e.target.value)}
              placeholder={selected1.examtitle}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">Exam Subject</Form.Label>
            <Form.Control
             type="text"
             className="form-control"
            
             placeholder={selected1.examsubject}
             onChange={(e) => setexamsubject(e.target.value)}
            />
          </Form.Group>
         
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">
            ClassName list
            </Form.Label>
            <select
                      className="form-control custom-select"
                      id="select4"
                      onChange={(e) => setstandard(e.target.value)}
                    >
                       <option value="">Select Class</option>
                     <option value="Class 1">Class 1</option>
                  <option value="Class 2">Class 2</option>
                  <option value="Class 3">Class 3</option>
                  <option value="Class 4">Class 4</option>
                  <option value="Class 5">Class 5</option>
                  <option value="Class 6">Class 6</option>
                  <option value="Class 7">Class 7</option>
                  <option value="Class 8">Class 8</option>
                  <option value="Class 9">Class 9</option>
                  <option value="Class 10">Class 10</option>
                  <option value="Class 11">Class 11</option>
                  <option value="Class 12">Class 12</option>
                    </select>
          </Form.Group>
          
        
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">Mark</Form.Label>
            <Form.Control
              type="number"
              onChange={(e) => setexammark(e.target.value)}
              placeholder={selected1.exammark}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold"> Due Date</Form.Label>
            <Form.Control
               type="Date"
               className="form-control"
               id="password4"
               autocomplete="off"
              onChange={(e) => setexamdate(e.target.value)}
              placeholder={selected1.examdate}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">Exam Instruction</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setexaminstruction(e.target.value)}
              placeholder={selected1.examinstruction}
            />
          </Form.Group>
          <Form.Group className="mb-3 mt-3" controlId="formGridAddress1">
            <Form.Label className="mb-2 mt-2 fw-bold">Duration</Form.Label>
            <Form.Control
              type="number"
              onChange={(e) => setexamtime(e.target.value)}
              placeholder={selected1.examtime}
            />
          </Form.Group>
          
          <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Question:</Form.Label>
              <Form.Control
                type="text" id="question" value={question} onChange={handleQuestionChange} required 
              />
            </Form.Group>
       
         <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Choices:</Form.Label>
              {choices.map((choice, index) => (
          <div key={index} style={{display:"flex",flexDirection:"row"}}> <Form.Control
                 type="text"
                 value={choice}
                 onChange={(e) => handleChoiceChange(e, index)}
                 required
              /> {index >= 0 && (
                <AiFillDelete style={{margin:"20px"}} color="red" onClick={() => handleRemoveChoice(index)} />
                
                
              )}
            </div>
          ))}
            </Form.Group>
            <button className="btn btn-primary" onClick={handleAddChoice}>
          Add Choice
        </button>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Correct Answer</Form.Label>
              <Form.Control
                type="text"
                placeholder="Correct Answer"
              
                onChange={handleAnswerChange} required 
              />
            </Form.Group>
            <div style={{margin:"20px"}}>
       

       <button className="btn btn-primary" style={{marginLeft:"20px"}} onClick={()=>handleFormSubmit()}>Create Question</button>
     
       </div>
       {questions ? questions.map((question, index) => (
  <div key={index}>
    <h2>Questions</h2>
    <h3>Question: {question.question}</h3>
    <ul>
      {question.choices?.map((choice, choiceIndex) => (
        <li key={choiceIndex}>{choice}</li>
      ))}
    </ul>
    <h6 style={{color:"green"}}>Correct Answer: {question.ans}</h6>
    <button onClick={() => handleDeleteQuestion(index)} style={{color:"white",backgroundColor:'red',borderColor:'red'}}>Delete Question</button>
  </div>
)) : selected1.mcqfile?.map((question, index) => (
  <div key={index}>
    <h2>Questions</h2>
    <h3>Question: {question.question}</h3>
    <ul>
      {question.choices?.map((choice, choiceIndex) => (
        <li key={choiceIndex}>{choice}</li>
      ))}
    </ul>
    <h6 style={{color:"green"}}>Correct Answer: {question.ans}</h6>
    <button onClick={() => handleDeleteQuestion(index)} style={{color:"white",backgroundColor:'red',borderColor:'red'}}>Delete Question</button>
  </div>
))}

       </div>
         
       
        <Modal.Footer>
          <Button variant="success" onClick={hideModal1}>
            Close
          </Button>
          <Button variant="danger" onClick={updateProduct}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Adminmcq;
