import React, { useEffect, useState } from "react";
import { Button, Table, Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import axios from "axios";

const AdminCompanies = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // integrating post method
  const formdata = new FormData();
  const [CLogo, setCLogo] = useState("");
  const [CCount, setCCount] = useState("");
  const AddCompanies = async () => {
    formdata.append("CLogo", CLogo);
    formdata.append("CCount", CCount);

    try {
      const config = {
        url: "/user/Companies",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddCompanies();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddCompaniess, setAddCompaniesss] = useState([]);
  const getAddCompanies = async () => {
    try {
      let res = await axios.get("https://doorstepedu.info/api/user/getCompanies");
      if (res.status === 200) {
        setAddCompaniesss(res.data.getCompanies);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data, setData] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setData(item);
  };
  const DeleteCompanies = async () => {
    try {
      const config = {
        url: "user/DeleteCompanies/" + Data,
        method: "delete",
        baseURL: "https://doorstepedu.info/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getAddCompanies();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // //update method
  // const [Data1, setData1] = useState("");
  // const [show1, setShow1] = useState(false);
  // const handleClose1 = () => setShow1(false);
  // const handleShow1 = (item) => {
  //   setShow1(true);
  //   setData1(item);
  // };

  // const editCompanies = async (e) => {
  //   e.preventDefault();
  //   if (!CLogo) {
  //     alert("Select the image");
  //   } else {
  //     formdata.append("CLogo", CLogo);
  //     formdata.append("CCount", CCount);
  //     formdata.append("id", Data1?._id);
  //     try {
  //       const config = {
  //         url: "/user/editCompanies",
  //         method: "put",
  //         baseURL: "https://doorstepedu.info/api",
  //         header: { "content-type": "multipart/form-data" },
  //         data: formdata,
  //       };
  //       await axios(config).then((res) => {
  //         if (res.status === 200) {
  //           alert("successfully Update");
  //           handleClose1();
  //           getAddCompanies();
  //         }
  //       });
  //     } catch (error) {
  //       console.log(error);
  //       alert(error.response.data.error);
  //     }
  //   }
  // };
  useEffect(() => {
    getAddCompanies();
  }, []);
  console.log(AddCompaniess);
  return (
    <>

      <div className="add-gr">
        <div className="container">
          <div className="ad-b mt-4 mb-4">
            <button className="btn btn-success" onClick={handleShow}>
              Add Companies  Logo
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra mt-5">
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Logo</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {AddCompaniess?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <img
                        src={`https://doorstepedu.info/Companies/${item?.CLogo}`}
                        alt=""
                        style={{ height: "75px", width: "75px" }}
                      />
                    </td>

                    {/* <td>
                      <div className="ad-b ">
                        <span>
                          <i
                            class="fas fa-edit"
                            style={{
                              fontSize: "20px",
                              color: "green",
                              paddingTop: "20px",
                            }}
                            onClick={() => handleShow1(item)}
                            // title="edit"
                          ></i>
                        </span>
                      </div>
                    </td> */}
                    <td>
                      <i
                        class="fa fa-trash"
                        aria-hidden="true"
                        style={{
                          color: "red",
                          fontSize: "20px",
                          paddingTop: "20px",
                        }}
                        onClick={() => handleShow2(item?._id)}
                      // title="delete"
                      ></i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}> Add Companies Logo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="floatingInput" label="Image">
            <Form.Control
              type="file"
              placeholder={CLogo}
              className="rtyui_0"
              onChange={(e) => setCLogo(e.target.files[0])}
            />
          </FloatingLabel>{" "}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#00a695", border: "none" }}
            onClick={AddCompanies}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="floatingInput" label="Image">
            <Form.Control
              type="file"
              placeholder={CLogo}
              className="rtyui_0"
              onChange={(e) => setCLogo(e.target.files[0])}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingInput" label="Enter Text">
            <Form.Control
              type="text"
              placeholder={CCount}
              className="rtyui_0"
              onChange={(e) => setCCount(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#00a695", border: "none" }}
            onClick={editCompanies}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal> */}
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={DeleteCompanies}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminCompanies;
