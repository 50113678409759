import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import axios from "axios";
import moment from "moment";

function Addstudymaterial() {
  const [data, setdata] = useState([]);
  const [image, setimage] = useState();
  const [studymaterialtitle, setstudymaterialtitle] = useState(" ");
  const [studymaterialdate, setstudymaterialdate] = useState();
  const [studymaterialclassname, setstudymaterialclassname] = useState(" ");
  const [studymaterialsubject, setstudymaterialsubject] = useState(" ");
  const [selectedImages, setSelectedImages] = useState([]);

  const [type, settype] = useState(" ");
  const [standard, setstandard] = useState(" ");
  const [studentname, setstudentname] = useState(null);
  const user = JSON.parse(sessionStorage.getItem("admin"));
  
  const formdata2 = new FormData();
  const apiURL = process.env.REACT_APP_API_URL;
  // studentname !== "" ? formdata.append("studentid", studentname) : "";
  const handleFileChange = (event) => {
    const files = event.target.files;
    const newSelectedImages = [...selectedImages, ...files];
    setSelectedImages(newSelectedImages);
  };
  const uploadFiles=async(id)=>{
   
    try {
     
      for (let i = 0; i < selectedImages.length; i++) {
       
        formdata2.append("id", id);
        formdata2.append("studymaterialfile", selectedImages[i]
          );
        await fetch('https://doorstepedu.info/api/admin/addMeterialsFiles/', {
          method: 'post',
          body: formdata2
          
        })
      }
      
    } catch (error) {
      console.log(error);
    }
  }
  const postimage = async () => {
    if (
      !studymaterialtitle ||
      !studymaterialsubject ||
     
      !standard ||
     
      !type ||

      selectedImages.length === 0
     
    ) {
      alert('Please fill mandatory fields');
    
    } else {
    // formdata.append("studymaterialfile", image);
    // formdata.append("studymaterialtitle", studymaterialtitle);
    // formdata.append("studymaterialclassname", studymaterialclassname);
    // formdata.append("studymaterialdate", studymaterialdate);
    // formdata.append("studymaterialsubject", studymaterialsubject);

    // formdata.append("adminid", user._id);
    // formdata.append("standard", standard);
    // formdata.append(`${studentname !== null ? 'studentid' : ''}`, studentname);

    // formdata.append("studymaterialtype", type);

    try {
      const config = {
        url: "/admin/addstudymaterial",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers:{"content-type":"application/json"},
        data :{
          studymaterialtitle:studymaterialtitle,
          studymaterialclassname:studymaterialclassname,
          studymaterialdate: moment(studymaterialdate).format('DD/MM/YYYY'),
          studymaterialsubject:studymaterialsubject,
          teacherid:user._id,
          // teacherid:null,

          standard:standard,
          studentid:studentname,
          studymaterialtype:type
        }
        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        uploadFiles(res.data.success?._id)
        alert("studymaterial add successfully");
        window.location.assign("/admin/studymaterial");
      }
    } catch (error) {
      console.log(error);
    }
  }
  };

  useEffect(() => {
    getusers();
  }, []);

  const getusers = async () => {
    try {
      const config = {
        url: '/user/getAllUser1',
        method: 'get',
        baseURL: 'https://doorstepedu.info/api',
        headers: {'content-type': 'application/json'},
      };
      await axios(config)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);

            setdata(response.data.success);
            return;
          } else {
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          // //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      // alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };

  useEffect(() => {
    filter();
  }, [standard]);

  const filter = () => {
    if (standard != "") {
      let fliterdata = data.filter((item) => item["standard"] === standard);
      console.log(fliterdata);
      setdata(fliterdata);
    }
  };

  return (
    <div className="container mt-5">
      <Card
        className="text-center"
        style={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <h5 className="pt-5">Add studymaterial</h5>
        <div
        id="appCapsule"
        className=""
        style={{ padding: "", marginTop: "", width: "100%", position: "" }}
      >
        <div
          className="tab-pane fade show active"
          id="overview"
          role="tabpanel"
        ></div>
        <div className="section mt-2 mb-2">
          <div className="section-title">studymaterial Details</div>
          <div className="card">
            <div className="card-body">
              <form>
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="text4">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text4"
                      placeholder="studymaterial Title"
                      onChange={(e) => setstudymaterialtitle(e.target.value)}
                    />
                    <i className="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>

                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="">
                      Subject
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder="Subject"
                      onChange={(e) => setstudymaterialsubject(e.target.value)}
                    />
                    <i className="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="select4">
                      studymaterial Type
                    </label>
                    <select
                      className="form-control custom-select"
                      id="select4"
                      onChange={(e) => settype(e.target.value)}
                    >
                      <option value="">Select studymaterial Type</option>
                      <option value="standard">Whole class</option>
                      <option value="student">Individual student Name</option>
                    </select>
                  </div>
                </div>

                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="select4">
                      ClassName list
                    </label>
                    <select
                      className="form-control custom-select"
                      id="select4"
                      onChange={(e) => setstandard(e.target.value)}
                    >
                      <option value="">Select class</option>
                      <option value="Class 1">Class 1</option>
                  <option value="Class 2">Class 2</option>
                  <option value="Class 3">Class 3</option>
                  <option value="Class 4">Class 4</option>
                  <option value="Class 5">Class 5</option>
                  <option value="Class 6">Class 6</option>
                  <option value="Class 7">Class 7</option>
                  <option value="Class 8">Class 8</option>
                  <option value="Class 9">Class 9</option>
                  <option value="Class 10">Class 10</option>
                  <option value="Class 11">Class 11</option>
                  <option value="Class 12">Class 12</option>
                    </select>
                  </div>
                </div>

                {type == "student" ? (
                  <>
                    
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" for="select4">
                          Student Name list
                        </label>
                        <select
                          className="form-control custom-select"
                          id="select4"
                          onChange={(e) => setstudentname(e.target.value)}
                        >
                          <option value="">Select Student </option>
                          {data.filter((data)=> data.standard === standard).map((name) => (
                            <option value={name._id}>{name.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="password4">
                      Date
                    </label>
                    <input
                      type="Date"
                      className="form-control"
                      id="password4"
                      autocomplete="off"
                      placeholder="Password Input"
                      onChange={(e) => setstudymaterialdate(e.target.value)}
                    />
                    <i className="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>
              </form>
            </div>
            <div className="section-title ">Upload File</div>
            <div className="">
              <div className="-body">
                <form>
                  <div className="" id="fileUpload1">
                  <input
                      type="file"
                      id="fileuploadInput"
                      multiple 
                     
                      onChange={(e)=>handleFileChange(e)}
                    />
                     {selectedImages.map((imageFile, index) => (
          <img key={index} src={URL.createObjectURL(imageFile)} alt={`Image ${index}`} />
        ))}
                    {/* <label for="fileuploadInput" style={{ zIndex: "0" }}>
                      <span>
                        <strong>
                          <ion-icon name="arrow-up-circle-outline"></ion-icon>
                          <i>Upload a File</i>
                        </strong>
                      </span>
                    </label> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="form-button-group  transparent">
          <button
            type="submit"
            class="btn btn-primary btn-block btn-lg"
            onClick={postimage}
            style={{ background: "bule !important", zIndex: "01" }}
          >
            Create
          </button>
        </div>
      </div>
      </Card>
    </div>
  );
}

export default Addstudymaterial;
