import React, { useEffect, useState } from "react";
import { Button, Table, Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import axios from "axios";

const AdminTestrimonials = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // integrating post method
  const [TText1, setTText1] = useState("");
  const [TName, setTName] = useState("");

  const AddTestimonials = async () => {
    try {
      const config = {
        url: "/user/testimonials",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "application/json" },
        data: {
          TText1: TText1,
          TName: TName,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getAddTestimonials();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddTestimonial, setAddTestimonial] = useState([]);
  const getAddTestimonials = async () => {
    try {
      // alert("fdffdd")
      let res = await axios.get(
        "https://doorstepedu.info/api/user/gettestimonials"
      );
      if (res.status == 200) {
        setAddTestimonial(res.data.gettestimonials);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data, setData] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setData(item);
  };
  const deleteTestimonials = async () => {
    try {
      const config = {
        url: "user/deleteTestimonials/" + Data,
        method: "delete",
        baseURL: "https://doorstepedu.info/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getAddTestimonials();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [Aexp, setAexp] = useState("");
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);

  };
  const editTestimonials = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/user/edittestimonials",
        method: "put",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "application/json" },
        data: {
          id: Aexp?._id,
          TText1: TText1,
          TName: TName,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose1();
          getAddTestimonials();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  useEffect(() => {
    getAddTestimonials();
  }, []);
  console.log(AddTestimonial);
  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="ad-b mt-4 mb-4">
            <button className="btn btn-success" onClick={handleShow}>
              Add Testimonials
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra mt-5">
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Description</th>
                <th>Name</th>

                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {AddTestimonial?.map((item, i) => {
                return (
                  <tr>
                    <td>{++i}</td>
                    <td> {item?.TText1}</td>

                    <td style={{ paddingTop: "20px" }}>{item?.TName}</td>

                    <td>
                      <div className="ad-b ">
                        <span>
                          <i
                            class="fas fa-edit"
                            style={{
                              fontSize: "20px",
                              color: "green",
                              paddingTop: "20px",
                            }}
                            onClick={() => {
                              handleShow1(item);
                              setAexp(item);
                            }}
                          // title="edit"
                          ></i>
                        </span>
                      </div>
                    </td>
                    <td>
                      <i
                        class="fa fa-trash"
                        aria-hidden="true"
                        style={{
                          color: "red",
                          fontSize: "20px",
                          paddingTop: "20px",
                        }}
                        onClick={() => handleShow2(item?._id)}
                      // title="delete"
                      ></i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>
            Add Testimonials
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="floatingTextarea2" label="Description">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: '100px' }}
              className="rtyui_0"
              onChange={(e) => setTText1(e.target.value)}
            />
          </FloatingLabel>

          <FloatingLabel controlId="floatingInput" label="Enter Name">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setTName(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button className="btn btn-primary" onClick={AddTestimonials}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteTestimonials}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>Update Testimonials</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="floatingTextarea2" label="Description">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: '100px' }}
              className="rtyui_0"
              onChange={(e) => setTText1(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Enter Name">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setTName(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={editTestimonials}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminTestrimonials;
