import axios from "axios";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";

function Adminsidebar() {
  const [show, setshow] = useState(false);
  // const userdata = JSON.parse(sessionStorage.getItem("user"));

  return (
    <ProSidebar
      width="260px"
      collapsed={show}
      style={{ background: "lightblue",overflowY:"auto",height:"700px" }}
    >
      <SidebarHeader></SidebarHeader>
      <SidebarContent
        style={{
          background: "#01a695",
          color: "#FFF",
          fontSize: "20px",
          fontWeight: "700",
        }}
      >
        {/**
         *  You can add the content of the sidebar ex: menu, profile details, ...
         */}
        <Menu iconShape="" className="p-0 pt-2">
          <MenuItem
            icon={<i class="fa fa-tachometer" aria-hidden="true"></i>}
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
            Dashboard <Link to="/admin/dashboard" />
          </MenuItem>

          {/* <SubMenu title="Sub-Admins" >
                      <MenuItem >Sub-Admin<Link to="/admin/adminsubadmin"/></MenuItem>
                      <MenuItem >Add Sub-Admin<Link to="/admin/addsubadmin"/></MenuItem>
                     
                    </SubMenu>
                     */}
                     <hr className="mt-0" ></hr>
                      <SubMenu title="Slider">

<MenuItem >Add Slider<Link to="/admin/addslider"/></MenuItem>
          <MenuItem >View Slider<Link to="/admin/slider"/></MenuItem>
          </SubMenu>
          <hr className="mt-0" />
          {/* <SubMenu title="SubAdmin">
                    <MenuItem >Sub Admin<Link to="/admin/adminsubadmin"/></MenuItem>
                    <MenuItem >Add Subadmin<Link to="/admin/addsubadmin"/></MenuItem>
                    </SubMenu>  
                    <hr className="mt-0" /> */}

          <SubMenu title="Assignment">
            <MenuItem>
              Create Assignment
              <Link to="/admin/addassignment" />
            </MenuItem>
            <MenuItem>
              {" "}
              View Assignment
              <Link to="/admin/assignment" />
            </MenuItem>
            <MenuItem>
              {" "}
              Completed Assignment 
              <Link to="/admin/completedassignment" />
            </MenuItem>
          </SubMenu>
          <hr className="mt-0"></hr>

          {/* <MenuItem >Event<Link to="/admin/event"/></MenuItem> */}
          {/* {userdata.events?<>
                    <SubMenu title="Events" >
                    <MenuItem >Event<Link to="/admin/event"/></MenuItem>
                    <MenuItem >Add Event<Link to="/admin/addevent"/></MenuItem>
                    </SubMenu>  
                    <hr className="mt-0" /></>:""}  */}
          {/* <MenuItem >Event<Link to="/admin/event"/></MenuItem> */}
          {/* {userdata.specialevent?<>
                    <SubMenu title="SpecialEvents" >
                    <MenuItem  > Special Event<Link to="/admin/specialevent"/></MenuItem>
                    <MenuItem >Add Special Event<Link to="/admin/addspecialevent"/></MenuItem>
                    </SubMenu>  
                    <hr className="mt-0" /></>:""} */}

          {/* <MenuItem >Content<Link to="/admin/content"/></MenuItem> */}

          {/* <SubMenu title="Study Materials">
            <MenuItem>
              Create Study Materials
              <Link to="/admin/addcontent" />
            </MenuItem>
            <MenuItem>
              Study Materials
              <Link to="/admin/content" />
            </MenuItem>
          </SubMenu> */}

          {/* <hr className="mt-0"></hr> */}
          <SubMenu title="Exam Desk">
            <MenuItem>
              Create Exam
              <Link to="/admin/addexam" />
            </MenuItem>
            <MenuItem>
              View Exam <Link to="/admin/exam" />
            </MenuItem>
            <MenuItem>
              Completed Exam <Link to="/admin/completedexam" />
            </MenuItem>
            <MenuItem>
              Create Objective <Link to="/admin/addmcq" />
            </MenuItem>
            <MenuItem>
              View Objective <Link to="/admin/mcq" />
            </MenuItem>
            <MenuItem>
              {" "}
              Completed Objective 
              <Link to="/admin/completedmcq" />
            </MenuItem>
          </SubMenu>
          {/* <hr className="mt-0"></hr>

          <SubMenu title="Study Materials">
            <MenuItem>
              Create Study Materials
              <Link to="/admin/addstudymaterial" />
            </MenuItem>
            <MenuItem>
              Study Materials
              <Link to="/admin/studymaterial" />
            </MenuItem>
          </SubMenu> */}
          <hr className="mt-0"></hr>
          <SubMenu title="Profile">
            <MenuItem>
              View Teacher
              <Link to="/admin/teacherlist" />
            </MenuItem>
            <MenuItem>
              View Student
              <Link to="/admin/studentlist" />
            </MenuItem>
            <MenuItem>
              View Parent
              <Link to="/admin/parentlist" />
            </MenuItem>
          </SubMenu>

          <hr className="mt-0"></hr>

          <SubMenu title="Class History">
            <MenuItem>
              Teacher
              <Link to="/admin/teacherclass" />
            </MenuItem>
            <MenuItem>
              Student
              <Link to="/admin/studentclass" />
            </MenuItem>
          </SubMenu> 
           <hr className="mt-0"></hr>

      {/*    <SubMenu title="ATTENDANCE">
            <MenuItem>
              ATTENDANCE
              <Link to="/admin/teacherlocation" />
            </MenuItem>
          </SubMenu>

          <hr className="mt-0"></hr>*/}

           <SubMenu title="Feedback">
            <MenuItem>
              Feedback
              <Link to="/admin/feedback" />
            </MenuItem>
          </SubMenu>  
           <hr className="mt-0"></hr> 
           <SubMenu title="Rating">
            <MenuItem>
              Teacher Rating
              <Link to="/admin/teacherrating" />
            </MenuItem>
            <MenuItem>
              Student Rating
              <Link to="/admin/studentrating" />
            </MenuItem>
          </SubMenu> 
         <hr className="mt-0"></hr> 

          <SubMenu title="Zoom Class">
            <MenuItem>
              Zoom Class
              <Link to="/admin/zoom" />
            </MenuItem>
          </SubMenu>

          <hr className="mt-0"></hr>

          <SubMenu title="Study Materials">
            <MenuItem>
              Create Study Materials
              <Link to="/admin/addstudymaterial" />
            </MenuItem>

            <MenuItem>
              View Study Materials
              <Link to="/admin/studymaterial" />
            </MenuItem>
          </SubMenu>

          <hr className="mt-0"></hr>
          <SubMenu title="Fee List">
            <MenuItem>
              Fee paid
              <Link to="/admin/feepaid" />
            </MenuItem>
            <MenuItem>
              Fee Pending
              <Link to="/admin/feepending" />
            </MenuItem>
          </SubMenu>
          <hr className="mt-0"></hr>
          <SubMenu title="Class">
            <MenuItem>
              Create Class
              <Link to="/admin/createclass" />
            </MenuItem>
            <MenuItem>
              View Class
              <Link to="/admin/classlist" />
            </MenuItem>
          </SubMenu> 
           <hr className="mt-0"></hr>

          <SubMenu title="NoticeBoard">
            {/* <MenuItem>
              Create NoticeBoard
              <Link to="/admin/allnote" />
            </MenuItem> */}
            <MenuItem>
              NoticeBoard
              <Link to="/admin/noticeboard" />
            </MenuItem>
            <MenuItem>
              Teacher NoticeBoard
              <Link to="/admin/noteteacher" />
            </MenuItem>
            <MenuItem>
              Student NoticeBoard
              <Link to="/admin/notestudent" />
            </MenuItem>
            <MenuItem>
              Parent NoticeBoard
              <Link to="/admin/noteparent" />
            </MenuItem>
          </SubMenu>
           <hr className="mt-0"></hr>

      {/*    <SubMenu title="Teacher Details">
            <MenuItem>
              Create Teacher Details
              <Link to="/admin/addteacherinfo" />
            </MenuItem>
            <MenuItem>
              View Teacher Details
              <Link to="/admin/teacherinfo" />
            </MenuItem>
          </SubMenu> */}
          {/* <hr className="mt-0"></hr> */}

          <SubMenu title="Youtube">
            <MenuItem>
              Create Youtube
              <Link to="/admin/addyoutube" />
            </MenuItem>

            {/* <MenuItem>
              Create Youtube Teacher
              <Link to="/admin/addyoutubeteacher" />
            </MenuItem>
            <MenuItem>
              Create Youtube student
              <Link to="/admin/addyoutubestudent" />
            </MenuItem>
            <MenuItem>
              Create Youtube parent
              <Link to="/admin/addyoutubeparent" />
            </MenuItem> */}
            <MenuItem>
              Youtube
              <Link to="/admin/youtube" />
            </MenuItem>
          </SubMenu>
          {/* <hr className="mt-0"></hr> */}

          {/* <SubMenu title="class" >
                    <MenuItem >Add Class details<Link to="/admin/"/></MenuItem>
                    <MenuItem >Class details<Link to="/admin/"/></MenuItem>
                    </SubMenu>  
                     <hr className="mt-0" ></hr> */}
        </Menu>
      </SidebarContent>
      <SidebarFooter style={{ background: "#01a695", color: "#FFF" }}>
        <div style={{ height: "400px", background: "#01a695" }}></div>
      </SidebarFooter>
    </ProSidebar>
  );
}

export default Adminsidebar;
