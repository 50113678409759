import React from "react";

const Terms = () => {
  return (
    <>
      <div className="text-center mt-4">
        <h1 style={{ fontFamily: "initial" }}>Terms & Conditions</h1>
      </div>
      <div className="container">
        <div className="row ">
          <div className="col-md-12 dghj">
            <p>
              Please read these terms and conditions ("terms and conditions",
              "terms") carefully before using DST Edu. Power, Android
              application, operated by Door Step Tuition Academy [DST] ("us",
              'we", "our").
            </p>
            <h5>Conditions of use</h5>
            <p>
              By using this website, you certify that you have read and reviewed
              this Agreement and that you agree to comply with its terms. If you
              do not want to be bound by the terms of this Agreement, you are
              advised to leave the website accordingly.{" "}
              <span style={{ fontWeight: "bold" }}>DST Edu. Power</span> only
              grants use and access of this website, its products, and its
              services to those who have accepted its terms.
            </p>
            <h5>Privacy policy</h5>
            <p>
              Before you continue using our website or application, we advise
              you to read our privacy policy regarding our user data collection.
              It will help you better understand our practices.
            </p>
            <h5>Intellectual property</h5>
            <p>
              You agree that all materials, products, and services provided on
              this website are the property of{" "}
              <span style={{ fontWeight: "bold" }}>DST Edu. Power,</span> its
              affiliates, directors, officers, employees, agents, suppliers, or
              licensors including all copyrights, trade secrets, trademarks,
              patents, and other intellectual property. You also agree that you
              will not reproduce or redistribute the [name]’s intellectual
              property in any way, including electronic, digital, or new
              trademark registrations.
            </p>
            <p>
              You grant{" "}
              <span style={{ fontWeight: "bold" }}>DST Edu. Power</span> a
              royalty-free and non-exclusive license to display, use, copy,
              transmit, and broadcast the content you upload and publish. For
              issues regarding intellectual property claims, you should contact
              the company in order to come to an agreement.
            </p>
            <h5>User accounts</h5>
            <p>
              As a user of this website or application, you may be asked to
              register with us and provide private information. You are
              responsible for ensuring the accuracy of this information, and you
              are responsible for maintaining the safety and security of your
              identifying information. You are also responsible for all
              activities that occur under your account or password.
            </p>
            <p>
              If you think there are any possible issues regarding the security
              of your account on the website, inform us immediately so we may
              address them accordingly.
            </p>
            <p>
              We reserve all rights to terminate accounts, edit or remove
              content and cancel orders at our sole discretion.
            </p>
            <h5>Applicable law</h5>
            <p>
              By visiting this website or using of this application you agree
              that the laws of the Bangalore Jurisdiction, without regard to
              principles of conflict laws, will govern these terms and
              conditions, or any dispute of any sort that might come between{" "}
              <span style={{ fontWeight: "bold" }}> DST Edu. Power</span> and
              you, or its business partners and associates.
            </p>
            <h5>Disputes</h5>
            <p>
              Any dispute related in any way to your visit to this website or to
              products you purchase from us shall be arbitrated by state or
              federal court [Bangalore Jurisdiction] and you consent to
              exclusive jurisdiction and venue of such courts.
            </p>
            <h5>Indemnification</h5>
            <p>
              You agree to indemnify{" "}
              <span style={{ fontWeight: "bold" }}>DST Edu. Power </span> and
              its affiliates and hold{" "}
              <span style={{ fontWeight: "bold" }}>DST Edu. Power </span>{" "}
              harmless against legal claims and demands that may arise from your
              use or misuse of our services. We reserve the right to select our
              own legal counsel.
            </p>
            <h5>Limitation on liability</h5>
            <p>
              <span style={{ fontWeight: "bold" }}>DST Edu. Power </span> is not
              liable for any damages that may occur to you as a result of your
              misuse of our website or Android application.
            </p>
            <p>
              {" "}
              <span style={{ fontWeight: "bold" }}>DST Edu. Power </span>
              reserves the right to edit, modify, and change this Agreement at
              any time. We shall let our users know of these changes through
              electronic mail. This Agreement is an understanding between{" "}
              <span style={{ fontWeight: "bold" }}>DST Edu. Power </span> and
              the user, and this supersedes and replaces all prior agreements
              regarding the use of this website.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Terms;
