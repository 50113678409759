import React, { useEffect, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const Footter = () => {
  // integrating get method for contact us
  const [AddContactuse, setAddContactuse] = useState([]);
  const getAddContactuss = async () => {
    try {
      // alert("fdffdd")
      let res = await axios.get(
        "https://doorstepedu.info/api/user/getallContactus"
      );
      if (res.status == 200) {
        setAddContactuse(res.data.getContactusnow);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddContactuss();
  }, []);
  console.log(AddContactuse);
  return (
    <>
      <div style={{ backgroundColor: "#00a695" }}>
        <div className="container mt-3">
          <div className="row pt-5">
            <div className="col-md-3 fh_0 fh_1 ">
              <h4 style={{ color: "white" }}>Quick Links</h4>
              <ul>
                <li>
                  <a href="/">
                    <KeyboardArrowRightIcon /> HOME
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/aboutus">
                    <KeyboardArrowRightIcon />
                    ABOUT US
                  </a>{" "}
                </li>
                <li>
                  <a href="gallery">
                    <KeyboardArrowRightIcon />
                    GALLERY
                  </a>{" "}
                </li>
                <li>
                  <a href="/tution">
                    <KeyboardArrowRightIcon />
                    TUTION
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/skilldevelopment">
                    <KeyboardArrowRightIcon />
                    SKILL DEVELOPMENT
                  </a>{" "}
                </li>
              </ul>
            </div>
            <div className="col-md-3 fh_0 fh_1 mt-4">
              <ul>
                <li>
                  {" "}
                  <a href="/trainingandplacement">
                    <KeyboardArrowRightIcon />
                    TEACHER TRAINING <br />{" "}
                    <span style={{ marginLeft: "25px" }}> & PLACEMENTS</span>
                  </a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/examdesk">
                    <KeyboardArrowRightIcon />
                    EXAM DESK
                  </a>{" "}
                </li>
                <li>
                  <a href="/contact">
                    <KeyboardArrowRightIcon />
                    CONTACT US
                  </a>{" "}
                </li>
                <li>
                  <a href="/terms">
                    <KeyboardArrowRightIcon />
                    TERMS & CONDITIONS
                  </a>{" "}
                </li>
                <li>
                  <a href="/privacy">
                    <KeyboardArrowRightIcon />
                    PRIVACY
                  </a>{" "}
                </li>
              </ul>
            </div>
            {AddContactuse?.map((item) => {
              return (
                <div className="col-md-3 fh_0 ">
                  <h4 style={{ color: "white" }}>Contact Us</h4>
                  <li>
                    <MailIcon /> {item?.Email}
                  </li>
                  <li>
                    <LocationOnIcon />
                    {item?.Address} <br />{" "}
                    {/* <span style={{ marginLeft: "25px" }}>London SE1 7PB,</span>{" "}
                      <br />{" "}
                      <span style={{ marginLeft: "25px" }}>United Kingdom</span>{" "} */}
                  </li>
                  <li>
                    <PhoneIcon /> {item?.Phone}
                  </li>
                  <li>
                    <WhatsAppIcon /> {item?.whatsapp}
                  </li>
                </div>
              );
            })}

            <div className="col-md-3 fh_0">
              <h4 style={{ color: "white" }}>Follow Us On</h4>

              <FacebookIcon style={{ fontSize: "50px" }} />

              <TwitterIcon style={{ marginLeft: "10px", fontSize: "50px" }} />

              <YouTubeIcon style={{ marginLeft: "10px", fontSize: "50px" }} />

              <InstagramIcon style={{ marginLeft: "10px", fontSize: "50px" }} />

              <div>
                <p>
                  Download the app from here <br />
                  <FontAwesomeIcon
                    icon={faDownload}
                    beat
                    style={{ marginLeft: "70px", fontSize: "30px" }}
                  />
                </p>
                <img src="./google-play-badge (1).svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footter;
