import React, { useState } from "react";
import { Card, Form, Button } from "react-bootstrap";
import axios from "axios";

function Addnoteparent() {
  // const [image, setimage] = useState();
  const [noteparenttitle, setnoteparenttitle] = useState(" ");
  const [noteparentdate, setnoteparentdate] = useState();
  const [noteparentclassname, setnoteparentclassname] = useState(" ");
  const [noteparentsubject, setnoteparentsubject] = useState(" ");
  const [noteparentcontent, setnoteparentcontent] = useState(" ");

  const formdata = new FormData();

  const postimage = async () => {
    //formdata.append("noteparentfile", image);
    formdata.append("noteparenttitle", noteparenttitle);
    formdata.append("noteparentclassname", noteparentclassname);
    formdata.append("noteparentdate", noteparentdate);
    formdata.append("noteparentsubject", noteparentsubject);
    formdata.append("noteparentcontent", noteparentcontent);

    try {
      const config = {
        url: "/addnoteparent",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("noticeboard add successfully");
        window.location.assign("/admin/noteparent");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="container mt-5">
      <Card
        className="text-center"
        style={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <h5 className="pt-5">Create Noticeboard for Parent</h5>
        <Card.Body>
          <Form className="w-50 mx-auto">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                onChange={(e) => setnoteparenttitle(e.target.value)}
              />
              {/* <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text> */}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Content</Form.Label>
              <Form.Control
                type="text"
                placeholder="Content"
                row="2"
                onChange={(e) => setnoteparentcontent(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Date"
                onChange={(e) => setnoteparentdate(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                placeholder="Subject"
                onChange={(e) => setnoteparentsubject(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>classname</Form.Label>
              <Form.Control
                type="text"
                placeholder="Date"
                onChange={(e) => setnoteparentclassname(e.target.value)}
              />
            </Form.Group>
          </Form>

          <button className="btn btn-primary" onClick={postimage}>
            Create Noticeboard
          </button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Addnoteparent;
