import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React from "react";
import './App.css';
import './admin.css';
import Header from "./Components/Header";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./Components/Home";
import Footter from "./Components/Footter";
import Aboutus from "./Components/Aboutus";
import Faculty from "./Components/Faculty";
import Gallery from "./Components/Gallery";
import SkillDevelopment from "./Components/SkillDevelopment";
import Traningandplacement from "./Components/Traningandplacement";
import Mcqtest from "./Components/Mcqtest";
import Subjective from "./Components/Subjective";
import ExamDesk from "./Components/ExamDesk";
import Contact from "./Components/Contact";
import Dashboard from "./Components/Admin/Dashboard";
import Anglearrow from "./Components/Anglearrow";
import EnquiryForm from "./Components/Admin/EnquiryForm";
import Admindashboard from "./Components/Admin/Admindashboard";
import Tution from "./Components/Tution";
import Faculti from "./Components/Faculti";
import ViewTutor from "./Components/ViewTutor";
import BecomeTutor from "./Components/BecomeTutor";
import AdminExamdesk from "./Components/Admin/AdminExamdesk";
import AdminTutionOnline from "./Components/Admin/AdminTutionOnline";
import AdminFaculty from "./Components/Admin/AdminFaculty";
import AdminGaller from "./Components/Admin/AdminGaller";
import AdminHometution from "./Components/Admin/AdminHometution";
import HomeContent from "./Components/Admin/HomeContent";
import AdminTestrimonials from "./Components/Admin/AdminTestrimonials";
import AdminBranches from "./Components/Admin/AdminBranches";
import AdminPlacements from "./Components/Admin/AdminPlacements";
import AdminCompanies from "./Components/Admin/AdminCompanies";
import AdminAboutus from "./Components/Admin/AdminAboutus";
import AdminBecomeatutor from "./Components/Admin/AdminBecomeatutor";
import AdminTraining from "./Components/Admin/AdminTraing";
import AdmnHometution from "./Components/Admin/AdmnHometution";
import Whatsapp from "./Components/Whatsapp/Whatsapp";
import AdminGraph from "./Components/Admin/AdminGraph";
import AdminMcq from "./Components/Admin/AdminMcq";
import AdminSubjective from "./Components/Admin/AdminSubjective";
import Terms from "./Components/Terms";
import Privacy from "./Components/Privacy";
import AdminWhyChooseus from "./Components/Admin/AdminWhyChooseus";
import AdminTution from "./Components/Admin/AdminTution";
import AdminPDP from "./Components/Admin/AdminPDP";
import Adminbecomeatutorcontent from "./Components/Admin/Adminbecomeatutorcontent";
import AdminTPContent from "./Components/Admin/AdminTPContent";
import AdminCertificates from "./Components/Admin/AdminCertificates";
import AdminContactus from "./Components/Admin/AdminContactus";
import Adminsignin from "./admin/Adminsignin";
import Adminlayout from "./admin/Adminlayout";
import Adminhome from "./admin/Adminhome";
import Adminuser from "./admin/Adminuser";
import Admincontent from "./admin/Admincontent";
import Adminslider from "./admin/Adminslider";
import Adminevent from "./admin/Adminevent";
import Addslider from "./admin/Addslider";
import Addcontent from "./admin/Addcontent";
import Addnew from "./admin/Addnew";
import Addstudymaterial from "./admin/Addstudymaterial";

import Adminstudymaterial from "./admin/Adminstudymaterial";
import Addyoutube from "./admin/Addyoutube";
import Addexam from "./admin/Addexam";
import Adminexam from "./admin/Adminexam";

import Addassignment from "./admin/Addassignment";
import Adminassignment from "./admin/Adminassignment";
import Addspecialevent from "./admin/Addspecialevent";
import Adminspecialevent from "./admin/Adminspecialevent";
//import Adminsidebar from "./admin/Adminsidebar";

import Adminbooking from "./admin/Adminbooking";
import Adminspecialbooking from "./admin/Adminspecialbooking";
import Adminspecialtransaction from "./admin/Adminspecialtransaction";
import Admineventtransaction from "./admin/Admineventtransaction";
import Admindonate from "./admin/Admindonate";
import Admindonatetransaction from "./admin/Admindonatetransaction";
import Adddonate from "./admin/Adddonate";
import Admincreateevent from "./admin/Admincreateevent";
import Addsubadmin from "./admin/Addsubadmin";
import Adminsubadmin from "./admin/Adminsubadmin";
import Adminconfirmcreateevent from "./admin/Adminconfirmcreateevent";
import Admincancelledcreateevent from "./admin/Admincancelledcreateevent";
import Adminyoutube from "./admin/Adminyoutube";
import Adminnoticeboard from "./admin/Adminnoticeboard";
import Addnoticeboard from "./admin/Addnoticeboard";
import Addteacherinfo from "./admin/Addteacherinfo";
import Adminteacherinfo from "./admin/Adminteacherinfo";
import Adminqrlocation from "./admin/Adminqrlocation";
import Addnoteteacher from "./admin/Addnoteteacher";
import Adminnoteteacher from "./admin/Adminnoteteacher";
import Addnotestudent from "./admin/Addnotestudent";
import Adminnotestudent from "./admin/Adminnotestudent";
import Addnoteparent from "./admin/Addnoteparent";
import Adminnoteparent from "./admin/Adminnoteparent";

import Allnote from "./admin/Allnote";
import Addmcq from "./admin/Addmcq";

import Adminstudent from "./admin/Adminstudent";

import Adminteacher from "./admin/Adminteacher";

import Adminparent from "./admin/Adminparent";
import Adminfeepending from "./admin/Adminfeepending";
import Adminfeepaid from "./admin/Adminfeepaid";
import Adminmcq from "./admin/Adminmcq";
import Adminteacherclass from "./admin/Adminteacherclass";
import Adminstudentclass from "./admin/Adminstudentclass";
import Admincreateclass from "./admin/Admincreateclass";
import Adminclass from "./admin/Adminclass";

import Adminfeedback from "./admin/Adminfeedback";

import Adminzoom from "./admin/Adminzoom";
import Adminstudentrating from "./admin/Adminstudentrating";
import Adminteacherrating from "./admin/Adminteacherrating";
import Uploadmcqquestion from "./admin/Uploadmcqquestion";

import Adminsignin1 from "./Components/Admin/Adminsignin";

import CompletedAssignment from "./admin/Admincompletedassignment";
import CompletedExam from "./admin/Admincompletedexam";
import CompletedMCQ from "./admin/Admincompletedmcq";
import BranchEnquiryForm from "./Components/Admin/AdminBranchenquiry";
import DashboardAdmin from "./admin/DASHBOARD";
function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/admin"
          element={
            <>
              <Adminsignin />
            </>
          }
        />
 <Route
          path="/admindashboard"
          element={
            <>
              <DashboardAdmin />
            </>
          }
        />

        <Route
          path="/privacy"
          element={
            <>
              <Privacy />
            </>
          }
        />
        <Route
          path="/admin/dashboard"
          element={
            <>
              <Adminlayout childern={<Adminhome />} />
            </>
          }
        />
        <Route
          path="/admin/user"
          element={
            <>
              <Adminlayout childern={<Adminuser />} />
            </>
          }
        />
        <Route
          path="/admin/assignment"
          element={
            <>
              <Adminlayout childern={<Adminassignment />} />
            </>
          }
        />
         <Route
          path="/admin/completedassignment"
          element={
            <>
              <Adminlayout childern={<CompletedAssignment />} />
            </>
          }
        />
        <Route
          path="/admin/completedexam"
          element={
            <>
              <Adminlayout childern={<CompletedExam />} />
            </>
          }
        />
        <Route
          path="/admin/completedmcq"
          element={
            <>
              <Adminlayout childern={<CompletedMCQ />} />
            </>
          }
        />
        <Route
          path="/admin/content"
          element={
            <>
              <Adminlayout childern={<Admincontent />} />
            </>
          }
        />
        <Route
          path="/admin/addslider"
          element={
            <>
              <Adminlayout childern={<Addslider />} />
            </>
          }
        />

        <Route
          path="/admin/teacherlocation"
          element={
            <>
              <Adminlayout childern={<Adminqrlocation />} />
            </>
          }
        />
        <Route
          path="/admin/studentrating"
          element={
            <>
              <Adminlayout childern={<Adminstudentrating />} />
            </>
          }
        />
        <Route
          path="/admin/teacherrating"
          element={
            <>
              <Adminlayout childern={<Adminteacherrating />} />
            </>
          }
        />

        <Route
          path="/admin/addexam"
          element={
            <>
              <Adminlayout childern={<Addexam />} />
            </>
          }
        />
        <Route
          path="/admin/exam"
          element={
            <>
              <Adminlayout childern={<Adminexam />} />
            </>
          }
        />
        <Route
          path="/admin/allnote"
          element={
            <>
              <Adminlayout childern={<Allnote />} />
            </>
          }
        />
        <Route
          path="/admin/exam"
          element={
            <>
              <Adminlayout childern={<Adminexam />} />
            </>
          }
        />
        <Route
          path="/admin/addstudymaterial"
          element={
            <>
              <Adminlayout childern={<Addstudymaterial />} />
            </>
          }
        />
        <Route
          path="/admin/studymaterial"
          element={
            <>
              <Adminlayout childern={<Adminstudymaterial />} />
            </>
          }
        />
        <Route
          path="/admin/mcq"
          element={
            <>
              <Adminlayout childern={<Adminmcq />} />
            </>
          }
        />
        <Route
          path="/admin/uploadmcq"
          element={
            <>
              <Adminlayout childern={<Uploadmcqquestion />} />
            </>
          }
        />
        <Route
          path="/admin/teacherclass"
          element={
            <>
              <Adminlayout childern={<Adminteacherclass />} />
            </>
          }
        />
        <Route
          path="/admin/studentclass"
          element={
            <>
              <Adminlayout childern={<Adminstudentclass />} />
            </>
          }
        />
        <Route
          path="/admin/createclass"
          element={
            <>
              <Adminlayout childern={<Admincreateclass />} />
            </>
          }
        />
        <Route
          path="/admin/classlist"
          element={
            <>
              <Adminlayout childern={<Adminclass />} />
            </>
          }
        />

        <Route
          path="/admin/addsubadmin"
          element={
            <>
              <Adminlayout childern={<Addsubadmin />} />
            </>
          }
        />
        <Route
          path="/admin/adminsubadmin"
          element={
            <>
              <Adminlayout childern={<Adminsubadmin />} />
            </>
          }
        />
        <Route
          path="/admin/addnoticeboard"
          element={
            <>
              <Adminlayout childern={<Addnoticeboard />} />
            </>
          }
        />
        <Route
          path="/admin/addnoteteacher"
          element={
            <>
              <Adminlayout childern={<Addnoteteacher />} />
            </>
          }
        />
        <Route
          path="/admin/noteteacher"
          element={
            <>
              <Adminlayout childern={<Adminnoteteacher />} />
            </>
          }
        />
        <Route
          path="/admin/notestudent"
          element={
            <>
              <Adminlayout childern={<Adminnotestudent />} />
            </>
          }
        />
        <Route
          path="/admin/addnotestudent"
          element={
            <>
              <Adminlayout childern={<Addnotestudent />} />
            </>
          }
        />

        <Route
          path="/admin/studentlist"
          element={
            <>
              <Adminlayout childern={<Adminstudent />} />
            </>
          }
        />

        <Route
          path="/admin/teacherlist"
          element={
            <>
              <Adminlayout childern={<Adminteacher />} />
            </>
          }
        />
        <Route
          path="/admin/feepaid"
          element={
            <>
              <Adminlayout childern={<Adminfeepaid />} />
            </>
          }
        />

        <Route
          path="/admin/feedback"
          element={
            <>
              <Adminlayout childern={<Adminfeedback />} />
            </>
          }
        />
        <Route
          path="/admin/zoom"
          element={
            <>
              <Adminlayout childern={<Adminzoom />} />
            </>
          }
        />

        <Route
          path="/admin/feepending"
          element={
            <>
              <Adminlayout childern={<Adminfeepending />} />
            </>
          }
        />

        <Route
          path="/admin/parentlist"
          element={
            <>
              <Adminlayout childern={<Adminparent />} />
            </>
          }
        />
        <Route
          path="/admin/addmcq"
          element={
            <>
              <Adminlayout childern={<Addmcq />} />
            </>
          }
        />
        <Route
          path="/admin/addnoteparent"
          element={
            <>
              <Adminlayout childern={<Addnoteparent />} />
            </>
          }
        />
        <Route
          path="/admin/noteparent"
          element={
            <>
              <Adminlayout childern={<Adminnoteparent />} />
            </>
          }
        />
        <Route
          path="/admin/noticeboard"
          element={
            <>
              <Adminlayout childern={<Adminnoticeboard />} />
            </>
          }
        />
        <Route
          path="/admin/specialtransaction"
          element={
            <>
              <Adminlayout childern={<Adminspecialtransaction />} />
            </>
          }
        />
        <Route
          path="/admin/eventtransaction"
          element={
            <>
              <Adminlayout childern={<Admineventtransaction />} />
            </>
          }
        />

        <Route
          path="/admin/addcontent"
          element={
            <>
              <Adminlayout childern={<Addcontent />} />
            </>
          }
        />
        <Route
          path="/admin/addyoutube"
          element={
            <>
              <Adminlayout childern={<Addyoutube />} />
            </>
          }
        />

        <Route
          path="/admin/addteacherinfo"
          element={
            <>
              <Adminlayout childern={<Addteacherinfo />} />
            </>
          }
        />

        <Route
          path="/admin/teacherinfo"
          element={
            <>
              <Adminlayout childern={<Adminteacherinfo />} />
            </>
          }
        />
        <Route
          path="/admin/youtube"
          element={
            <>
              <Adminlayout childern={<Adminyoutube />} />
            </>
          }
        />
        <Route
          path="/admin/addassignment"
          element={
            <>
              <Adminlayout childern={<Addassignment />} />
            </>
          }
        />
        <Route
          path="/admin/addnew"
          element={
            <>
              <Adminlayout childern={<Addnew />} />
            </>
          }
        />
        <Route
          path="/admin/addspecialevent"
          element={
            <>
              <Adminlayout childern={<Addspecialevent />} />
            </>
          }
        />
        <Route
          path="/admin/eventbooking"
          element={
            <>
              <Adminlayout childern={<Adminbooking />} />
            </>
          }
        />
        <Route
          path="/admin/specialeventbooking"
          element={
            <>
              <Adminlayout childern={<Adminspecialbooking />} />
            </>
          }
        />
        <Route
          path="/admin/donatetransaction"
          element={
            <>
              <Adminlayout childern={<Admindonatetransaction />} />
            </>
          }
        />
        <Route
          path="/admin/createevent"
          element={
            <>
              <Adminlayout childern={<Admincreateevent />} />
            </>
          }
        />
        <Route
          path="/admin/donate"
          element={
            <>
              <Adminlayout childern={<Admindonate />} />
            </>
          }
        />
        <Route
          path="/admin/adddonate"
          element={
            <>
              <Adminlayout childern={<Adddonate />} />
            </>
          }
        />

        <Route
          path="/admin/slider"
          element={
            <>
              <Adminlayout childern={<Adminslider />} />
            </>
          }
        />
        <Route
          path="/admin/event"
          element={
            <>
              <Adminlayout childern={<Adminevent />} />
            </>
          }
        />
        <Route
          path="/admin/specialevent"
          element={
            <>
              <Adminlayout childern={<Adminspecialevent />} />
            </>
          }
        />
        <Route
          path="/admin/confirmcreateevent"
          element={
            <>
              <Adminlayout childern={<Adminconfirmcreateevent />} />
            </>
          }
        />
        <Route
          path="/admin/cancelledcreateevent"
          element={
            <>
              <Adminlayout childern={<Admincancelledcreateevent />} />
            </>
          }
        />
        {/* Amandeep */}
        <Route
          path="/adminweb"
          element={
            <>
              <Adminsignin1 />
            </>
          }
        />
       <Route path="/" element={<><Header /><Home /><Whatsapp/><Footter /></>} />
          <Route path="/aboutus" element={<><Header /><Aboutus /><Whatsapp/><Footter /></>} />
          <Route path="/faculty" element={<><Header /><Faculty /><Whatsapp/><Footter /></>} />
          <Route path="/gallery" element={<><Header /><Gallery /><Whatsapp/><Footter /></>} />
          <Route path="/skilldevelopment" element={<><Header /><SkillDevelopment /><Whatsapp/><Footter /></>} />
          <Route path="/trainingandplacement" element={<><Header /><Traningandplacement /><Whatsapp/><Footter /></>} />
          <Route path="/mcqtest" element={<><Header /><Mcqtest /><Whatsapp/><Footter /></>} />
          <Route path="/subjective" element={<><Header /><Subjective /><Whatsapp/><Footter /></>} />
          <Route path="/examdesk" element={<><Header /><ExamDesk /><Whatsapp/><Footter /></>} />
          <Route path="/contact" element={<><Header /><Contact /><Whatsapp/><Footter /></>} />
          <Route path="/tution" element={<><Header /><Tution /><Whatsapp/><Footter /></>} />
          <Route path="/faculti" element={<><Header /><Faculti /><Whatsapp/><Footter /></>} />
          <Route path="/tutor" element={<><Header /><ViewTutor /><Whatsapp/><Footter /></>} />
          <Route path="/becomeatutor" element={<><Header /><BecomeTutor /><Whatsapp/><Footter /></>} />
          <Route path="/terms" element={<><Terms /></>} />
          <Route path="/privacy" element={<><Privacy /></>} />

          <Route
            path="/enquiryform"
            element={
              <Dashboard
                children={
                  <>
                    <EnquiryForm />

                  </>
                }
              />
            }
          />
          <Route
            path="/dashboard"
            element={
              <Dashboard
                children={
                  <>
                    <Admindashboard />

                  </>
                }
              />
            }
          />
          <Route
            path="/adminexamdesk"
            element={
              <Dashboard
                children={
                  <>
                    <AdminExamdesk />

                  </>
                }
              />
            }
          />
          <Route
            path="/admintutiononline"
            element={
              <Dashboard
                children={
                  <>
                    <AdminTutionOnline />

                  </>
                }
              />
            }
          />
          <Route
            path="/adminfaculty"
            element={
              <Dashboard
                children={
                  <>
                    <AdminFaculty />
                  </>
                }
              />
            }
          />
          <Route
            path="/admingallery"
            element={
              <Dashboard
                children={
                  <>
                    <AdminGaller />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminhometution"
            element={
              <Dashboard
                children={
                  <>
                    <AdminHometution />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminhomecontent"
            element={
              <Dashboard
                children={
                  <>
                    <HomeContent />
                  </>
                }
              />
            }
          />
          <Route
            path="/admintestimonaqls"
            element={
              <Dashboard
                children={
                  <>
                    <AdminTestrimonials />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminbranches"
            element={
              <Dashboard
                children={
                  <>
                    <AdminBranches />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminplacements"
            element={
              <Dashboard
                children={
                  <>
                    <AdminPlacements />
                  </>
                }
              />
            }
          />
          <Route
            path="/admincompanies"
            element={
              <Dashboard
                children={
                  <>
                    <AdminCompanies />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminaboutus"
            element={
              <Dashboard
                children={
                  <>
                    <AdminAboutus />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminbecomeatutor"
            element={
              <Dashboard
                children={
                  <>
                    <AdminBecomeatutor />
                  </>
                }
              />
            }
          />
          <Route
            path="/admintraining"
            element={
              <Dashboard
                children={
                  <>
                    <AdminTraining />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminbecomeatutor"
            element={
              <Dashboard
                children={
                  <>
                    <AdminBecomeatutor />
                  </>
                }
              />
            }
          />
          <Route
            path="/admnhometution"
            element={
              <Dashboard
                children={
                  <>
                    <AdmnHometution />
                  </>
                }
              />
            }
          />
          <Route
            path="/admngraph"
            element={
              <Dashboard
                children={
                  <>
                    <AdminGraph />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminmcq"
            element={
              <Dashboard
                children={
                  <>
                    <AdminMcq />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminsubjective"
            element={
              <Dashboard
                children={
                  <>
                    <AdminSubjective />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminwhychooseus"
            element={
              <Dashboard
                children={
                  <>
                    <AdminWhyChooseus />
                  </>
                }
              />
            }
          />
          <Route
            path="/admintution"
            element={
              <Dashboard
                children={
                  <>
                    <AdminTution />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminpdp"
            element={
              <Dashboard
                children={
                  <>
                    <AdminPDP />
                  </>
                }
              />
            }
          />
          <Route
            path="/adminbecomeatutorcontent"
            element={
              <Dashboard
                children={
                  <>
                    <Adminbecomeatutorcontent />
                  </>
                }
              />
            }
          />
          <Route
            path="/admintpcontent"
            element={
              <Dashboard
                children={
                  <>
                    <AdminTPContent />
                  </>
                }
              />
            }
          />
          <Route
            path="/admincertificates"
            element={
              <Dashboard
                children={
                  <>
                    <AdminCertificates />
                  </>
                }
              />
            }
          />
          <Route
            path="/admincontactus"
            element={
              <Dashboard
                children={
                  <>
                    <AdminContactus />
                  </>
                }
              />
            }
          />
           <Route
            path="/branchenquiry"
            element={
              <Dashboard
                children={
                  <>
                    <BranchEnquiryForm />
                  </>
                }
              />
            }
          />


    
</Routes>

    </Router>
  );
}

export default App;
