import React from "react";
import { Navbar, Form, Nav, Button, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { IconButton } from "@material-ui/core";
import Logo from "../assets/logo.jpg";

function Adminheader() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userdata = JSON.parse(sessionStorage.getItem("user"));
  const admin = JSON.parse(sessionStorage.getItem("admin")); 
   const profile = JSON.parse(sessionStorage.getItem("profile"));
  const apiURL = process.env.REACT_APP_API_URL;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const signout = () => {
   
            sessionStorage.removeItem("admin");
            window.location.assign("/admin")
           alert("Logout successfully")
           
        }
 

  const profiledetails = () => {
    window.location.assign("/doctor/profile");
  };
  return (
    <Navbar
      dark
      expand="md"
      style={{
        backgroundColor: "#01a695",
        borderBottom: "1px solid hsla(0,0%,100%,.5)!important",
      }}
    >
      <Navbar.Brand href="/admins/dashboard" >
        <img src={Logo} height="60px" width="60px" style={{marginTop:"-20px",marginBottom:"-20px",backgroundColor:'#01a695',marginLeft:"30px"}} />
        {/* <strong style={{ color: "blue", paddingLeft: "40px" }}>
          DoorStep Tuition
        </strong> */}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav    className="ms-auto my-2 my-lg-0"

navbarScroll>
        <div>
                  <i class="fa-solid fa-power-off" style={{fontSize:"25px", marginRight:"20px",color:'white'}} onClick={signout}></i>
                </div>
        </Nav>
        

            {/* <NavDropdown className="mr-5" title={<span><Avatar style={{height:"30px", width:"30px"}}/></span>} id="basic-nav-dropdown" >
          <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
          <NavDropdown.Item href="/changepassword">Change Password</NavDropdown.Item>
          <NavDropdown.Item href="/orders">Orders</NavDropdown.Item>
          <NavDropdown.Item href="/subscription">Subscripation</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={signout}>Sign Out</NavDropdown.Item>
        </NavDropdown> */}
        
       
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Adminheader;
