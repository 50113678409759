import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Card, Button, Carousel, Modal, Form } from "react-bootstrap";

function Admindonatetransaction() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [number, setNumber] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showcPassword, setShowcPassword] = useState(false);
  const [paymentmethod, setPaymentMethod] = useState(null);
  const Onsubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("");
    } else if (!name | !email) {
      alert("Please Fill All Field");
    } else {
      try {
        const config = {
          url: "/",
          method: "post",
          baseURL: "https://doorstepedu.info/api",
          headers: { "content-type": "application/json" },
          data: {
            name: name,
            email: email,
            password: password,
            cPassword: confirmPassword,
            phoneNumber: number,
          },
          //responseType:"application/json"
        };
        let res = await axios(config);
        if (res.status === 200) {
          console.log(res.data);
          alert(res.data.success);
          // sessionStorage.setItem("signupdata",JSON.stringify(res.data.user));
          //window.location.assign("/login");
          // sendotp();
          // localStorage.setItem("userdata",JSON.stringify(res.data.user));
          // localStorage.setItem("token",res.data.token);
          // window.location.assign("/signin");
          return res;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div>
      <div className="container">
        <div className="row" style={{ padding: " 50px 30px" }}>
          <div className="col-md-12 t-c ">
            <h1
              className=""
              style={{ padding: "20px 20px 50px", color: "Navyblue" }}
            >
              {" "}
              Donation
            </h1>
          </div>

          <div className="col-md-12">
            <form>
              <div className="row ">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <div className="form-group pb-2">
                    <label className="f-w-700 ">Name</label>
                    <input
                      className="form-control "
                      style={{
                        background: "#fff",
                        padding: "10px;",
                        height: "60px",
                      }}
                      placeholder="Enter Name"
                      onChange={(e) => setName(e.target.value)}
                    ></input>
                  </div>
                  <div className="form-group pb-2">
                    <label className="f-w-700 ">Email ID</label>
                    <input
                      className="form-control "
                      style={{
                        background: "#fff",
                        padding: "10px;",
                        height: "60px",
                      }}
                      placeholder="Enter Email"
                      onChange={(e) => setEmail(e.target.value)}
                    ></input>
                  </div>
                  <div className="form-group pb-2">
                    <label className="f-w-700 "> PhoneNumber</label>
                    <input
                      className="form-control "
                      style={{
                        background: "#fff",
                        padding: "10px;",
                        height: "60px",
                      }}
                      placeholder="Enter Phone"
                      onChange={(e) => setEmail(e.target.value)}
                    ></input>
                  </div>

                  <div className="form-group pb-2">
                    <label className="f-w-700 ">Address</label>
                    <input
                      className="form-control "
                      style={{
                        background: "#fff",
                        padding: "10px;",
                        height: "60px",
                      }}
                      placeholder="Enter amount"
                      onChange={(e) => setName(e.target.value)}
                    ></input>
                  </div>
                  <div className="form-group pb-2">
                    <label className="f-w-700 ">Amount</label>
                    <input
                      className="form-control "
                      style={{
                        background: "#fff",
                        padding: "10px;",
                        height: "60px",
                      }}
                      placeholder="Enter amount"
                      onChange={(e) => setName(e.target.value)}
                    ></input>
                  </div>
                  <div className="form-group pb-2">
                    <label className="f-w-700 ">Pay method</label>
                    <Form.Control
                      as="select"
                      //onChange={(e)=>setPaymentMode(e.target.value)}
                    >
                      <option>Select payment Method</option>
                      <option value="COD">Cash Temple </option>
                      <option value="online">Card </option>
                    </Form.Control>
                    {/* <input className="form-control " type="password" style={{ background:"#fff", padding:"10px;" , height:"60px"}} placeholder="Mode" onChange={(e)=>setConfirmPassword(e.target.value)}></input> */}
                  </div>
                  <div className="form-group pt-4">
                    <button
                      className="btn btn-primary form-control"
                      style={{
                        padding: "5px",
                        background: "Navyblue",
                        height: "60px",
                      }}
                      onClick={Onsubmit}
                    >
                      {" "}
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="col-md-6">
            <form>
              <div className="row ">
                <div className="col-md-12">
                  {/* <div className="form-group pb-2">
         <label className="f-w-700 ">Age</label>
         <input className="form-control " style={{ background:"#fff", padding:"10px;"}} placeholder="Enter here"></input>
       </div> */}
                </div>
              </div>
            </form>
          </div>
          <div className="col-md-3"></div>
          <div
            className="col-md-6 pt-2"
            style={{ padding: "20px 20px 10px", float: "right" }}
          >
            <div className="ml-5"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admindonatetransaction;
