import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const Header = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [EName, setEName] = useState("");
  const [EPhone, setEPhone] = useState("");
  const [EEmail, setEEmail] = useState("");
  const [Emsg, setEmsg] = useState("");

  const Enquiryf = async () => {
    try {


      const config = {
        url: "/user/enquiry",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "application/json" },
        data: {
          EName: EName,
          EPhone: EPhone,
          EEmail: EEmail,
          Emsg: Emsg,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  const [AddContactuse, setAddContactuse] = useState([]);
  const getAddContactuss = async () => {
    try {
      // alert("fdffdd")
      let res = await axios.get(
        "https://doorstepedu.info/api/user/getallContactus"
      );
      if (res.status == 200) {
        setAddContactuse(res.data.getContactusnow);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddContactuss();
  }, []);
  console.log(AddContactuse);
  return (
    <>
      <Navbar bg="light" data-bs-theme="light">
        <Container>
          <Navbar.Brand href="/" className="text-white">
            <img
              src="./logo.jpeg"
              alt=""
              style={{ width: "100%", height: "60px" }}
            />
          </Navbar.Brand>

          <Nav className="ms-auto">
            {AddContactuse?.map((item) => {
              return (
                <>
                  <Nav.Link href="#home" className="dfgh_4">
                    <FontAwesomeIcon
                      icon={faPhone}
                      beat
                      style={{ color: "#00a695" }}
                    />{" "}
                    {item?.Phone}
                  </Nav.Link>
                  <Nav.Link href="#features" className="dfgh_4">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      beat
                      style={{ color: "#00a695" }}
                    />{" "}
                    {item?.Email}
                  </Nav.Link>
                </>
              );
            })}

            <button className="btn10" onClick={handleShow}>
              ENQUIRE NOW
            </button>
          </Nav>
        </Container>
      </Navbar>

      <Navbar
        style={{ backgroundColor: "#00a695", padding: "10px" }}
        expand="lg"
      >
        <Container fluid>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            style={{ marginLeft: "200px" }}
          >
            <Nav className="ms-auto">
              <div className="dropdown">
                <a href="/" className="dropbtn">
                  HOME
                </a>
              </div>
              {/* <Nav.Link href="/aboutus"> ABOUT US</Nav.Link>
              <Nav.Link href="/faculty">FACULTY</Nav.Link> */}

              <div className="dropdown">
                <a href="/aboutus" class="dropbtn">
                  ABOUT US
                </a>

                <div className="dropdown-content">
                  <a href="#faculty">FACULTY</a>
                  <a href="#gallery">GALLERY</a>
                  {/* <a href="#app">STCMS [App]</a> */}
                </div>
              </div>
              {/* <Nav.Link href="/branches">BRANCHES</Nav.Link> */}

              {/* <NavDropdown title="CLASSES" id="basic-nav-dropdown">
                <NavDropdown.Item href="/insurance">ONLINE</NavDropdown.Item>
                <NavDropdown.Item
                  href="/insurance"
                  style={{ borderBottom: "unset" }}
                >
                  OFFLINE
                </NavDropdown.Item>
              </NavDropdown> */}

              <div className="dropdown">
                <a href="/examdesk" className="dropbtn">
                  EXAM DESK
                </a>

                <div className="dropdown-content">
                  <a href="#mcq">MCQ TEST</a>
                  <a href="#subjective">SUBJECTIVE TEST</a>
                </div>
              </div>
              <div className="dropdown">
                <a href="/tution" class="dropbtn">
                  TUITION
                </a>

                <div className="dropdown-content">
                  <a href="#online">ONLINE</a>
                  <a href="#hometution">HOME TUITION</a>
                  <a href="#institute">INSTITUTE</a>
                </div>
              </div>
              <div className="dropdown">
                <a href="/trainingandplacement" class="dropbtn">
                  TEACHER TRAINING & PLACEMENT
                </a>

                <div className="dropdown-content">
                  <a href="/skilldevelopment">SKILL DEVELOPMENT</a>
                  <a href="/becomeatutor">BECOME A TUTOR</a>
                </div>
              </div>

              <div className="dropdown">
                <a href="/contact" className="dropbtn">
                  CONTACT US
                </a>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>Enquire Now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <FloatingLabel controlId="floatingInput" label="Enter Your Name">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => {
                setEName(e.target.value);
              }}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Mobile No">
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => {
                setEPhone(e.target.value);
              }}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Email">
            <Form.Control
              type="email"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => {
                setEEmail(e.target.value);
              }}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingTextarea2" label="Message">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              className="rtyui_0"
              onChange={(e) => {
                setEmsg(e.target.value);
              }}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            onClick={Enquiryf}
            style={{ backgroundColor: "#00a695", border: "none" }}
          >
            Send Request
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Header;
