import React from "react";
import { NavLink } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import { BiLogOutCircle } from "react-icons/bi";
// import { BsFillChatDotsFill, BsWhatsapp } from "react-icons/bs";
// import { GrContact } from "react-icons/gr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

function AdminHeader() {
  return (
    <>
      <div
        className="header"
        style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" }}
      >
        <Navbar expand="lg">
          <Container fluid>
            <Navbar.Brand href="#">
              {/* <p className=""></p> */}
              <h3 style={
                {
                  color: "rgb(6 160 150)",
                  fontWeight: "600",
                  fontSize: "29px",

                }}>Admin</h3>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="ms-auto my-2 my-lg-0"

                navbarScroll
              >
                <div>
                  <i class="fa-solid fa-power-off" style={{fontSize:"25px", marginRight:"20px"}}></i>
                </div>

                {/* <div className=" d-flex align-items-center justify-content-center" >
                  <div class="dropdown ">
                    <FontAwesomeIcon icon={faUser}  style={{fontSize:"20px"}}/>

                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="/profile">Profile</a></li>
                      <li><a class="dropdown-item" href="/dashboard"> Logout</a></li>

                    </ul>
                  </div>
                  <div className="m-3" style={{ fontSize: "17px", fontWeight: "600", color: "rgb(6 160 150)" }}>
                    Damon Salvatore{" "}
                    <div
                      className="d-block"
                      style={{ fontSize: "14px", fontWeight: "600" }}
                    >
                      ID:38572147
                    </div>
                  </div>
                </div> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default AdminHeader;
