import React, { useEffect, useState } from "react";
import { Button, Table, Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import axios from "axios";

const AdminWhyChooseus = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // integrating post method
  const formdata = new FormData();
  const [WImage, setWImage] = useState("");
  const [WText, setWText] = useState("");
  const AddWhyChooseus = async () => {
    formdata.append("WImage", WImage);
    formdata.append("WText", WText);

    try {
      const config = {
        url: "/user/WhyChooseus",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddWhyChooseus();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddWhyChooseuss, setAddWhyChooseuss] = useState([]);
  const getAddWhyChooseus = async () => {
    try {
      let res = await axios.get(
        "https://doorstepedu.info/api/user/getWhyChooseus"
      );
      if (res.status === 200) {
        setAddWhyChooseuss(res.data.getWhyChooseus);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data, setData] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setData(item);
  };
  const DeleteWhyChooseus = async () => {
    try {
      const config = {
        url: "user/DeleteWhyChooseus/" + Data,
        method: "delete",
        baseURL: "https://doorstepedu.info/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getAddWhyChooseus();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [Data1, setData1] = useState("");
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setData1(item);
  };

  const editWhyChooseus = async (e) => {
    e.preventDefault();
    if (!WImage) {
      alert("Select the image");
    } else {
      formdata.append("WImage", WImage);
      formdata.append("WText", WText);
      formdata.append("id", Data1?._id);
      try {
        const config = {
          url: "/user/editWhyChooseus",
          method: "put",
          baseURL: "https://doorstepedu.info/api",
          header: { "content-type": "multipart/form-data" },
          data: formdata,
        };
        await axios(config).then((res) => {
          if (res.status === 200) {
            alert("successfully Update");
            handleClose1();
            getAddWhyChooseus();
          }
        });
      } catch (error) {
        console.log(error);
        alert(error.response.data.error);
      }
    }
  };
  useEffect(() => {
    getAddWhyChooseus();
  }, []);
  console.log(AddWhyChooseuss);
  return (
    <>

      <div className="add-gr">
        <div className="container">
          <div className="ad-b mt-4 mb-4">
            {AddWhyChooseuss.length !== 0 ? (
              <></>
            ) : (
              <>
                {" "}
                <button className="btn btn-success" onClick={handleShow}>
                  Add Why Choose us Content
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra mt-5">
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Image</th>
                <th>Description</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {AddWhyChooseuss?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <Image
                        src={`https://doorstepedu.info/WhyChooseus/${item?.WImage}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      />
                    </td>

                    <td style={{ paddingTop: "20px" }}>{item?.WText}</td>

                    <td>
                      <div className="ad-b ">
                        <span>
                          <i
                            class="fas fa-edit"
                            style={{
                              fontSize: "20px",
                              color: "green",
                              paddingTop: "20px",
                            }}
                            onClick={() => handleShow1(item)}
                          // title="edit"
                          ></i>
                        </span>
                      </div>
                    </td>
                    <td>
                      <i
                        class="fa fa-trash"
                        aria-hidden="true"
                        style={{
                          color: "red",
                          fontSize: "20px",
                          paddingTop: "20px",
                        }}
                        onClick={() => handleShow2(item?._id)}
                      // title="delete"
                      ></i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>
            {" "}
            Add WhyChooseus
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="floatingInput" label="Image">
            <Form.Control
              type="file"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setWImage(e.target.files[0])}
            />
          </FloatingLabel>{" "}

          <FloatingLabel controlId="floatingTextarea2" label="Description">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              className="rtyui_0"
              onChange={(e) => setWText(e.target.value)}
              style={{ height: '100px' }}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#00a695", border: "none" }}
            onClick={AddWhyChooseus}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>Update WhyChooseUs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="floatingInput" label="Image">
            <Form.Control
              type="file"
              placeholder={WImage}
              className="rtyui_0"
              onChange={(e) => setWImage(e.target.files[0])}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingTextarea2" label="Description">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              className="rtyui_0"
              onChange={(e) => setWText(e.target.value)}
              style={{ height: '100px' }}
            />
          </FloatingLabel>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#00a695", border: "none" }}
            onClick={editWhyChooseus}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={DeleteWhyChooseus}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminWhyChooseus;
