import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import axios from "axios";
import moment from "moment";

function Admincreateclass() {
  const [data, setdata] = useState([]);
  const [data1, setdata1] = useState([]);
  const [standard, setstandard] = useState("");
  const [subject, setsubject] = useState("");
  const [time, settime] = useState("");
  const [time2, settime2] = useState("");
  const [type, settype] = useState("");

  const [teacheremail, setteacheremail] = useState("");
  const [studentemail, setstudentemail] = useState(null);
  const [others, setothers] = useState("");
  const [date, setdate] = useState("");

  const formdata = new FormData();

  const postimage = async () => {
    if(!teacheremail ||  !time || !time2 || !date || !others || !subject || !standard || !type){
      alert('Please fill all the fields');
    } else {
    try {
      const config = {
        url: "/admin/addcreateclass",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "content-type": "application/json" },
        data: {
          teacherid: teacheremail,
          studentid: studentemail,
          time: time,
          time2: time2,
          date: moment(date).format('DD/MM/YYYY'),
          type:type,
          other: others,
          subject: subject,
          standard: standard,
        },
        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("Create Class add successfully");
        window.location.assign("/admin/classlist");
      }
    } catch (error) {
      console.log(error);
    }
  }
  };
  console.log(studentemail, teacheremail);

  useEffect(() => {
    getusers();
  }, []);

  const getusers = () => {
    try {
      axios
        .get(`https://doorstepedu.info/api/user/getallUser1`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata1(response.data.success);
            return;
          } else {
            //alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };

  useEffect(() => {
    getteacherusers();
  }, []);

  const getteacherusers = () => {
    try {
      axios
        .get(`https://doorstepedu.info/api/user/getAllUser`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata(response.data.success);
            return;
          } else {
            //alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };
  return (
    <div className="container mt-5">
      <Card
        className="text-center"
        style={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <h5 className="pt-5">Add Create Class</h5>
        <Card.Body>
        <div>
            <label> Subject </label>
            <input
              className="form-control"
              row="2"
              onChange={(e) => setsubject(e.target.value)}
            ></input>
          </div>
          <div>
            <label> Date </label>
            <input
              className="form-control"
              type="date"
              onChange={(e) => setdate(e.target.value)}
            ></input>
          </div>
          <div>
            <label> Start Time </label>
            <input
              className="form-control"
              type="time"
              onChange={(e) => settime(e.target.value)}
            ></input>
          </div>
          <div>
            <label> End Time </label>
            <input
              className="form-control"
              type="time"
              onChange={(e) => settime2(e.target.value)}
            ></input>
          </div>

          <div className="form-group basic">
            <div className="input-wrapper">
              <label className="label" for="select4">
                Teacher
              </label>
              <select
                className="form-control custom-select"
                id="select4"
                onChange={(e) => setteacheremail(e.target.value)}
              >
                <option value="">Select Teacher </option>
                {data.map((email) => (
                  <option value={email._id}>{email.name}</option>
                ))}
              </select>
            </div>
          </div>
          {/* <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="select4">
                      ClassName list
                    </label>
                    <select
                      className="form-control custom-select"
                      id="select4"
                      onChange={(e) => setstandard(e.target.value)}
                    >
                      <option value="">Select class</option>
                      <option value="Class 1">Class 1</option>
                  <option value="Class 2">Class 2</option>
                  <option value="Class 3">Class 3</option>
                  <option value="Class 4">Class 4</option>
                  <option value="Class 5">Class 5</option>
                  <option value="Class 6">Class 6</option>
                  <option value="Class 7">Class 7</option>
                  <option value="Class 8">Class 8</option>
                  <option value="Class 9">Class 9</option>
                  <option value="Class 10">Class 10</option>
                  <option value="Class 11">Class 11</option>
                  <option value="Class 12">Class 12</option>
                    </select>
                  </div>
                </div>
          <div className="form-group basic">
            <div className="input-wrapper">
              <label className="label" for="select4">
                Student
              </label>
              <select
                className="form-control custom-select"
                id="select4"
                onChange={(e) => setstudentemail(e.target.value)}
              >
                <option value="">Select Student </option>
                {data1.filter((data)=> data.standard === standard).map((email) => (
                  <option value={email._id}>{email.name}</option>
                ))}
              </select>
            </div>
          </div> */}
 <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="select4">
                      Class Type
                    </label>
                    <select
                      className="form-control custom-select"
                      id="select4"
                      onChange={(e) => settype(e.target.value)}
                    >
                      <option value="">Select Class Type</option>
                      <option value="standard">Whole class</option>
                      <option value="student">Individual student Name</option>
                    </select>
                  </div>
                </div>

                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="select4">
                      ClassName list
                    </label>
                    <select
                      className="form-control custom-select"
                      id="select4"
                      onChange={(e) => setstandard(e.target.value)}
                    >
                     <option value="Class 1">Class 1</option>
                  <option value="Class 2">Class 2</option>
                  <option value="Class 3">Class 3</option>
                  <option value="Class 4">Class 4</option>
                  <option value="Class 5">Class 5</option>
                  <option value="Class 6">Class 6</option>
                  <option value="Class 7">Class 7</option>
                  <option value="Class 8">Class 8</option>
                  <option value="Class 9">Class 9</option>
                  <option value="Class 10">Class 10</option>
                  <option value="Class 11">Class 11</option>
                  <option value="Class 12">Class 12</option>
                    </select>
                  </div>
                </div>

                {type == "student" ? (
                  <>
                    {" "}
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" for="select4">
                          Student Name list
                        </label>
                        <select
                          className="form-control custom-select"
                          id="select4"
                          onChange={(e) => setstudentemail(e.target.value)}
                        >
                          <option value="">Select Student </option>
                          {data1.filter((data)=> data.standard === standard).map((name) => (
                            <option value={name._id}>{name.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>{" "}
                  </>
                ) : (
                  ""
                )}

       
          <div>
            <label> Others </label>
            <input
              className="form-control"
              row="2"
              onChange={(e) => setothers(e.target.value)}
            ></input>
          </div>

      

          <button className="btn btn-primary mt-5" onClick={postimage}>
            Create Class
          </button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Admincreateclass;
