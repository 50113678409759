import React, { useEffect, useState } from "react";
import { Button, Table, Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import axios from "axios";

const Adminbecomeatutorcontent = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // integrating post method
  const [BText1, setBText1] = useState("");
  const [BHeader, setBHeader] = useState("");

  const AddBecome = async () => {
    try {
      const config = {
        url: "/user/Become",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "application/json" },
        data: {
          BText1: BText1,
          BHeader: BHeader,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          getAddBecomes();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddBecomee, setAddBecomee] = useState([]);
  const getAddBecomes = async () => {
    try {
      // alert("fdffdd")
      let res = await axios.get("https://doorstepedu.info/api/user/getBecome");
      if (res.status == 200) {
        setAddBecomee(res.data.getBecome);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data, setData] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setData(item);
  };
  const deleteBecome = async () => {
    try {
      const config = {
        url: "user/deleteBecome/" + Data,
        method: "delete",
        baseURL: "https://doorstepedu.info/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getAddBecomes();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [Aexp, setAexp] = useState("");
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setAexp(item);
  };
  const editBecome = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/user/editBecome",
        method: "put",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "application/json" },
        data: {
          id: Aexp?._id,
          BText1: BText1,
          BHeader: BHeader,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose1();
          getAddBecomes();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  useEffect(() => {
    getAddBecomes();
  }, []);
  console.log(AddBecomee);
  return (
    <>

      <div className="add-gr">
        <div className="container">
          <div className="ad-b mt-4 mb-4">
            {AddBecomee.length !== 1 ? (
              <></>
            ) : (
              <>
                <button className="btn btn-success" onClick={handleShow}>
                  Add Content For Become A Tutor
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra mt-5">
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Header</th>
                <th>Text</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {AddBecomee?.map((item, i) => {
                return (
                  <tr>
                    <td>{++i}</td>
                    <td>{item?.BHeader}</td>

                    <td style={{ paddingTop: "20px" }}>{item?.BText1}</td>

                    <td>
                      <div className="ad-b ">
                        <span>
                          <i
                            class="fas fa-edit"
                            style={{
                              fontSize: "20px",
                              color: "green",
                              paddingTop: "20px",
                            }}
                            onClick={() => handleShow1(item)}
                          // title="edit"
                          ></i>
                        </span>
                      </div>
                    </td>
                    <td>
                      <i
                        class="fa fa-trash"
                        aria-hidden="true"
                        style={{
                          color: "red",
                          fontSize: "20px",
                          paddingTop: "20px",
                        }}
                        onClick={() => handleShow2(item?._id)}
                      // title="delete"
                      ></i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>
            {" "}
            Add Content For Become A Tutor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="floatingInput" label="Header">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setBHeader(e.target.value)}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingTextarea2" label="Enter Text">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              className="rtyui_0"
              onChange={(e) => setBText1(e.target.value)}
            />
          </FloatingLabel>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#00a695", border: "none" }}
            onClick={AddBecome}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={deleteBecome}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Update About us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="floatingInput" label="Header">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setBHeader(e.target.value)}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingTextarea2" label="Enter Text">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              className="rtyui_0"
              onChange={(e) => setBText1(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button variant="primary" onClick={editBecome}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Adminbecomeatutorcontent;
