import React, { useState, useEffect } from "react";
import { Card, Button, Carousel, Modal, Form } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

function Adddonate() {
  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const [data, setdata] = useState([]);
  const [donoteamount, setDonoteAmount] = useState(null);
  const [paymentmethod, setPaymentMethod] = useState(null);

  const [data1, setdata1] = useState([]);
  const [data2, setdata2] = useState([]);
  useEffect(() => {
    getevent();
    getspecialevent();
    getslider();
  }, []);

  const getevent = async () => {
    let res = await axios.get("https://doorstepedu.info/api/getevent");
    if ((res.status = 200)) {
      console.log(res.data);
      setdata(res.data.event);
    }
  };
  const getspecialevent = async () => {
    try {
      axios
        .get(`https://doorstepedu.info/api/getspecialevent`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata1(response.data.specialevent);
            return;
          } else {
            //alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");

      // setdatacondition(true);

      return error;
    }
  };

  const donotebooking = async () => {
    if (paymentmethod == null && donoteamount == null) {
      alert("kindly fill all field");
      return;
    }

    try {
      const config = {
        url: "/adddonotebooking",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "content-type": "application/json" },
        data: {
          userId: admin._id,

          amount: donoteamount,

          donotetime: moment().format("hh:mm A"),

          donotedate: moment().format("DD/MM/YYYY"),
          paymentmethod: paymentmethod,
        },
        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("donation successfully");
        hideModal();
        // window.location.assign('/admin/slider');
      }
    } catch (error) {
      console.log(error);
      alert("donotion failed");
    }
  };

  const setPaymentMode = (value) => {
    setPaymentMethod(value);
  };

  const setDonote = (value) => {
    setDonoteAmount(value);
  };

  const [isOpen, setIsOpen] = useState(false);
  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  const getslider = () => {
    try {
      axios
        .get(`https://doorstepedu.info/api/getslider`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata2(response.data.slider);
            return;
          } else {
            //alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };
  return (
    <div className="container mt-5">
      <div className="row">
        <div
          className="col-md-12"
          style={{ alignContent: "center", textAlign: "center" }}
        >
          <h1>Donation </h1>
          <h2 className="mb-5">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad
            minim veniam.
          </h2>

          <Modal
            show={isOpen}
            onHide={hideModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Donation
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <h4>Amount</h4>
                <input
                  type="Text"
                  onChange={(e) => setDonote(e.target.value)}
                  className="form-control"
                ></input>
                <h4 className="pt-3">Payment Method</h4>
                <Form.Control
                  as="select"
                  onChange={(e) => setPaymentMode(e.target.value)}
                >
                  <option>Select payment Method</option>
                  <option value="COD">COD </option>
                  <option value="online">Online Payment</option>
                </Form.Control>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={hideModal}>Close</Button>
              <Button onClick={donotebooking}>Confirm</Button>
            </Modal.Footer>
          </Modal>

          <Button
            variant="primary"
            onClick={showModal}
            className="bnt-border sc-hiCivh sc-eJwXpk iUXnyj jMA-dWh p-3"
            style={{
              fontSize: "20px",
              fontWeight: "700",
              background: "Navyblue",
            }}
          >
            Add Donation
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Adddonate;
