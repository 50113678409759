import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const Faculty = () => {
  const [viewTutor, setviewTutor] = useState("");
  const navigate = useNavigate();
  const handlenavigate = () => {
    console.log("hjhjhj", viewTutor);
    navigate("/tutor", { state: viewTutor });
  };
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [show2, setShow2] = useState(false);
  //integrating get  method for faculty page
  const [Addfaculty, setAddfaculty] = useState([]);
  const [nochangedata, setnochangedata] = useState([])
  const getAddfaculty = async () => {
    try {
      let res = await axios.get("https://doorstepedu.info/api/user/getfaculty");
      if (res.status === 200) {
        setAddfaculty(res.data.getfaculty);
        setnochangedata(res.data.getfaculty);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddfaculty();
  }, []);
  console.log(Addfaculty);
  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setAddfaculty([...filterTable]);
    } else {
      setSearch(e.target.value);
      setAddfaculty([...nochangedata]);
    }
  };

  return (
    <>

      <div className="">
        <div
          class="module mid"
          style={{
            height: "80px",
            background: "#d1ccc0",
            overflow: " hidden",
            // background: " #C33764",
            //   background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
            //  url("Header 2.jpg")`,
            backgroundSize: "cover",
          }}
        >
          <h2>Faculty</h2>
          <ul
            class=" container breadcrumbs bc3 pt-3 pb-3"
            role="menubar"
            aria-label="breadcrumbs"
          >
            <li>
              <a href="/">Home</a>{" "}
              <span style={{ color: "#092746" }}>&raquo;</span>
            </li>
            <li style={{ color: "#092746" }}> Faculty</li>
          </ul>
        </div>
      </div>

      <div className="container mt-4">
        <div className="row hjklo_9">
          <div className="col-md-12">
            <h1>Find Your Tutor </h1>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-4">
            <div className="do-sear mt-4">
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                <Form.Control
                  placeholder="Search"
                  aria-label="Username"
                  className="vi_0"
                  aria-describedby="basic-addon1"
                  onChange={handleFilter}
                />
              </InputGroup>

            </div>
          </div>

        </div>
        {/* <div className="col-md-3 tyul_0">
            <ul>
              <h4>SUBJECTS</h4>
              <a href="">
                <li>Biology</li>
              </a>
              <a href="">
                <li>Chemistry</li>
              </a>
              <a href="">
                <li>Physics</li>
              </a>
              <a href="">
                <li>Maths</li>
              </a>
              <a href="">
                <li>Accounts</li>
              </a>
              <a href="">
                <li>Business</li>
              </a>
            </ul>
          </div> */}

        <div className="row">
          {Addfaculty?.map((item) => {
            return (
              <div className="col-md-4">
                <div>
                  <Card style={{ width: '21.5rem', height: "415px" }} className="ms-4">
                    <Card.Img variant="top" src={`https://doorstepedu.info/Faculty/${item?.FImg}`} style={{ height: "200px" }} />
                    <Card.Body>

                      <Card.Text className="card_text_p">
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                          }}
                        >
                          Name :
                        </span>{" "}
                        {item?.FName}
                        <br />
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                          }}
                        >
                          Qualification:
                        </span>
                        {item?.FQualification}
                        <br />
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                          }}
                        >
                          Expreience:
                        </span>{" "}
                        {item?.FExprience}
                        <br />
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "15px",
                          }}
                        >
                          Specialization:
                        </span>
                        {item?.FSpecialization}
                        <br />
                      </Card.Text>
                      <div id="container">
                        <button
                          className="btn4"
                          onClick={() => {
                            navigate("/tutor", { state: item });
                          }}
                        ></button>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Faculty;
