import axios from "axios";
import { Card } from "react-bootstrap";

import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";

function Uploadmcqquestion() {
  const [excel, setexcel] = useState();
  const [image, setimage] = useState();
  const [data1, setdata1] = useState([]);
  const [data, setdata] = useState([]);
  const token = sessionStorage.getItem("token");
  const apiURL = process.env.REACT_APP_API_URL;
  const imageURL = process.env.REACT_APP_IMAGE_API_URL;
  const user = JSON.parse(localStorage.getItem("admin"));
  const mcqId = JSON.parse(localStorage.getItem("mcqId"));
  const formdata = new FormData();

  if (excel && data1.length === 0) {
    readFile();
  }

  function readFile() {
    var name = excel.name;
    const reader = new FileReader();
    reader.onload = (evt) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      /* Update state */
      console.log("Data>>>" + data); // shows that excel data is read
      console.log(convertToJson(data)); // shows data in json format
      setdata1(JSON.parse(convertToJson(data)));
    };
    reader.readAsBinaryString(excel);
  }

  function convertToJson(csv) {
    var lines = csv.split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }

      result.push(obj);
    }

    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
  }

  const postorders = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "/addchoice",
        method: "post",
        baseURL: `https://doorstepedu.info/api/`,
        headers: {
          "content-type": "application/json",
          Authorization: "Bearer " + token,
        },
        data: {
          choice: data1.map((datas) => ({
            teacherid: user.id,
            mcqId: mcqId,
            questiontext: datas.questiontext,
            option1: datas.option1,
            option2: datas.option2,
            option3: datas.option3,
            option4: datas.option4,
            answer: datas.answer,
            subject: datas.subject,
          })),
        },

        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("Question Added Successfully");
        // window.location.assign("/pharmacy/product");
        // localStorage.setItem("signupdata",JSON.stringify(res.data.user));
        // localStorage.setItem("token",res.data.token);
        // window.location.assign("/verify-otp");
        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log(image);

  useEffect(() => {
    getchoice();
  }, []);

  const getchoice = () => {
    try {
      axios
        .get(`https://doorstepedu.info/api/getchoice/`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata(response.data.choice);
            return;
          } else {
            //alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };

  const postdeleteimage = async (data) => {
    try {
      const config = {
        url: "/deletechoice/" + data._id,
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "content-type": "multipart/form-data" },

        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("question deleted successfully");
        window.location.assign("/admin/mcq");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container pb-5">
      <Card
        className="mt-5"
        style={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <h5 className="text-center pt-5">Add Question file</h5>
        <Card.Body>
          <div>
            {/* <button className="btn btn-outline-info mr-2">
              <a
                style={{ textDecoration: "none" }}
                href={imageURL + "/excelfile/productexcel.xlsx"}
              >
                Download Excel
              </a>
            </button> */}
            <input
              accept=".xlsx,.xls"
              style={{ display: "none" }}
              id="icon-button-file"
              type="file"
              onChange={(e) => setexcel(e.target.files[0])}
            />
            <label
              className="btn btn-outline-info mt-2"
              htmlFor="icon-button-file"
            >
              {" "}
              Import Excel
            </label>
            <button
              className="btn btn-outline-success ml-2 mr-2"
              onClick={postorders}
            >
              Upload
            </button>
          </div>
        </Card.Body>
      </Card>
      <div
        id="appCapsule"
        className="extra-header-active full-height"
        style={{ padding: "0px", marginTop: "53px", width: "100%" }}
      >
        <div
          className="section mb-2 mt-1"
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingTop: "20px",
          }}
        >
          {/* <div
            className="tab-pane fade show active pb-5"
            id="overview"
            role="tabpanel"
          >
            <div className="transactions">
              {data?.map((choice) => (
                <a
                  href=""
                  className="item"
                  style={{ marginBottom: "5px", marginTop: "5px" }}
                >
                  <div className="detail">
                    <div>
                      <strong>{choice?.questiontext}</strong>
                      <p>
                        {choice?.option.map((option) => (
                          <div className="p1">{option}</div>
                        ))}
                      </p>
                      <p>{choice?.answer}</p>
                      <p>{choice?.subject}</p>
                      <button
                        className="btn btn-outline-danger mt-2"
                        onClick={() => postdeleteimage(choice)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Uploadmcqquestion;
