import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {Search,CSVExport} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { dateFilter } from "react-bootstrap-table2-filter";
import DatePicker from "react-multi-date-picker";
import exportFromJSON from "export-from-json";

function Adminclass() {
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const [data2, setdata2] = useState([]);
  const [data1, setdata1] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;

  const [endDate, setendDate] = useState(new Date());
  const [startDate, setstartDate] = useState(new Date());

  useEffect(() => {
    getusers();
  }, []);

  const getusers = () => {
    try {
      axios
        .get(`https://doorstepedu.info/api/admin/getcreateclass1`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata2(response.data.success);
            // setdata1(response.data.assignment);
            return;
          } else {
            //alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };

  const postdeleteimage = async (data) => {
    try {
      const config = {
        url: "/admin/deletecreateclass/" + data._id,
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        // headers: { "content-type": "multipart/form-data" },

        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("Class deleted successfully");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      dataField: "teacherid?.name",
      text: "Teacher",
    },
    {
      dataField: "studentid?.name",
      text: "Student Name",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p
             style={{fontSize:"16px",marginTop:'-0px'}}
            >
            {row.studentid  ? row.studentid?.name : "Whole Class"}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "date",
      text: "Date",
    },
    {
      dataField: "type",
      text: "Type",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p style={{fontSize:"16px",marginTop:'-0px'}}>
              {row.type === "standard"
                ? "Whole Class"
                : "Individual Student"}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "subject",
      text: "Subject",
    },
    {
      dataField: "standard",
      text: "Standard",
    },

    {
      dataField: "time",
      text: " Start Time",
    },
    {
      dataField: "time2",
      text: " End Time",
    },
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn btn-danger"
              onClick={() => postdeleteimage(row)}
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];
  const data = [];
  if (data2?.length !== 0) {
    for (let i = 0; i < data2.length; i++) {
      data.push({
        TeacherName: data2[i]?.teacherid?.name,
        StudentName: data2[i]?.studentid  ? data2[i]?.studentid?.name : "Whole Class",
        Date: data2[i]?.date,
        Type: data2[i]?.type === "standard"
        ? "Whole Class"
        : "Individual Student",
        Subject:data2[i].subject,
        Class:data2[i].standard,
        StartTime:
          data2[i]?.time,
          EndTime: data2[i]?.time2,
     
      });
    }
  }
  const fileName = "Class";
  const exportType = "xls";

  const ExportToExcel = () => {
    exportFromJSON({ data, fileName, exportType });
  };
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total px-4">
      Showing {from} to {to} of {size} Results
    </span>
  );
  function getDateObject(datevalue) {
    //  return new Date(datevalue);
    let d = new Date(datevalue);
    //let d=moment(datevalue).format('DD/MM/YYYY');
    let v = d.getDate() + "/" + d.getMonth() + "/" + d.getFullYear();
    return v;
  }

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data2.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  // data.filter((data) => {
  //   console.log(new Date(data.date.split("-").reverse().join("-")));
  //   console.log(Date.parse(data.date));
  // });
  // const search = () => {
  //   const filteredData = data1.filter(
  //     (data) =>
  //       new Date(data.date.split("-").reverse().join("-")) >=
  //         new Date(startDate.split("-").reverse().join("-")) &&
  //       new Date(data.date.split("-").reverse().join("-")) <=
  //         new Date(endDate.split("-").reverse().join("-"))
  //   );
  //   setdata(filteredData);
  // };

  return (
    <div style={{ background: "#fff" }}>
      <h2 style={{ color: "#01a695", textAlign: "center" }}> Class List </h2>
      <div className="row pb-3 px-3">
        <div className="col-md-2">
          {/* <h7>Date From</h7> */}
          {/* <DatePicker
            style={{ height: "35px", width: "140px", marginRight: "-5px" }}
            placeholder="DD-MM-YYYY"
            format="DD-MM-YYYY"
            type="input-icon"
            onChange={(date) => setstartDate(date.format("DD-MM-YYYY"))}
          />
        </div>
        <div className="col-md-2">
          <h7>Date To</h7>
          <DatePicker
            style={{ height: "35px", width: "140px", marginRight: "-5px" }}
            placeholder="DD-MM-YYYY"
            format="DD-MM-YYYY"
            type="input-icon"
            onChange={(date) => setendDate(date.format("DD-MM-YYYY"))}
          /> */}
        </div>
        {/* <div className="col-md-2">
          <button
            className="btn btn-primary"
            style={{ marginTop: "20px" }}
            onClick={search}
          >
            filter
          </button>
        </div> */}
      </div>
      <ToolkitProvider
        keyField="id"
        data={data2}
        columns={columns}
        search
        exportCSV={{
          fileName: "class.csv",
          blobType: "text/csv;charset=ansi",
        }}
      >
        {(props) => (
          <div>
            <span className="px-3 mr-auto ">
              <ExportCSVButton
                className="btn-outline-success"
                onClick={ExportToExcel}
              >
                Export to Excel
              </ExportCSVButton>
            </span>
            <span className="pl-4 mr-5 mt-5">
              <SearchBar className="form-control " {...props.searchProps} />
            </span>
            <span className="pl-4 mr-5 mt-5" style={{marginLeft:"20px"}}>
            <ClearSearchButton
              className="btn-outline-info"
              {...props.searchProps}
            />
</span>

            <hr />
            <div className="table-responsive" style={{margin:'30px'}}>
              <BootstrapTable
                keyField="id"
                striped
                hover
                condensed
                columns={columns}
                data={data2}
                pagination={paginationFactory(options)}
                {...props.baseProps}
              />
            </div>
          </div>
        )}
      </ToolkitProvider>

      {/* <Table striped bordered hover size="sm">
  <thead>
    <tr>
      <th>S.no</th>
      <th>assignment Name</th>
      <th>Amount</th>
      <th>User name</th>
      <th>User Email</th>
      
      <th >Booked Date</th>
      <th >assignment Date</th>
      <th>Payment Method</th>
      
    </tr>
  </thead>
  <tbody>
    {data?.map((e)=>(
       <tr>
       <td>1</td>
       <td>{e.assignment[0].assignmenttitle}</td>
       <td>{e.assignment[0].assignmentamount}</td>
       <td>{e.user[0]?.name}</td>
    <td>{e.user[0].email}</td>
    <td>{e.assignmentdate}</td>
    <td>{e.bookedtime}</td>
    <td>{e.paymentmethod}</td>
       
     </tr>
    ))}
   
    

  <a href="https://wa.me/country_code_and_your_phone_number" className="whatsapp_float" target="_blank"> <i className="fa fa-whatsapp whatsapp-icon"></i></a>

    
   
  </tbody>
</Table> */}
    </div>
  );
}

export default Adminclass;
