import React, { useState } from "react";
import { Card } from "react-bootstrap";
import axios from "axios";

function Addspecialevent() {
  const [image, setimage] = useState();
  const [specialeventtitle, setspecialeventtitle] = useState(" ");
  const [specialeventdate, setspecialeventdate] = useState(" ");

  const [amount, setamount] = useState();
  const [specialeventcontent, setspecialeventcontent] = useState(" ");
  const [specialeventtime, setspecialeventtime] = useState(" ");

  const formdata = new FormData();

  const postimage = async () => {
    formdata.append("specialeventimage", image);
    formdata.append("specialeventtitle", specialeventtitle);

    formdata.append("specialeventdate", specialeventdate);
    formdata.append("specialeventtime", specialeventtime);
    formdata.append("specialeventamount", amount);
    formdata.append("specialeventcontent", specialeventcontent);

    try {
      const config = {
        url: "/addspecialevent",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "specialevent-type": "multipart/form-data" },
        data: formdata,
        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("specialevent add successfully");
        window.location.assign("/admin/specialevent");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="container mt-5">
      <Card
        className="text-center"
        style={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <h5 className="pt-5">Add specialevent</h5>
        <Card.Body>
          <div>
            <img
              src={image != undefined ? URL.createObjectURL(image) : null}
              style={{ height: "300px", width: "350px" }}
            />
          </div>
          <div>
            <input
              type="file"
              style={{ border: "none" }}
              onChange={(e) => setimage(e.target.files[0])}
            />
          </div>
          <div>
            <label> specialevent Title </label>
            <input
              type="text"
              onChange={(e) => setspecialeventtitle(e.target.value)}
            ></input>
          </div>
          <div>
            <label> specialevent Date </label>
            <input
              type="date"
              onChange={(e) => setspecialeventdate(e.target.value)}
            ></input>
          </div>
          <div>
            <label> specialevent Time </label>
            <input
              type="time"
              onChange={(e) => setspecialeventtime(e.target.value)}
            ></input>
          </div>
          <div>
            <label> Amount </label>
            <input type="number" onChange={(e) => setamount(e.target.value)} />
          </div>
          <div>
            <label> Content </label>
            <textarea
              row="2"
              onChange={(e) => setspecialeventcontent(e.target.value)}
            ></textarea>
          </div>
          <button className="btn btn-primary" onClick={postimage}>
            Add specialevent
          </button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Addspecialevent;
