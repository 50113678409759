import React, { useState } from "react";
import { Card } from "react-bootstrap";
import axios from "axios";

function Addcontent() {
  const [image, setimage] = useState();
  const [content, setcontent] = useState(" ");
  const formdata = new FormData();

  const postimage = async () => {
    formdata.append("contentimage", image);
    formdata.append("content", content);

    try {
      const config = {
        url: "/addcontent",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert(" add successfully");
        window.location.assign("/admin/content");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="container mt-5">
      <Card
        className="text-center"
        style={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <h5 className="pt-5">Add Study Material</h5>
        <Card.Body>
          <div>
            <img
              src={image != undefined ? URL.createObjectURL(image) : null}
              style={{ height: "300px", width: "350px" }}
            />
          </div>
          <div>
            <input
              type="file"
              style={{ border: "none" }}
              onChange={(e) => setimage(e.target.files[0])}
            />
          </div>
          <div>
            <label>Study Material</label>
            <textarea
              row="5"
              onChange={(e) => setcontent(e.target.value)}
            ></textarea>
          </div>
          <button className="btn btn-primary" onClick={postimage}>
            Add Study Material
          </button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Addcontent;
