import React, { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
const Traningandplacement = () => {
  //post method for enquiry
  const [TraingType, setTraingType] = useState("");
  const [TrainingName, setTrainingName] = useState("");
  const [TrainingPhone, setTrainingPhone] = useState("");
  const [TrainingEmail, setTrainingEmail] = useState("");
  const [TrainingMsg, setTrainingMsg] = useState("");

  const Trainingenquiry = async () => {
    try {
      const config = {
        url: "/user/Trainingenquiry",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "application/json" },
        data: {
          TraingType: TraingType,
          TrainingName: TrainingName,
          TrainingPhone: TrainingPhone,
          TrainingEmail: TrainingEmail,
          TrainingMsg: TrainingMsg,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method for content
  const [addtp, setAddtp] = useState({});
  const [show88, setShow88] = useState(false);

  const handleClose88 = () => setShow88(false);
  const handleShow88 = () => setShow88(true);
  const [show99, setShow99] = useState(false);

  const handleClose99 = () => setShow99(false);
  const handleShow99 = () => setShow99(true);
  const [show34, setShow34] = useState(false);

  const handleClose34 = () => setShow34(false);
  const handleShow34 = () => setShow34(true);
  const [AddTandP, setAddTandP] = useState([]);
  const getTandP = async () => {
    try {
      let res = await axios.get("https://doorstepedu.info/api/user/getTandP");
      if (res.status === 200) {
        setAddTandP(res.data.getTandP);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //integrating get  method for certificates
  const [AddGalery, setAddGalery] = useState([]);
  const getAddCertificate = async () => {
    try {
      let res = await axios.get(
        "https://doorstepedu.info/api/user/getCertificate"
      );
      if (res.status === 200) {
        setAddGalery(res.data.getCertificate);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    getTandP();
    getAddCertificate();
  }, []);
  console.log(AddTandP);
  console.log(AddGalery);
  return (
    <>
      <div className="">
        <div
          class="module mid"
          style={{
            height: "80px",
            background: "#d1ccc0",
            overflow: " hidden",
            // background: " #C33764",
            //   background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
            //  url("Header 2.jpg")`,
            backgroundSize: "cover",
          }}
        >
          <h2 style={{ right: "-10%" }}>Teachers Training and Placements</h2>
          <ul
            class=" container breadcrumbs bc3 pt-3 pb-3"
            role="menubar"
            aria-label="breadcrumbs"
          >
            <li>
              <a href="/">Home</a>{" "}
              <span style={{ color: "#092746" }}>&raquo;</span>
            </li>
            <li style={{ color: "#092746" }}>
              {" "}
              Teachers Training and Placements
            </li>
          </ul>
        </div>
      </div>
      <div className="container mt-4">
        <div className="row hjklo_9">
          <div className="col-md-12">
            <h1></h1>
          </div>
        </div>
      </div>

      <div className="container mt-4">
        <div className="row hjklo_9">
          <div className="col-md-9">
            <h1>
              Play <span style={{ color: "#00a695" }}> School</span>
            </h1>
            <p className="card_text_p" style={{ marginLeft: "8px" }}>Welcome to Kids Center</p>
            <h4>
              Fill your child's childhood{" "}
              <span style={{ color: "#00a695" }}>
                with the joy of learning!
              </span>
            </h4>
          </div>
          <div className="col-md-3">
            <div id="container">
              <button className="btn9" onClick={handleShow}></button>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container">
        <div className="row">
          <div className="col-md-3">
            <img src="./icon1.jpg" alt="" style={{ width: "100%" }} />
            <p className= "card_text_p"
              className="text-center"
              style={{ fontSize: "20px", fontFamily: "initial" }}
            >
              To Think Creatively <br /> and Create
            </p>
          </div>
          <div className="col-md-3">
            <img src="./icon2.jpg" alt="" style={{ width: "100%" }} />
            <p className= "card_text_p"
              className="text-center"
              style={{ fontSize: "20px", fontFamily: "initial" }}
            >
              To Feel Fne and to <br /> Understand Emotions
            </p>
          </div>
          <div className="col-md-3">
            <img src="./icon3.jpg" alt="" style={{ width: "100%" }} />
            <p className= "card_text_p"
              className="text-center"
              style={{ fontSize: "20px", fontFamily: "initial" }}
            >
              To be Independent <br /> and Active
            </p>
          </div>
          <div className="col-md-3">
            <img src="./icon4.jpg" alt="" style={{ width: "100%" }} />
            <p className= "card_text_p"
              className="text-center"
              style={{ fontSize: "20px", fontFamily: "initial" }}
            >
              To Apply <br /> Knowledge in Life
            </p>
          </div>
        </div>
      </div> */}
      <div className="container mt-3">
        {AddTandP?.map((item) => {
          return (
            <div className="row">
              <div className="col-md-6 hjklo_9 text-center">
                <h1>
                  Education from birth <br />{" "}
                  <span style={{ color: "#00a695" }}>Begins with us!...</span>
                </h1>
                <p className="card_text_p" style={{ fontSize: "20px", textAlign: "justify" }}>
                  {item?.PSText1.slice(0, 500) < item?.PSText1.slice(0, 501) ? (<>{item?.PSText1.slice(0, 500)}
                    <br /> <span
                      className="viewmore text-dark"
                      onClick={() => {
                        handleShow88();
                        setAddtp(item);
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      View More
                    </span> </>) : (<>{item?.PSText1}</>)}

                </p>
              </div>
              <div className="col-md-6">
                <img
                  src={`https://doorstepedu.info/TandP/${item?.PSImage1}`}
                  alt=""
                  style={{ width: "100%", height: "250px" }}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 hjklo_9">
            <h1>
              {" "}
              School<span style={{ color: "#C4E538" }}> </span>
            </h1>
          </div>
        </div>
      </div>
      <div className="container">
        {AddTandP?.map((item) => {
          return (
            <div className="row">
              <div className="col-md-6">
                <img
                  src={`https://doorstepedu.info/TandP/${item?.HTImage2}`}
                  alt=""
                  style={{ width: "100%", height: "250px" }}
                />
              </div>
              <div className="col-md-6">
                <p className="card_text_p" style={{ textAlign: "justify" }}>
                  {item?.HTText2.slice(0, 500) < item?.HTText2.slice(0, 501) ? (<>{item?.HTText2.slice(0, 500)}... <br />
                    <br />
                    <span
                      className="viewmore text-dark"
                      onClick={() => {
                        handleShow99();
                        setAddtp(item);
                      }}
                      style={{
                        cursor: "pointer",

                      }}
                    >
                      View More
                    </span>
                  </>) : (<>{item?.HTText2}</>)}{" "}

                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 hjklo_9">
            <h1>
              {" "}
              Colleges<span style={{ color: "#C4E538" }}> </span>
            </h1>
          </div>
        </div>
      </div>
      <div className="container">
        {AddTandP?.map((item) => {
          return (
            <div className="row">
              <div className="col-md-6 hjklo_9 ">
                <h1 className="text-center mt-3">
                  Take First Step to <br />{" "}
                  <span style={{ color: "#00a695" }}>Success! </span>
                </h1>
                <p className="card_text_p" style={{ textAlign: "justify" }}>
                  {item?.CText3.slice(0, 500) < item?.CText3.slice(0, 501) ? (<>{item?.CText3.slice(0, 500)}br...
                    <span
                      className="viewmore text-dark"
                      onClick={() => {
                        handleShow34();
                        setAddtp(item);
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      View More
                    </span>
                  </>) : (<>{item?.CText3.slice(0, 500)}</>)}{" "}

                </p>
              </div>
              <div className="col-md-6">
                <img
                  src={`https://doorstepedu.info/TandP/${item?.CImage3}`}
                  alt=""
                  style={{ width: "100%", height: "250px" }}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 hjklo_9">
            <h1>
              {" "}
              Certificates<span style={{ color: "#C4E538" }}> </span>
            </h1>
          </div>
        </div>
      </div>
      <div className="container">
        {AddGalery?.map((item) => {
          return (
            <div className="row">
              <div className="col-md-12">
                <img
                  src={`https://doorstepedu.info/Certificates/${item?.CeImage}`}
                  alt=""
                  style={{ width: "100%", height: "400px" }}
                />
              </div>
            </div>
          );
        })}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>Enquire Now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <FloatingLabel controlId="floatingSelectGrid" label="Select T&P Type">
            <Form.Select
              aria-label="Floating label select example"
              onChange={(e) => {
                setTraingType(e.target.value);
              }}
            >
              <option value="">---- select----</option>
              <option value="Play School">Play School </option>
              <option value="School">School</option>
              <option value="College">College</option>
            </Form.Select>
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Enter Your Name">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setTrainingName(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Mobile No">
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setTrainingPhone(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Email">
            <Form.Control
              type="email"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setTrainingEmail(e.target.value)}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingTextarea2" label="Message">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              className="rtyui_0"
              onChange={(e) => setTrainingMsg(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            onClick={Trainingenquiry}
            style={{ backgroundColor: "#00a695", border: "none" }}
          >
            Send Request
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show88} onHide={handleClose88} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Play School</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            {" "}
            <img
              src={`https://doorstepedu.info/TandP/${addtp?.PSImage1}`}
              alt=""
              style={{ width: "100%", height: "350px" }}
            />
          </span>{" "}
          <p className="card_text_p">{addtp?.PSText1}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose88}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show99} onHide={handleClose99} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Play School</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            {" "}
            <img
              src={`https://doorstepedu.info/TandP/${addtp?.HTImage2}`}
              alt=""
              style={{ width: "100%", height: "350px" }}
            />
          </span>{" "}
          <p className="card_text_p">{addtp?.HTText2}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose99}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show34} onHide={handleClose34} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Colleges</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            {" "}
            <img
              src={`https://doorstepedu.info/TandP/${addtp?.CImage3}`}
              alt=""
              style={{ width: "100%" }}
            />
          </span>{" "}
          <p className="card_text_p">{addtp?.CText3}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose34}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Traningandplacement;
