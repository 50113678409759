import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import InputGroup from 'react-bootstrap/InputGroup';

const AdminBecomeatutor = () => {
  // integrating get method for enquiry form
  const [getBcTutor, setgetBcTutor] = useState([]);
  const [nochangedata, setnochangedata] = useState([])

  const getBcTutors = async () => {
    let res = await axios.get("https://doorstepedu.info/api/user/getBcTutor");
    if (res.status === 200) {
      setgetBcTutor(res.data.getBcTutornow);
      setnochangedata(res.data.getBcTutornow)
    }
  };

  //delete method
  const [show, setShow] = useState(false);
  const [Data, setData] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setShow(true);
    setData(data);
  };
  const deleteBcTutor = async () => {
    try {
      const config = {
        url: "/user/deleteBcTutor/" + Data,
        method: "delete",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Deleted");
          getBcTutors();
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };
  const [search, setSearch] = useState("");
  const handleFilter = (e) => {
    if (e.target.value != "") {
      setSearch(e.target.value);
      const filterTable = nochangedata.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setgetBcTutor([...filterTable]);
    } else {
      setSearch(e.target.value);
      setgetBcTutor([...nochangedata]);
    }
  };
  useEffect(() => {
    getBcTutors();
  }, []);
  console.log(getBcTutor);
  return (
    <>
      <div className="container  mt-5">
        <div className="row mb-3">
          <div className="col-md-4">
            <div className="do-sear mt-4">
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1"><FontAwesomeIcon icon={faMagnifyingGlass} /></InputGroup.Text>
                <Form.Control
                  placeholder="Search"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  onChange={handleFilter}
                />
              </InputGroup>
            </div>
          </div>

        </div>
        <div className="row">
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>

                <th>Name</th>
                <th>Email</th>
                <th>Contact No.</th>
                <th>Message</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {getBcTutor?.map((item, i) => {
                return (
                  <tr>
                    <td>{++i}</td>
                    <td>{item?.EName}</td>
                    <td>{item?.EEmail}</td>
                    <td>{item?.EPhone}</td>
                    <td>{item?.Emsg}</td>
                    <td>
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{ color: "red" }}
                        onClick={() => {
                          handleShow(item?._id);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button onClick={deleteBcTutor}>Delete </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminBecomeatutor;
