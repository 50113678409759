import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import axios from "axios";

const Tution = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  //post method for online method

  const [OName, setOName] = useState("");
  const [OGrade, setOGrade] = useState("");
  const [OBoard, setOBoard] = useState("");
  const [OSubject, setOSubject] = useState("");
  const [OPhone, setOPhone] = useState("");
  const [OEmail, setOEmail] = useState("");
  const [OMsg, setOMsg] = useState("");

  const onlineenquiry = async () => {
    try {
      const config = {
        url: "/user/onlinetutionn",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "application/json" },
        data: {
          OName: OName,
          OGrade: OGrade,
          OBoard: OBoard,
          OSubject: OSubject,
          OPhone: OPhone,
          OEmail: OEmail,
          OMsg: OMsg,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //post method for Home and Institute  method
  const [EClassType, setEClassType] = useState("");
  const [ETutionName, setETutionName] = useState("");
  const [ETutionGrade, setETutionGrade] = useState("");
  const [ETutionBoard, setETutionBoard] = useState("");
  const [ETutionSubject, setETutionSubject] = useState("");
  const [ETutionPhone, setETutionPhone] = useState("");
  const [ETutionEmail, setETutionEmail] = useState("");
  const [EtutionAddress, setEtutionAddress] = useState("");
  const [ETutionMsg, setETutionMsg] = useState("");

  const homeenquiry = async () => {
    try {
      const config = {
        url: "/user/hometution",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "application/json" },
        data: {
          EClassType: EClassType,
          ETutionName: ETutionName,
          ETutionGrade: ETutionGrade,
          ETutionBoard: ETutionBoard,
          ETutionSubject: ETutionSubject,
          ETutionPhone: ETutionPhone,
          ETutionEmail: ETutionEmail,
          EtutionAddress: EtutionAddress,
          ETutionMsg: ETutionMsg,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Enquiry Sent SuccessFully We Will Talk To You Later...")
          // window.location.reload()
          setEClassType("");
          setETutionName("");
          setETutionGrade("");
          setETutionBoard("");
          setETutionSubject("");
          setETutionPhone("");
          setETutionEmail("");
          setEtutionAddress("");
          setETutionMsg("");




        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  // get method for online /home/institute tutions
  const [addtution, setaddTution] = useState({});
  const [show88, setShow88] = useState(false);

  const handleClose88 = () => setShow88(false);
  const handleShow88 = () => setShow88(true);
  const [show99, setShow99] = useState(false);

  const handleClose99 = () => setShow99(false);
  const handleShow99 = () => setShow99(true);
  const [show34, setShow34] = useState(false);

  const handleClose34 = () => setShow34(false);
  const handleShow34 = () => setShow34(true);
  const [AddTutionContent, setAddTutionContent] = useState([]);
  const getTutionContent = async () => {
    try {
      let res = await axios.get(
        "https://doorstepedu.info/api/user/getTutionContent"
      );
      if (res.status === 200) {
        setAddTutionContent(res.data.getTutionContent);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTutionContent();
  }, []);
  console.log(AddTutionContent);
  return (
    <>
      <div className="">
        <div
          class="module mid"
          style={{
            height: "80px",
            background: "#d1ccc0",
            overflow: " hidden",
            // background: " #C33764",
            //   background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
            //  url("Header 2.jpg")`,
            backgroundSize: "cover",
          }}
        >
          <h2>Tuition</h2>
          <ul
            class=" container breadcrumbs bc3 pt-3 pb-3"
            role="menubar"
            aria-label="breadcrumbs"
          >
            <li>
              <a href="/">Home</a>{" "}
              <span style={{ color: "#092746" }}>&raquo;</span>
            </li>
            <li>Tuition</li>
          </ul>
        </div>
      </div>
      <div className="container mt-2 " id="online">
        <div className="row hjklo_9">
          <div className="col-md-12">
            <h1>Online Tuition</h1>
          </div>
        </div>
      </div>
      <div className="container">
        {AddTutionContent?.map((item) => {
          return (
            <div className="row">
              <div className="col-md-8">
                <p
                  style={{
                    textAlign: "justify",
                    fontSize: "20px",
                    fontFamily: "initial",
                    fontWeight: "500",
                  }}
                  className="card_text_p"
                >
                  {item?.OText1.slice(0, 500) < item?.OText1.slice(0, 501) ? (<>{item?.OText1.slice(0, 500)}... <br />
                    <span
                      className="viewmore text-dark"
                      onClick={() => {
                        handleShow88();
                        setaddTution(item);
                      }}
                      style={{
                        cursor: "pointer",

                      }}
                    >
                      View More
                    </span></>) : (<>{item?.OText1}</>)}

                </p>
                <br />
                <img
                  src={`https://doorstepedu.info/TutionContent/${item?.OImage1}`}
                  alt=""
                  style={{ width: "100%", height: "300px" }}
                />
              </div>
              <div className="col-md-4">
                <Card style={{ width: "22rem" }}>
                  <Card.Img
                    variant="top"
                    src="./online.avif"
                    style={{ height: "225px" }}
                  />
                  <Card.Body>
                    <Card.Title>Online Clasess</Card.Title>
                    <Card.Text className="card_text_p">
                      For More Details Click on the Link Below
                    </Card.Text>
                    <button
                      className="btn10 "
                      style={{ margin: "auto" }}
                      onClick={handleShow}
                    >
                      ENQUIRE NOW
                    </button>
                  </Card.Body>
                </Card>
              </div>
            </div>
          );
        })}
      </div>
      <div className="container mt-2 " id="hometution">
        <div className="row hjklo_9">
          <div className="col-md-12">
            <h1>Home Tuition</h1>
          </div>
        </div>
      </div>
      <div className="container">
        {AddTutionContent?.map((item) => {
          return (
            <div className="row">
              <div className="col-md-8">
                <p
                  style={{
                    textAlign: "justify",
                    fontSize: "20px",
                    fontFamily: "initial",
                    fontWeight: "500",
                  }}
                  className="card_text_p"
                >
                  {item?.HText2.slice(0, 500) < item?.HText2.slice(0, 501) ? (<>{item?.HText2.slice(0, 500)}... <br />
                    <span
                      className="viewmore text-dark"
                      onClick={() => {
                        handleShow99();
                        setaddTution(item);
                      }}
                      style={{
                        cursor: "pointer",

                      }}
                    >
                      View More
                    </span></>) : (<>{item?.HText2}</>)}

                </p>
                <br />
                <img
                  src={`https://doorstepedu.info/TutionContent/${item?.HImage2}`}
                  alt=""
                  style={{ width: "100%", height: "300px" }}
                />
                <div className="container mt-2 " id="institute">
                  <div className="row hjklo_9">
                    <div className="col-md-12">
                      <h1>Institute</h1>
                    </div>
                  </div>
                </div>
                <div>
                  <p
                    style={{
                      textAlign: "justify",
                      fontSize: "20px",
                      fontFamily: "initial",
                      fontWeight: "500",
                    }}
                    className="card_text_p"
                  >
                    {item?.IText3.slice(0, 500) < item?.IText3.slice(0, 501) ? (<>{item?.IText3.slice(0, 500)}... <br />
                      <span
                        className="viewmore text-dark"
                        onClick={() => {
                          handleShow34();
                          setaddTution(item);
                        }}
                        style={{
                          cursor: "pointer",

                        }}
                      >
                        View More
                      </span>
                    </>) : (<>{item?.IText3}</>)}

                  </p>
                  <br />
                  <img
                    src={`https://doorstepedu.info/TutionContent/${item?.IImage3}`}
                    alt=""
                    style={{ width: "100%", height: "300px" }}
                  />
                </div>
              </div>

              <div className="col-md-4">
                <Form
                  style={{
                    // border: "1px solid rgb(0,0,0,0.2)",
                    padding: "20px",
                    boxShadow: "0 8px 32px 0 rgba(19.19.20,0.37)",
                    background: "rgba(255,255,255,0.24)",
                    backdropFilter: "blur (4px)",
                  }}
                >
                  <h1>Enquire Now </h1>
                  <FloatingLabel
                    controlId="floatingSelectGrid"
                    label="Select Class Type"
                  >
                    <Form.Select
                      aria-label="Floating label select example"
                      onChange={(e) => {
                        setEClassType(e.target.value);
                      }}
                    >
                      <option value="">---- select----</option>
                      <option value="Home Tuition">Home Tuition </option>
                      <option value="Institute">Institute</option>
                    </Form.Select>
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Enter Your Name"
                  >
                    <Form.Control
                      type="text"
                      placeholder="name@example.com"
                      className="rtyui_0"
                      value={ETutionName}
                      onChange={(e) => {
                        setETutionName(e.target.value);
                      }}
                    />
                  </FloatingLabel>
                  <FloatingLabel controlId="floatingInput" label="Grade">
                    <Form.Control
                      type="text"
                      placeholder="name@example.com"
                      className="rtyui_0"
                      value={ETutionGrade}
                      onChange={(e) => {
                        setETutionGrade(e.target.value);
                      }}
                    />
                  </FloatingLabel>
                  <FloatingLabel controlId="floatingInput" label="Board">
                    <Form.Control
                      type="text"
                      placeholder="name@example.com"
                      className="rtyui_0"
                      value={ETutionBoard}
                      onChange={(e) => {
                        setETutionBoard(e.target.value);
                      }}
                    />
                  </FloatingLabel>
                  <FloatingLabel controlId="floatingInput" label="Subject">
                    <Form.Control
                      type="text"
                      placeholder="name@example.com"
                      className="rtyui_0"
                      value={ETutionSubject}
                      onChange={(e) => {
                        setETutionSubject(e.target.value);
                      }}
                    />
                  </FloatingLabel>
                  <FloatingLabel controlId="floatingInput" label="Mobile No">
                    <Form.Control
                      type="number"
                      placeholder="name@example.com"
                      className="rtyui_0"
                      value={ETutionPhone}
                      onChange={(e) => {
                        setETutionPhone(e.target.value);
                      }}
                    />
                  </FloatingLabel>
                  <FloatingLabel controlId="floatingInput" label="Email">
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      className="rtyui_0"
                      value={ETutionEmail}
                      onChange={(e) => {
                        setETutionEmail(e.target.value);
                      }}
                    />
                  </FloatingLabel>
                  <FloatingLabel controlId="floatingTextarea2" label="Address">
                    <Form.Control
                      as="textarea"
                      placeholder="Leave a comment here"
                      style={{ height: "100px" }}
                      className="rtyui_0"
                      value={EtutionAddress}
                      onChange={(e) => {
                        setEtutionAddress(e.target.value);
                      }}
                    />
                  </FloatingLabel>
                  <FloatingLabel controlId="floatingTextarea2" label="Message">
                    <Form.Control
                      as="textarea"
                      placeholder="Leave a comment here"
                      style={{ height: "100px" }}
                      className="rtyui_0"
                      value={ETutionMsg}
                      onChange={(e) => {
                        setETutionMsg(e.target.value);
                      }}
                    />
                  </FloatingLabel>
                  <button className="btn10 mt-2" onClick={homeenquiry}>
                    {" "}
                    Send Request
                  </button>
                </Form>
              </div>
            </div>
          );
        })}
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>Online Classes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <FloatingLabel controlId="floatingInput" label="Enter Your Name">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => {
                setOName(e.target.value);
              }}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Enter Your Grade">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => {
                setOGrade(e.target.value);
              }}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Board">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => {
                setOBoard(e.target.value);
              }}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Subject">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => {
                setOSubject(e.target.value);
              }}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Mobile No">
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => {
                setOPhone(e.target.value);
              }}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Email">
            <Form.Control
              type="email"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => {
                setOEmail(e.target.value);
              }}
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingTextarea2" label="Message">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              className="rtyui_0"
              onChange={(e) => {
                setOMsg(e.target.value);
              }}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            onClick={onlineenquiry}
            style={{ backgroundColor: "#00a695", border: "none" }}
          >
            Send Request
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>Home Tution</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <FloatingLabel
            controlId="floatingSelectGrid"
            label="Select Grade"
            style={{ color: "black" }}
          >
            <Form.Select
              aria-label="Floating label select example"
              className="rtyui_0"
            >
              <option value=""></option>
              <option value="2">4 </option>
              <option value="3">5</option>
              <option value="3">6</option>
              <option value="3">7</option>
              <option value="3">8</option>
              <option value="3">9</option>
              <option value="3">10</option>
              <option value="3">11</option>
              <option value="3">12</option>
            </Form.Select>
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Subject">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Mobile No">
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Email">
            <Form.Control
              type="email"
              placeholder="name@example.com"
              className="rtyui_0"
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingTextarea2" label="Address">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              className="rtyui_0"
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingTextarea2" label="Message">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              className="rtyui_0"
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            onClick={handleClose1}
            style={{ backgroundColor: "#00a695", border: "none" }}
          >
            Send Request
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>Institute</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <FloatingLabel controlId="floatingInput" label="Enter Your Name">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
            />
          </FloatingLabel>
          <FloatingLabel
            controlId="floatingSelectGrid"
            label="Select Grade"
            style={{ color: "black" }}
          >
            <Form.Select
              aria-label="Floating label select example"
              className="rtyui_0"
            >
              <option value=""></option>
              <option value="2">4 </option>
              <option value="3">5</option>
              <option value="3">6</option>
              <option value="3">7</option>
              <option value="3">8</option>
              <option value="3">9</option>
              <option value="3">10</option>
              <option value="3">11</option>
              <option value="3">12</option>
            </Form.Select>
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Board">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Subject">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Mobile No">
            <Form.Control
              type="number"
              placeholder="name@example.com"
              className="rtyui_0"
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Email">
            <Form.Control
              type="email"
              placeholder="name@example.com"
              className="rtyui_0"
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingTextarea2" label="Address">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              className="rtyui_0"
            />
          </FloatingLabel>
          <FloatingLabel controlId="floatingTextarea2" label="Message">
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              className="rtyui_0"
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            onClick={handleClose2}
            style={{ backgroundColor: "#00a695", border: "none" }}
          >
            Send Request
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show88} onHide={handleClose88} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Online Tuition</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            {" "}
            <img
              src={`https://doorstepedu.info/TutionContent/${addtution?.OImage1}`}
              alt=""
              style={{ width: "100%", height: "300px" }}
            />
          </span>{" "}
          <p className="card_text_p">{addtution?.OText1}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose88}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show99} onHide={handleClose99} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Home Tuition</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            {" "}
            <img
              src={`https://doorstepedu.info/TutionContent/${addtution?.HImage2}`}
              alt=""
              style={{ width: "100%", height: "300px" }}
            />
          </span>{" "}
          <p className="card_text_p">{addtution?.HText2}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose99}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show34} onHide={handleClose34} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Institute</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            {" "}
            <img
              src={`https://doorstepedu.info/TutionContent/${addtution?.IImage3}`}
              alt=""
              style={{ width: "100%", height: "300px" }}
            />
          </span>{" "}
          <p className="card_text_p">{addtution?.HText2}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose34}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Tution;
