import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { v1 as uuid } from "uuid";

function Adminteacherinfo(props) {
  const [data, setdata] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    getusers();
  }, []);

  const getusers = () => {
    try {
      axios
        .get(`https://doorstepedu.info/api/user/getAllUser`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata(response.data.success);
            return;
          } else {
            //alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };

  const postdeleteimage = async (data) => {
    try {
      const config = {
        url: "/deleteteacherinfo/" + data._id,
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "teacherinfo-type": "multipart/form-data" },

        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("teacherinfo deleted successfully");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };
  async function create(data) {
    try {
      const id = uuid();
      const config = {
        url: "/teacherinforoomid",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "teacherinfo-type": "application/json" },
        data: { id: data._id, roomid: data._id },

        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        window.location.assign(`/room/${data._id}`);
        // console.log(res.data);
        // alert("teacherinfo deleted successfully")
        // window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div style={{ background: "#fff" }}>
      <h2 style={{ color: "#01a695", textAlign: "center" }}>
        {" "}
        teacherinfo{" "}
      </h2>
      <Table responsive hover size="md">
        <thead>
          <tr>
            <th>S. no</th>
            <th>File</th>
            <th> teacherName </th>

            <th> Date of jion </th>
            <th> Subject </th>
            <th> Experience </th>

            <th> class </th>

            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((datas) => (
            <tr>
              <td>1</td>
              <td>
                {" "}
                <img
                  src={
                    "http://bd.parnetslink.com/teacherinfo/" +
                    datas.teacherinfofile
                  }
                  height="100px"
                  width="100px"
                  style={{ borderRadius: "100%" }}
                />{" "}
              </td>
              <td>{datas.teacherinfotitle}</td>
              <td>{datas.teacherinfodate}</td>
              <td>{datas.teacherinfosubject}</td>
              <td> {datas.teacherinfoexperience} </td>
              <td>{datas.teacherinfoclassname}</td>
              <td>
                {" "}
                <button
                  className="btn btn-outline-danger"
                  onClick={() => postdeleteimage(datas)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
          {/* <tr>
                                    <td>DR David</td>
                                    <td><img src="" height /></td>                                                               
                                    <td><div className=""> <button className="btn btn-danger">Cancel</button> </div></td>
                                </tr> */}
        </tbody>
      </Table>
    </div>
  );
}

export default Adminteacherinfo;
