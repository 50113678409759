import axios from "axios";
import React, { useEffect, useState } from "react";

const Gallery = () => {
  //integrating get  method
  const [AddGalery, setAddGalery] = useState([]);
  const getAddGallery = async () => {
    try {
      let res = await axios.get("https://doorstepedu.info/api/user/getgallery");
      if (res.status === 200) {
        setAddGalery(res.data.getgallery);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAddGallery();
  }, []);
  console.log(AddGalery);
  return (
    <>
      <div className="">
        <div
          class="module mid"
          style={{
            height: "80px",
            background: "#d1ccc0",
            overflow: " hidden",
            // background: " #C33764",
            //   background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
            //  url("Header 2.jpg")`,
            backgroundSize: "cover",
          }}
        >
          <h2>Gallery</h2>
          <ul
            class=" container breadcrumbs bc3 pt-3 pb-3"
            role="menubar"
            aria-label="breadcrumbs"
          >
            <li>
              <a href="/">Home</a>{" "}
              <span style={{ color: "#092746" }}>&raquo;</span>
            </li>
            <li style={{ color: "#092746" }}> Gallery</li>
          </ul>
        </div>
      </div>

      <div className="container mt-4">
        <div className="row  hjklo_9 ">
          {AddGalery?.map((item) => {
            return (
              <div className="col-md-3 text-center hhjou_0">
                <img
                  src={`https://doorstepedu.info/Gallery/${item?.GImage}`}
                  alt=""
                  style={{ width: "100%", height: "250px" }}
                />
                <h5 style={{ fontFamily: "initial" }}>{item?.GText}</h5>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Gallery;
