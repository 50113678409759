import React from "react";
import Card from "react-bootstrap/Card";
const Faculti = () => {
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <Card style={{height:"470px", width:"21rem"}}>
            <div className="wrapper">
              <div className="image">
                <img
                  src="./tutor4-740x792.jpg"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="content1">
                  <p>Name: Amandeep Singh</p>
                  <p>Qualification: BCA</p>
                  <p>Expreience: 2 Months</p>
                  <p>Specialization: Physics , Chemistry , Biology</p>
                </div>

                <div className="content">
                  <p>Name: Amandeep Singh</p>
                  <p>Qualification: BCA</p>
                  <p>Expreience: 2 Months</p>
                  <p>Specialization: Physics , Chemistry , Biology</p>
                  <div>
                    <button className="btn10 ">ENQUIRE NOW</button>
                  </div>
                </div>
              </div>
            </div>
            </Card>
           
          </div>
          <div className="col-md-4">
          <Card style={{height:"470px"}}>
            <div className="wrapper">
              <div className="image">
                <img
                  src="./tutor4-740x792.jpg"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="content1">
                  <p>Name: Amandeep Singh</p>
                  <p>Qualification: BCA</p>
                  <p>Expreience: 2 Months</p>
                  <p>Specialization: Physics , Chemistry , Biology</p>
                </div>

                <div className="content">
                  <p>Name: Amandeep Singh</p>
                  <p>Qualification: BCA</p>
                  <p>Expreience: 2 Months</p>
                  <p>Specialization: Physics , Chemistry , Biology</p>
                  <div>
                    <button className="btn10 ">ENQUIRE NOW</button>
                  </div>
                </div>
              </div>
            </div>
            </Card>
          </div>
          <div className="col-md-4">
          <Card style={{height:"470px"}}>
            <div className="wrapper">
              <div className="image">
                <img
                  src="./tutor4-740x792.jpg"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="content1">
                  <p>Name: Amandeep Singh</p>
                  <p>Qualification: BCA</p>
                  <p>Expreience: 2 Months</p>
                  <p>Specialization: Physics , Chemistry , Biology</p>
                </div>

                <div className="content">
                  <p>Name: Amandeep Singh</p>
                  <p>Qualification: BCA</p>
                  <p>Expreience: 2 Months</p>
                  <p>Specialization: Physics , Chemistry , Biology</p>
                  <div>
                    <button className="btn10 ">ENQUIRE NOW</button>
                  </div>
                </div>
              </div>
            </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faculti;
