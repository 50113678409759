import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

const ExamDesk = () => {
  const [viewmore, setViewmore] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [viewmoree, setViewmoree] = useState({});
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  //   //integrating get  method
  const [AddMcq, setAddMcq] = useState([]);
  const getAddMcq = async () => {
    try {
      let res = await axios.get("https://doorstepedu.info/api/user/getMcq");
      if (res.status === 200) {
        setAddMcq(res.data.getMcq);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [ETestType, setETestType] = useState("");
  const [ETestName, setETestName] = useState("");
  const [ETestPhone, setETestPhone] = useState("");
  const [ETestEmail, setETestEmail] = useState("");
  const [ETestMsg, setETestMsg] = useState("");

  const testEnquiryf = async () => {
    try {
      const config = {
        url: "/user/testenquiry",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "application/json" },
        data: {
          ETestType: ETestType,
          ETestName: ETestName,
          ETestPhone: ETestPhone,
          ETestEmail: ETestEmail,
          ETestMsg: ETestMsg,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          setETestType("");
          setETestName("");
          setETestPhone("");
          setETestEmail("");
          setETestMsg("");
        }
      });
    } catch (error) {
      alert(error.response.data.msg);
    }
  };
  useEffect(() => {
    getAddMcq();
  }, []);
  console.log(AddMcq);
  useEffect(() => {
    getAddMcq();
  }, []);
  return (
    <>
      <div className="">
        <div
          class="module mid"
          style={{
            height: "80px",
            background: "#d1ccc0",
            overflow: " hidden",
            // background: " #C33764",
            //   background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
            //  url("Header 2.jpg")`,
            backgroundSize: "cover",
          }}
        >
          <h2> Exam Desk</h2>
          <ul
            class=" container breadcrumbs bc3 pt-3 pb-3"
            role="menubar"
            aria-label="breadcrumbs"
          >
            <li>
              <a href="/">Home</a>{" "}
              <span style={{ color: "#092746" }}>&raquo;</span>
            </li>
            <li style={{ color: "#092746" }}> Exam Desk</li>
          </ul>
        </div>
      </div>
      <div className="container mt-2 " id="mcq">
        <div className="row hjklo_9">
          <div className="col-md-12">
            <h1>MCQ Test</h1>
          </div>
        </div>
      </div>
      <div className="container mt-3">
        <div className="row">
          {AddMcq?.map((item) => {
            return (
              <div className="col-md-8">
                <img
                  src={`https://doorstepedu.info/MCQ/${item?.MImage1}`}
                  alt=""
                  style={{ width: "100%", height: "250px" }}
                />
                <p style={{ textAlign: "justify", fontSize: "18px" }}>
                  {item?.MText1?.slice(0, 500) < item?.MText1?.slice(0, 501) ? (<>{item?.MText1?.slice(0, 500)}...  <br />  <span
                    className="viewmore text-dark"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleShow();
                      setViewmore(item);
                    }}
                  >
                    View More
                  </span> </>) : (<>{item?.MText1}</>)}

                </p>
                <div className="container mt-2 " id="subjective">
                  <div className="row hjklo_9">
                    <div className="col-md-12">
                      <h1>Subjective Test</h1>
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src={`https://doorstepedu.info/MCQ/${item?.MImage2}`}
                    alt=""
                    style={{ width: "100%", height: "250px" }}
                  />
                  <p style={{ textAlign: "justify", fontSize: "18px" }}>
                    {item?.MText2?.slice(0, 500) < item?.MText2?.slice(0, 501) ? (<>{item?.MText2?.slice(0, 500)}... <br /> <span
                      className="viewmore text-dark"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleShow1();
                        setViewmoree(item);
                      }}
                    >
                      View More
                    </span></>) : (<>{item?.MText2}</>)}{" "}

                  </p>
                </div>
              </div>
            );
          })}

          <div className="col-md-4">
            {/* <Card style={{ width: "22rem" }}>
              <Card.Img variant="top" src="./mcq.jpg" />
              <Card.Body>
                <Card.Title>MCQ Test</Card.Title>
                <Card.Text>
                  Here We Conduct the MCQ Tests to get more Details Click here
                </Card.Text>
                <button
                  className="btn10 "
                  style={{ margin: "auto" }}
                  onClick={handleShow}
                >
                  ENQUIRE NOW
                </button>
              </Card.Body>
            </Card> */}
            <Form
              style={{
                // border: "1px solid rgb(0,0,0,0.2)",
                padding: "20px",
                boxShadow: "0 8px 32px 0 rgba(19.19.20,0.37)",
                background: "rgba(255,255,255,0.24)",
                backdropFilter: "blur (4px)",
              }}
            >
              <h1>Enquire Now </h1>
              <FloatingLabel
                controlId="floatingSelectGrid"
                label="Select Test Type"
              >
                <Form.Select
                  aria-label="Floating label select example"
                  onChange={(e) => setETestType(e.target.value)}
                >
                  <option value="">---- select----</option>
                  <option value="MCQ Test">MCQ Test </option>
                  <option value="Subjective Test">Subjective Test</option>
                </Form.Select>
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="Enter Your Name">
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  className="rtyui_0"
                  onChange={(e) => setETestName(e.target.value)}
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="Mobile No">
                <Form.Control
                  type="number"
                  placeholder="name@example.com"
                  className="rtyui_0"
                  onChange={(e) => setETestPhone(e.target.value)}
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingInput" label="Email">
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  className="rtyui_0"
                  onChange={(e) => setETestEmail(e.target.value)}
                />
              </FloatingLabel>
              <FloatingLabel controlId="floatingTextarea2" label="Message">
                <Form.Control
                  as="textarea"
                  placeholder="Leave a comment here"
                  style={{ height: "100px" }}
                  className="rtyui_0"
                  onChange={(e) => setETestMsg(e.target.value)}
                />
              </FloatingLabel>
              <button className="btn10 mt-2" onClick={testEnquiryf}>
                {" "}
                Send Request
              </button>
            </Form>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>MCQ Test</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            {" "}
            <img
              src={`https://doorstepedu.info/MCQ/${viewmore?.MImage1}`}
              alt=""
              style={{ width: "100%", height: "300px" }}
            />
          </span>{" "}
          <p>{viewmore?.MText1}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>
            Subjective Test
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            {" "}
            <img
              src={`https://doorstepedu.info/MCQ/${viewmoree?.MImage2}`}
              alt=""
              style={{ width: "100%", height: "300px" }}
            />
          </span>{" "}
          <p>{viewmoree?.MText2}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExamDesk;
