import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

function Adminspecialevent() {
  const [data, setdata] = useState([]);
  const apiURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    getusers();
  }, []);

  const getusers = () => {
    try {
      axios
        .get(`https://doorstepedu.info/api/getspecialevent`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata(response.data.specialevent);
            return;
          } else {
            //alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };

  const postdeleteimage = async (data) => {
    try {
      const config = {
        url: "/deletespecialevent/" + data._id,
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "content-type": "multipart/form-data" },

        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("specialevent deleted successfully");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };
  async function create(data) {
    try {
      const config = {
        url: "/specialeventroomid",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "event-type": "application/json" },
        data: { id: data._id, roomid: data._id },

        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        window.location.assign(`/room/${data._id}`);
        // console.log(res.data);
        // alert("event deleted successfully")
        // window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div style={{ background: "#fff" }}>
      <h2 style={{ color: "#01a695", textAlign: "center" }}>
        {" "}
        Adminspecialevent{" "}
      </h2>
      <Table responsive hover size="md">
        <thead>
          <tr>
            <th>S. no</th>
            <th>Image</th>
            <th> title </th>
            <th> Date </th>
            <th> time </th>
            <th> amount </th>
            <th> Content </th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((datas) => (
            <tr>
              <td>1</td>
              <td>
                {" "}
                <img
                  src={
                    "http://bd.parnetslink.com/specialevent/" +
                    datas.specialeventimage
                  }
                  height="100px"
                  width="100px"
                  style={{ borderRadius: "100%" }}
                />{" "}
              </td>
              <td>{datas.specialeventtitle}</td>
              <td>{datas.specialeventdate}</td>
              <td>{datas.specialeventtime}</td>
              <td>{datas.specialeventamount}</td>
              <td>{datas.specialeventcontent}</td>
              <td>
                <button
                  className="btn btn-outline-success"
                  onClick={() => create(datas)}
                >
                  Create room
                </button>{" "}
                <button
                  className="btn btn-outline-danger"
                  onClick={() => postdeleteimage(datas)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default Adminspecialevent;
