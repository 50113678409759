import React, { useState } from "react";
import { Card } from "react-bootstrap";
import axios from "axios";

function Addteacherdetail() {
  const [image, setimage] = useState();
  const [teacherinfotitle, setteacherinfotitle] = useState(" ");
  const [teacherinfodate, setteacherinfodate] = useState();
  const [teacherinfoclassname, setteacherinfoclassname] = useState(" ");
  const [teacherinfoexperience, setteacherinfoexperience] = useState(" ");
  const [teacherinfosubject, setteacherinfosubject] = useState(" ");

  const formdata = new FormData();

  const postimage = async () => {
    formdata.append("teacherinfofile", image);
    formdata.append("teacherinfotitle", teacherinfotitle);
    formdata.append("teacherinfoclassname", teacherinfoclassname);
    formdata.append("teacherinfodate", teacherinfodate);
    formdata.append("teacherinfoexperience", teacherinfoexperience);
    formdata.append("teacherinfosubject", teacherinfosubject);

    try {
      const config = {
        url: "/addteacherinfo",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "teacherinfo-type": "multipart/form-data" },
        data: formdata,
        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("teacherinfo add successfully");
        window.location.assign("/admin/teacherinfo");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="container mt-5">
      <Card
        className="text-center"
        style={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <h5 className="pt-5">Add teacherinfo</h5>
        <Card.Body>
          <div>
            <img
              src={image != undefined ? URL.createObjectURL(image) : null}
              style={{ height: "300px", width: "350px" }}
            />
          </div>
          <div>
            <input
              type="file"
              style={{ border: "none" }}
              onChange={(e) => setimage(e.target.files[0])}
            />
          </div>
          <div>
            <label> teacherinfo Title </label>
            <input
              type="text"
              onChange={(e) => setteacherinfotitle(e.target.value)}
            ></input>
          </div>
          <div>
            <label> Date </label>
            <input
              type="date"
              onChange={(e) => setteacherinfodate(e.target.value)}
            ></input>
          </div>
          <div>
            <label> Experience </label>
            <textarea
              row="0"
              onChange={(e) => setteacherinfoexperience(e.target.value)}
            ></textarea>
          </div>
          <div>
            <label> Subject </label>
            <textarea
              row="2"
              onChange={(e) => setteacherinfosubject(e.target.value)}
            ></textarea>
          </div>

          <div>
            <label> classname </label>
            <textarea
              row="2"
              onChange={(e) => setteacherinfoclassname(e.target.value)}
            ></textarea>
          </div>
          <button className="btn btn-primary" onClick={postimage}>
            Add teacherinfo
          </button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Addteacherdetail;
