import React from 'react'

const Subjective = () => {
  return (
    <>
    <div
        style={{
          backgroundImage: "url('./about-us.jpg')",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          height: "300px",
        }}
      >
        {" "}
        <div className="container mt-2 pt-5">
          <div className="row hjklo_9">
            <div className="col-md-12 text-white">
              <h1>
                Subjective
                <span> Tests </span>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-2">
        <div className="row hjklo_9">
          <div className="col-md-12">
            <h3 style={{ fontFamily: "initial" }}>
              HTML and Web Page Designing Subjective Questions Practice Test - Set 01
            </h3>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h4 style={{ fontFamily: "initial", fontWeight: "bold" }}>
              Practice Test: Question Set - 01
            </h4>
            <hr />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
            <div className="col-md-6">
                <p>Q1. What is HTML ?</p>
                <button className='btn10'>View Answer</button>
                <p>Q2. What is HTML ?</p>
                <button className='btn10'>View Answer</button>
                <p>Q3. What is HTML ?</p>
                <button className='btn10'>View Answer</button>
                <p>Q4. What is HTML ?</p>
                <button className='btn10'>View Answer</button>
                <p>Q5. What is HTML ?</p>
                <button className='btn10'>View Answer</button>
                <p>Q6. What is HTML ?</p>
                <button className='btn10'>View Answer</button>
                <p>Q7. What is HTML ?</p>
                <button className='btn10'>View Answer</button>

            </div>
        </div>
      </div>
    </>
  )
}

export default Subjective