import React from "react";

const Privacy = () => {
  return (
    <>
      <div className="text-center mt-4">
        <h1 style={{ fontFamily: "initial" }}>Privacy Disclaimer.</h1>
      </div>
      <div className="container">
        <div className="row ">
          <div className="col-md-12 dghj">
            <p>
              At Doorstep Edu Group, we are committed to safeguarding the
              privacy of our website visitors and users. This privacy disclaimer
              outlines the types of personal information we collect, how we use
              and protect it, and the choices you have regarding your data.
            </p>
            <h5>1. Collection of Personal Information:</h5>
            <p>
              When you visit our website, we may collect certain personally
              identifiable information, such as your name, email address,
              contact number, and any other information you voluntarily provide
              to us through forms or interactions on our site.
            </p>
            <h5>2. Use of Personal Information:</h5>
            <p>
              We use the personal information collected to provide you with the
              requested services and improve your browsing experience on our
              website. This may include responding to your inquiries, delivering
              relevant content, and notifying you about updates, promotions, or
              other information related to Doorstep Edu Group.
            </p>
            <h5>3. Protection of Personal Information:</h5>
            <p>
              We take appropriate measures to ensure the security of your
              personal information and protect it from unauthorized access,
              disclosure, alteration, or destruction. However, please note that
              no method of transmission over the internet or electronic storage
              is entirely secure, and we cannot guarantee absolute security.
            </p>
            {/* <p>
            You grant{" "}
            <span style={{ fontWeight: "bold" }}>DST Edu. Power</span> a
            royalty-free and non-exclusive license to display, use, copy,
            transmit, and broadcast the content you upload and publish. For
            issues regarding intellectual property claims, you should contact
            the company in order to come to an agreement.
          </p> */}
            <h5>4. Third-Party Disclosure:</h5>
            <p>
              We do not sell, trade, or transfer your personal information to
              third parties without your consent, except as required by law or
              necessary to provide the requested services. We may engage trusted
              third-party service providers who assist us in operating our
              website or conducting business, and they are obligated to keep
              your information confidential.
            </p>
            <p>
              If you think there are any possible issues regarding the security
              of your account on the website, inform us immediately so we may
              address them accordingly.
            </p>
            <p>
              We reserve all rights to terminate accounts, edit or remove
              content and cancel orders at our sole discretion.
            </p>
            <h5>5. Cookies and Tracking Technologies:</h5>
            <p>
              Our website may use cookies and similar tracking technologies to
              enhance your user experience, gather statistical information, and
              analyze trends. You can control and manage the use of cookies
              through your browser settings.
            </p>
            <h5>6. Links to Third-Party Websites:</h5>
            <p>
              Our website may contain links to external websites that are not
              operated by Doorstep Edu Group. We have no control over the
              content and practices of these third-party sites and encourage you
              to review their privacy policies before providing any personal
              information.
            </p>
            <h5>7. Children's Privacy:</h5>
            <p>
              Our services are not intended for children under the age of 13. We
              do not knowingly collect personal information from children. If
              you believe we have inadvertently collected information from a
              child, please contact us, and we will take appropriate steps to
              remove the information.
            </p>
            <h5>8. Your Rights:</h5>
            <p>
              You have the right to access, update, correct, or delete your
              personal information held by us. If you wish to exercise these
              rights or have any questions about our privacy practices, please
              contact us using the provided contact information.
            </p>
            <h5>9. Changes to this Privacy Disclaimer:</h5>
            <p>
              We may update this privacy disclaimer from time to time to reflect
              changes in our practices or legal requirements. We encourage you
              to review this page periodically for any updates.
            </p>
            <p>
              By using our website, you consent to the terms outlined in this
              privacy disclaimer and agree to the collection, use, and
              disclosure of your personal information as described above.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
