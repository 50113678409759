import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import axios from "axios";
import moment from "moment";

function Addexam() {
  const [data, setdata] = useState([]);
  const [image, setimage] = useState();
  const [examtitle, setexamtitle] = useState(" ");
  const [selectedImages, setSelectedImages] = useState([]);

  const [examdate, setexamdate] = useState();
  const [examclassname, setexamclassname] = useState(" ");
  const [examsubject, setexamsubject] = useState(" ");
  const [mark, setmark] = useState(" ");
  const [others, setothers] = useState(" ");
  const [type, settype] = useState(" ");
  const [standard, setstandard] = useState(" ");
  const [studentname, setstudentname] = useState(null);
  const [examinstruction, setexaminstruction] = useState("");
  const [time, settime] = useState("");
  const user = JSON.parse(sessionStorage.getItem("admin"));
  const formdata2 = new FormData();
  const apiURL = process.env.REACT_APP_API_URL;
  const handleFileChange = (event) => {
    const files = event.target.files;
    const newSelectedImages = [...selectedImages, ...files];
    setSelectedImages(newSelectedImages);
  };
  const uploadFiles=async(id)=>{
   
    try {
     
      for (let i = 0; i < selectedImages.length; i++) {
       
        formdata2.append("id", id);
        formdata2.append("examfile", selectedImages[i]
          );
        await fetch('https://doorstepedu.info/api/admin/addExamFiles/', {
          method: 'post',
          body: formdata2
          
        })
      }
      
    } catch (error) {
      console.log(error);
    }
  }
  const postimage = async () => {
    // formdata.append("examfile", image);
    // formdata.append("examtitle", examtitle);
    // formdata.append("examclassname", examclassname);
    // formdata.append("examdate", examdate);
    // formdata.append("examsubject", examsubject);
    // formdata.append("others", others);
    // formdata.append("mark", mark);
    // formdata.append("adminid", user._id);
    // formdata.append(`${studentname !== null ? 'studentid' : ''}`, studentname);

    // formdata.append("standard", standard);
    // formdata.append("examtype", type);
    // formdata.append("examinstruction", examinstruction);
    // formdata.append("examtime", time);

    if (
      !examtitle ||
      !examsubject ||
      !others ||
      !examdate ||
      !examinstruction || 
      !time ||
      !mark ||
      !standard ||
      !type ||
      selectedImages.length === 0
    ) {
      alert('Please fill mandatory fields');
    } else {
    try {
      const config = {
        url: "/admin/addexam",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers:{"content-type":"application/json"},

        data :{
          examtitle:examtitle,
          examclassname:examclassname,
          examdate: moment(examdate).format('DD/MM/YYYY'),
          examsubject:examsubject,
          others: others,
          mark:mark,
          adminid:user._id,
          teacherid:null,
          standard:standard,
          studentid:studentname,
          examtype:type,
          examinstruction: examinstruction,
          examtime: time
        
      }
    }
      let res = await axios(config);
      if (res.status === 200) {
      
        uploadFiles(res.data.success?._id)
        alert("exam add successfully");
        window.location.assign("/admin/exam");
      }
    } catch (error) {
      console.log(error);
    }
  }
  };

  useEffect(() => {
    getusers();
  }, []);

  const getusers = () => {
    try {
      axios
        .get(`https://doorstepedu.info/api/user/getAllUser1`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata(response.data.success);
            return;
          } else {
            //alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };

  useEffect(() => {
    filter();
  }, [standard]);

  const filter = () => {
    if (standard != "") {
      let fliterdata = data.filter((item) => item["standard"] === standard);
      console.log(fliterdata);
      setdata(fliterdata);
    }
  };
console.log(data,"ffgdfdf");
  return (
    <div className="container mt-5">
      <div className="section mt-2 mb-2">
          <div className="section-title" style={{fontSize:"20px",fontWeight:"800",alignSelf:"center"}}>Add Exam</div>
          <div className="card">
            <div className="card-body">
              <form>
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="text4">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text4"
                      placeholder="Exam Title"
                      onChange={(e) => setexamtitle(e.target.value)}
                    />
                    <i className="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>

                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="">
                      Subject
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder="Subject"
                      onChange={(e) => setexamsubject(e.target.value)}
                    />
                    <i className="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="">
                      Duration
                    </label>
                    {/* <input
                      type="time"
                      className="form-control"
                      id=""
                      placeholder="Subject"
                      onChange={(e) => settime(e.target.value)}
                    /> */}
                      <input
                     type="number"
                     placeholder="Duration eg: 30 ,180 in minutes"
                      className="form-control"
                      id=""
                     
                      onChange={(e) => settime(e.target.value)}
                    />
                    <i className="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="">
                      Exam Instruction
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder="Instruction"
                      onChange={(e) => setexaminstruction(e.target.value)}
                    />
                    <i className="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="select4">
                      Exam Type
                    </label>
                    <select
                      className="form-control custom-select"
                      id="select4"
                      onChange={(e) => settype(e.target.value)}
                    >
                      <option value="">Select Exam Type</option>
                      <option value="standard">Whole class</option>
                      <option value="student">Individual student Name</option>
                    </select>
                  </div>
                </div>

                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="select4">
                      ClassName list
                    </label>
                    <select
                      className="form-control custom-select"
                      id="select4"
                      onChange={(e) => setstandard(e.target.value)}
                    >
                      <option value="">Select class</option>
                      <option value="Class 1">Class 1</option>
                  <option value="Class 2">Class 2</option>
                  <option value="Class 3">Class 3</option>
                  <option value="Class 4">Class 4</option>
                  <option value="Class 5">Class 5</option>
                  <option value="Class 6">Class 6</option>
                  <option value="Class 7">Class 7</option>
                  <option value="Class 8">Class 8</option>
                  <option value="Class 9">Class 9</option>
                  <option value="Class 10">Class 10</option>
                  <option value="Class 11">Class 11</option>
                  <option value="Class 12">Class 12</option>
                    </select>
                  </div>
                </div>

                {type == "student" ? (
                  <>
                    {" "}
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" for="select4">
                          Student Name list
                        </label>
                        <select
                          className="form-control custom-select"
                          id="select4"
                          onChange={(e) => setstudentname(e.target.value)}
                        >
                          <option value="">Select Student </option>
                          {data.filter((data)=> data.standard === standard).map((name) => (
                            <option value={name._id}>{name.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>{" "}
                  </>
                ) : (
                  ""
                )}

                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="">
                      Others
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder="Others"
                      onChange={(e) => setothers(e.target.value)}
                    />
                    <i className="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="">
                      Mark
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id=""
                      placeholder="Mark"
                      onChange={(e) => setmark(e.target.value)}
                    />
                    <i className="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>

                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="password4">
                      Date
                    </label>
                    <input
                      type="Date"
                      className="form-control"
                      id="password4"
                      autocomplete="off"
                      placeholder="Password Input"
                      onChange={(e) => setexamdate(e.target.value)}
                    />
                    <i className="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>
              </form>
            </div>
            <div className="section-title ">Upload File</div>
            <div className="">
              <div className="-body">
                <form>
                <div className="" id="fileUpload1">
                    <input
                      type="file"
                      id="fileuploadInput"
                      multiple 
                     
                      onChange={(e)=>handleFileChange(e)}
                    />
                     {selectedImages.map((imageFile, index) => (
          <img key={index} src={URL.createObjectURL(imageFile)} alt={`Image ${index}`} />
        ))}
                    {/* <label for="fileuploadInput" style={{ zIndex: "0" }}>
                      <span>
                        <strong>
                          <ion-icon name="arrow-up-circle-outline"></ion-icon>
                          <i>Upload a File</i>
                        </strong>
                      </span>
                    </label> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="form-button-group  transparent">
          <button
            type="submit"
            class="btn btn-primary btn-block btn-lg"
            onClick={postimage}
            style={{ background: "bule !important", zIndex: "01" }}
          >
            Create
          </button>
        </div>
    </div>
  );
}

export default Addexam;
