import axios from "axios";
import React, { useState } from "react";
import { Card } from "react-bootstrap";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

function Addsubadmin() {
  const apiURL = process.env.REACT_APP_API_URL;
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [password, setpassword] = useState("");
  const [subadmin, setsubadmin] = useState(false);
  const [slider, setslider] = useState(false);
  const [events, setevents] = useState(false);
  const [specialevent, setspecialevent] = useState(false);
  const [transaction, settransaction] = useState(false);
  const [createevent, setcreateevent] = useState(false);
  const [donate, setdonate] = useState(false);
  const [user, setuser] = useState(false);

  const [booking, setbooking] = useState(false);

  const postsubadmin = async (e) => {
    e.preventDefault();

    try {
      const config = {
        url: "/addsubadmin",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "content-type": "application/json" },
        data: {
          name: name,
          email: email,
          password: password,
          phoneNumber: phonenumber,
          subadmin: subadmin,
          slider: slider,
          events: events,
          specialevent: specialevent,
          booking: booking,
          transaction: transaction,
          createevent: createevent,
          donate: donate,
          user: user,
        },
        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        window.location.assign("/admin/adminsubadmin");
        return res;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container pt-5 pb-3">
      <Card
        className="text-center"
        style={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <h5 className="pt-5"> Add Sub-Admin</h5>
        <Card.Body>
          {/* <div>
          <img src="/images/logo-1.svg" style={{height:"300px" ,width:"350px"}}/>
          </div> */}
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <label>Name</label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter the Name"
                onChange={(e) => setname(e.target.value)}
              />
              <label>Email</label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter the Email"
                onChange={(e) => setemail(e.target.value)}
              />
              <label>Mobile Number</label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter the Mobile Number"
                onChange={(e) => setphonenumber(e.target.value)}
              />
              <label>Password</label>
              <input
                className="form-control"
                type="text"
                placeholder="Enter the Password"
                onChange={(e) => setpassword(e.target.value)}
              />
              <label>Access Modules</label>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={subadmin}
                      onChange={() => setsubadmin(!subadmin)}
                    />
                  }
                  label="Sub-Admin"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={slider}
                      onChange={() => setslider(!slider)}
                    />
                  }
                  label="Slider"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={events}
                      onChange={() => setevents(!events)}
                    />
                  }
                  label="Events"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={specialevent}
                      onChange={() => setspecialevent(!specialevent)}
                    />
                  }
                  label="Special Events"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={booking}
                      onChange={() => setbooking(!booking)}
                    />
                  }
                  label="Booking"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={transaction}
                      onChange={() => settransaction(!transaction)}
                    />
                  }
                  label="Transaction"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={createevent}
                      onChange={() => setcreateevent(!createevent)}
                    />
                  }
                  label="createevent"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={donate}
                      onChange={() => setdonate(!donate)}
                    />
                  }
                  label="donate"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={user} onChange={() => setuser(!user)} />
                  }
                  label="User"
                />
              </FormGroup>
            </div>
          </div>

          <button className="btn btn-primary mt-3" onClick={postsubadmin}>
            Add Subadmin
          </button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Addsubadmin;
