import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

const ViewTutor = () => {
  const location = useLocation();
  console.log("fdfs", location?.state)
  //integrating get  method
  // const [Addfaculty, setAddfaculty] = useState([]);
  // const getAddfaculty = async () => {
  //   try {
  //     let res = await axios.get(
  //       "https://doorstepedu.info/api/user/getfacultybyid" + id
  //     );
  //     if (res.status === 200) {
  //       setAddfaculty(res.data.getAddfacultybyid);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   getAddfaculty();
  // }, []);
  // console.log("dtyfdjyfj", Addfaculty);
  return (
    <>
      <div className="">
        <div
          class="module mid"
          style={{
            height: "80px",
            background: "rgb(0 166 150 / 20%)",
            overflow: " hidden",
            // background: " #C33764",
            //   background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
            //  url("Header 2.jpg")`,
            backgroundSize: "cover",
          }}
        >
          <h2>Tutor</h2>
          <ul
            class=" container breadcrumbs bc3 pt-3 pb-3"
            role="menubar"
            aria-label="breadcrumbs"
          >
            <li>
              <a href="/">Home</a>{" "}
              <span style={{ color: "#092746" }}>&raquo;</span>
            </li>
            <li>
              {" "}
              <a href="/faculty">Faculty</a>{" "}
              <span style={{ color: "#092746" }}>&raquo;</span>
            </li>
            <li style={{ color: "#092746" }}>Tutor</li>
          </ul>
        </div>
      </div>
      <div className="container mt-3">
        <div className="row">
          <div className="col-md-6">
            <img
              src={`https://doorstepedu.info/Faculty/${location?.state?.FImg}`}
              alt=""
              style={{
                width: "100%",
                height: "350px",
                border: "1px solid rgba(0,0,0,0.1",
              }}
            />
          </div>
          <div className="col-md-6 ">
            <h3>{location?.state?.FName}</h3>
            <p style={{ textAlign: "justify" }}>
              {location?.state?.FDescription}
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Qualifications:</span>{" "}
              <span> {location?.state?.FQualification}</span>
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Experience</span>{" "}
              <span>{location?.state?.FExprience}</span>
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Subjects:</span>{" "}
              <span> {location?.state?.FSpecialization} </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewTutor;
