import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import axios from "axios";

import { Link } from "react-router-dom";

function Allnote() {
  return (
    <div className="container mt-5">
      <div className="row">
        {" "}
        <Link to="/admin/addnoticeboard">
          <Card
            style={{ width: "10rem", marginLeft: "20px", marginRight: "20px" }}
          >
            <Card.Body>
              <Card.Title>NoticeBoard </Card.Title>
              <Card.Text>For All</Card.Text>
            </Card.Body>
          </Card>
        </Link>
        <Link to="/admin/addnoteteacher">
          <Card style={{ width: "10rem", marginRight: "20px" }}>
            <Card.Body>
              <Card.Title>NoticeBoard </Card.Title>
              <Card.Text>For Teacher</Card.Text>
            </Card.Body>
          </Card>
        </Link>
        <Link to="/admin/addnotestudent">
          <Card style={{ width: "10rem", marginRight: "20px" }}>
            <Card.Body>
              <Card.Title>NoticeBoard </Card.Title>
              <Card.Text>For Student</Card.Text>
            </Card.Body>
          </Card>
        </Link>
        <Link to="/admin/addnoteparent">
          <Card style={{ width: "10rem", marginRight: "20px" }}>
            <Card.Body>
              <Card.Title>NoticeBoard </Card.Title>
              <Card.Text>For Parent</Card.Text>
            </Card.Body>
          </Card>
        </Link>
      </div>
    </div>
  );
}

export default Allnote;
