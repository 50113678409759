import React, { useState } from "react";
import { Card } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import { useEffect } from "react";

function Addassignment() {
 
  const [data, setdata] = useState([]);
  const [image, setimage] = useState('');
  const [selectedImages, setSelectedImages] = useState([]);
  const [assignmenttitle, setassignmenttitle] = useState('');
  const [assignmentdate, setassignmentdate] = useState();
  const [assignmentclassname, setassignmentclassname] = useState('');
  const [assignmentsubject, setassignmentsubject] = useState('');
  const [mark, setmark] = useState('');
  const [others, setothers] = useState('');
  const [type, settype] = useState('');
  const [standard, setstandard] = useState('');
  const [studentname, setstudentname] = useState(null);
  const user = JSON.parse(sessionStorage.getItem("admin"));
  useEffect(() => {
    getusers();
  }, []);
  const handleFileChange = (event) => {
    const files = event.target.files;
    const newSelectedImages = [...selectedImages, ...files];
    setSelectedImages(newSelectedImages);
  };
  const getusers = async () => {
    try {
      const config = {
        url: '/user/getAllUser1',
        method: 'get',
        baseURL: 'https://doorstepedu.info/api',
        headers: {'content-type': 'application/json'},
      };
      await axios(config)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);

            setdata(response.data.success);
            return;
          } else {
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          // //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      // alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };
  const formdata2 = new FormData();
  
console.log(selectedImages,"sdsa");
  const uploadFiles=async(id)=>{
   
    try {
     
      for (let i = 0; i < selectedImages.length; i++) {
       
        formdata2.append("id", id);
        formdata2.append("assignmentfile", selectedImages[i]
          );
        await fetch('https://doorstepedu.info/api/admin/addAssignmentFiles/', {
          method: 'post',
          body: formdata2
          
        })
      }
      
    } catch (error) {
      console.log(error);
    }
  }
  const postimage = async () => {
    // formdata.append("assignmentfile", image);
    // formdata.append('assignmenttitle', assignmenttitle);
    // formdata.append('assignmentclassname', assignmentclassname);
    // formdata.append('assignmentdate', moment(assignmentdate).format('DD/MM/YYYY'));
    // formdata.append('assignmentsubject', assignmentsubject);
    // formdata.append('others', others);
    // formdata.append('mark', mark);
    // formdata.append('adminid', user._id);
    // formdata.append('standard', standard);
    // formdata.append(`${studentname !== null ? 'studentid' : ''}`, studentname);
    // formdata.append('assignmenttype', type);
    if (
      !assignmenttitle ||
      !assignmentsubject ||
      !others ||
      !assignmentdate ||
      !mark ||
      !standard ||
      !type ||
      selectedImages.length === 0
    ) {
      alert('Please fill mandatory fields');
    } else {
    try {
      const config = {
        url: "/admin/addassignment",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers:{"content-type":"application/json"},

        data :{
          assignmenttitle:assignmenttitle,
          assignmentclassname:assignmentclassname,
          assignmentdate: moment(assignmentdate).format('DD/MM/YYYY'),
          assignmentsubject:assignmentsubject,
          others: others,
          mark:mark,
          adminid:user._id,
          standard:standard,
          studentid:studentname,
          assignmenttype:type
        }
        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
       
        uploadFiles(res.data.success?._id)
        alert("Assignment added successfully");
        window.location.assign("/admin/assignment");
      }
    } catch (error) {
      console.log(error);
    }
  }
  };
  return (
    <div className="container mt-5">
      <Card
        className="text-center"
        style={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <h5 className="pt-5">Add assignment</h5>
        <div className="section mt-2 mb-2">
          <div className="section-title">Assignment Details</div>
          <div className="card">
            <div className="card-body">
              <form>
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="text4">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="text4"
                      placeholder="Assignment Title"
                      onChange={(e) => setassignmenttitle(e.target.value)}
                    />
                    <i className="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>

                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="">
                      Subject
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder="Subject"
                      onChange={(e) => setassignmentsubject(e.target.value)}
                    />
                    <i className="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="select4">
                      Assignment Type
                    </label>
                    <select
                      className="form-control custom-select"
                      id="select4"
                      onChange={(e) => settype(e.target.value)}
                    >
                      <option value="">Select Assignment Type</option>
                      <option value="standard">Whole class</option>
                      <option value="student">Individual student Name</option>
                    </select>
                  </div>
                </div>

                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="select4">
                      ClassName list
                    </label>
                    <select
                      className="form-control custom-select"
                      id="select4"
                      onChange={(e) => setstandard(e.target.value)}
                    >
                     <option value="Class 1">Class 1</option>
                  <option value="Class 2">Class 2</option>
                  <option value="Class 3">Class 3</option>
                  <option value="Class 4">Class 4</option>
                  <option value="Class 5">Class 5</option>
                  <option value="Class 6">Class 6</option>
                  <option value="Class 7">Class 7</option>
                  <option value="Class 8">Class 8</option>
                  <option value="Class 9">Class 9</option>
                  <option value="Class 10">Class 10</option>
                  <option value="Class 11">Class 11</option>
                  <option value="Class 12">Class 12</option>
                    </select>
                  </div>
                </div>

                {type == "student" ? (
                  <>
                    {" "}
                    <div className="form-group basic">
                      <div className="input-wrapper">
                        <label className="label" for="select4">
                          Student Name list
                        </label>
                        <select
                          className="form-control custom-select"
                          id="select4"
                          onChange={(e) => setstudentname(e.target.value)}
                        >
                          <option value="">Select Student </option>
                          {data.filter((data)=> data.standard === standard).map((name) => (
                            <option value={name._id}>{name.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>{" "}
                  </>
                ) : (
                  ""
                )}

                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="">
                      Others
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder="Others"
                      onChange={(e) => setothers(e.target.value)}
                    />
                    <i className="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>
                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="">
                      Mark
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id=""
                      placeholder="Mark"
                      onChange={(e) => setmark(e.target.value)}
                    />
                    <i className="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>

                <div className="form-group basic">
                  <div className="input-wrapper">
                    <label className="label" for="password4">
                      Due Date
                    </label>
                    <input
                      type="Date"
                      className="form-control"
                      id="password4"
                      autocomplete="off"
                      placeholder="Password Input"
                      onChange={(e) => setassignmentdate(e.target.value)}
                    />
                    <i className="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>
              </form>
            </div>
            <div className="section-title ">Upload File</div>
            <div className="">
              <div className="-body">
                <form>
                  <div className="" id="fileUpload1">
                    <input
                      type="file"
                      id="fileuploadInput"
                      multiple 
                     
                      onChange={(e)=>handleFileChange(e)}
                    />
                     {selectedImages.map((imageFile, index) => (
          <img key={index} src={URL.createObjectURL(imageFile)} alt={`Image ${index}`} />
        ))}
                    {/* <label for="fileuploadInput" style={{ zIndex: "0" }}>
                      <span>
                        <strong>
                          <ion-icon name="arrow-up-circle-outline"></ion-icon>
                          <i>Upload a File</i>
                        </strong>
                      </span>
                    </label> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="form-button-group  transparent">
          <button
            type="submit"
            class="btn btn-primary btn-block btn-lg"
            onClick={postimage}
            style={{ background: "bule !important", zIndex: "01" }}
          >
            Create
          </button>
        </div>
      </Card>
    </div>
  );
}

export default Addassignment;
