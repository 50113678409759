import React, { useState } from "react";
import { Card } from "react-bootstrap";
import axios from "axios";

function Addslider() {
  const [image, setimage] = useState();
  const formdata = new FormData();

  const postimage = async () => {
    if(!image){
      alert("Please select image");
    } else {
    formdata.append("slider", image);

    try {
      const config = {
        url: "/admin/addslider",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("Slider add successfully");
        window.location.assign("/admin/slider");
      }
    } catch (error) {
      console.log(error);
    }
  }
  };
  return (
    <div className="container mt-5">
      <Card
        className="text-center"
        style={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <h5 className="pt-5">Add Slider</h5>
        <Card.Body>
          <div>
            <img
              src={image != undefined ? URL.createObjectURL(image) : null}
              style={{ height: "200px", width: "250px" }}
            />
          </div>
          <div>
            <input
              type="file"
              style={{ border: "none" ,marginTop:"30px"}}
              accept="image/png, image/gif, image/jpeg"
              onChange={(e) => setimage(e.target.files[0])}
            />
          </div>
          <button className="btn btn-primary" onClick={postimage}  style={{ marginTop:"30px"}}>
            Add Slider
          </button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Addslider;
