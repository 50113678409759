import React, { useEffect, useState } from "react";
import { Button, Table, Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import axios from "axios";

const AdminSubjective = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // integrating post method
  const formdata = new FormData();
  const [GImage, setGImage] = useState("");
  const [GText, setGText] = useState("");
  const AddGallery = async () => {
    formdata.append("GImage", GImage);
    formdata.append("GText", GText);

    try {
      const config = {
        url: "/user/gallery",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddGallery();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //integrating get  method
  const [AddGalery, setAddGalery] = useState([]);
  const getAddGallery = async () => {
    try {
      let res = await axios.get("https://doorstepedu.info/api/user/getgallery");
      if (res.status === 200) {
        setAddGalery(res.data.getgallery);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //delete method
  const [Data, setData] = useState("");
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (item) => {
    setShow2(true);
    setData(item);
  };
  const Deletegallery = async () => {
    try {
      const config = {
        url: "user/Deletegallery/" + Data,
        method: "delete",
        baseURL: "https://doorstepedu.info/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully delete");
          getAddGallery();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };
  //update method
  const [Data1, setData1] = useState("");
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setData1(item);
  };

  const editgallery = async (e) => {
    e.preventDefault();
    if (!GImage) {
      alert("Select the image");
    } else {
      formdata.append("GImage", GImage);
      formdata.append("GText", GText);
      formdata.append("id", Data1?._id);
      try {
        const config = {
          url: "/user/editgallery",
          method: "put",
          baseURL: "https://doorstepedu.info/api",
          header: { "content-type": "multipart/form-data" },
          data: formdata,
        };
        await axios(config).then((res) => {
          if (res.status === 200) {
            alert("successfully Update");
            handleClose1();
            getAddGallery();
          }
        });
      } catch (error) {
        console.log(error);
        alert(error.response.data.error);
      }
    }
  };
  useEffect(() => {
    getAddGallery();
  }, []);
  console.log(AddGalery);
  return (
    <>
      <div className="add-gr">
        <div className="container">
          <div className="ad-b mt-4 mb-4">
            <button className="btn btn-success" onClick={handleShow}>
              Add Content
            </button>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="mo-gra mt-5">
          <Table striped bordered hover style={{ textAlign: "center" }}>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Image</th>
                <th>Text</th>

                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {AddGalery?.map((item, i) => {
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>
                      <Image
                        src={`https://doorstepedu.info/Gallery/${item?.GImage}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      />
                    </td>

                    <td style={{ paddingTop: "20px" }}>{item?.GText}</td>

                    <td>
                      <div className="ad-b ">
                        <span>
                          <i
                            class="fas fa-edit"
                            style={{
                              fontSize: "20px",
                              color: "green",
                              paddingTop: "20px",
                            }}
                            onClick={() => handleShow1(item)}
                          // title="edit"
                          ></i>
                        </span>
                      </div>
                    </td>
                    <td>
                      <i
                        class="fa fa-trash"
                        aria-hidden="true"
                        style={{
                          color: "red",
                          fontSize: "20px",
                          paddingTop: "20px",
                        }}
                        onClick={() => handleShow2(item?._id)}
                      // title="delete"
                      ></i>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>Add Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="floatingInput" label="Image">
            <Form.Control
              type="file"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setGImage(e.target.files[0])}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingInput" label="Enter Text">
            <Form.Control
              type="text"
              placeholder="name@example.com"
              className="rtyui_0"
              onChange={(e) => setGText(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#00a695", border: "none" }}
            onClick={AddGallery}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#00a695" }}>Enquire Now</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel controlId="floatingInput" label="Image">
            <Form.Control
              type="file"
              placeholder={GImage}
              className="rtyui_0"
              onChange={(e) => setGImage(e.target.files[0])}
            />
          </FloatingLabel>{" "}
          <FloatingLabel controlId="floatingInput" label="Enter Text">
            <Form.Control
              type="text"
              placeholder={GText}
              className="rtyui_0"
              onChange={(e) => setGText(e.target.value)}
            />
          </FloatingLabel>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
          <Button
            style={{ backgroundColor: "#00a695", border: "none" }}
            onClick={editgallery}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3 style={{ color: "#c0392b" }}>Do You Really Want To Delete...</h3>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={Deletegallery}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdminSubjective;
