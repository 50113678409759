import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import exportFromJSON from "export-from-json";

import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { dateFilter } from "react-bootstrap-table2-filter";
import DatePicker from "react-multi-date-picker";
import moment from "moment";
import { Rocket } from "@mui/icons-material";

function CompletedAssignment() {
  const { SearchBar, ClearSearchButton } = Search;
  const { ExportCSVButton } = CSVExport;
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpen2, setIsOpen2] = useState(false);

  const [mark, setmark] = useState("");
  const [Feedback, setFeedback] = useState("");
  const [data2, setdata2] = useState([]);
  const [ass, setass] = useState([]);

  const [data1, setdata1] = useState();
  const apiURL = process.env.REACT_APP_API_URL;
  const user = JSON.parse(sessionStorage.getItem("admin"));

  const showModal = (item) => {
    setIsOpen(true);
    setdata1(item);
  };
  const oncl1 = () => {
    setIsOpen1(true);
   setIsOpen2(false);
  };
  const oncl2 = () => {
    setIsOpen1(false);
   setIsOpen2(true);
  };
  const hideModal = () => {
    setIsOpen(false);
  };
  const [endDate, setendDate] = useState(new Date());
  const [startDate, setstartDate] = useState(new Date());

  useEffect(() => {
    getusers();
    setInterval(() => {
      getassignment1();
    }, 3000);
  }, []);
  console.log(ass, "datadhd");
  const getusers = () => {
    try {
      axios
        .get(`https://doorstepedu.info/api/user/getalluploadassignment`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setdata2(
              response.data.success.filter((data) => data.adminid === user._id)
            );
            // setdata1(response.data.assignment);
            return;
          } else {
            //alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };
  
  const data = [];
  if (data2?.length !== 0) {
    for (let i = 0; i < data2.length; i++) {
      data.push({
        Title: data2[i]?.assignmentid?.assignmenttitle,
        DueDate: data2[i]?.assignmentid?.assignmentdate,
        Subject: data2[i]?.assignmentid?.assignmentsubject,
        Class: data2[i]?.assignmentid?.standard,
        AssignmentType:
          data2[i]?.assignmentid?.assignmenttype === "standard"
            ? "Whole Class"
            : "Individual Student",
        StudentName: data2[i]?.studentid
          ? data2[i]?.studentid?.name
          : "Whole Class",
        Others: data2[i]?.assignmentid?.others,

        TeacherName: data2[i]?.assignmentid?.teacherid ? data2[i]?.assignmentid?.teacherid?.name : "Admin",
        Marks: data2[i]?.assignmentid?.mark,
        CompletedAssignmentDate: moment(data2[i]?.createdAt).format("DD/MM/YYYY"),
        
      });
    }
  }
  const fileName = "Assignment";
  const exportType = "xls";

  const ExportToExcel = () => {
    exportFromJSON({ data, fileName, exportType });
  };
  const getassignment1 = () => {
    try {
      axios
        .get(`https://doorstepedu.info/api/user/getalluploadassignmentremark`)
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
            //  localStorage.setItem("userdata",JSON.stringify(response.data.userdata));
            setass(response.data.success);
            // setdata1(response.data.assignment);
            return;
          } else {
            //alert("Can't able to fetch ");
            //setdatacondition(true);
            return;
          }
        })
        .catch(function (error) {
          // setdatacondition(true);
          console.log(error.message.data);
          //alert("Can't able to fetch ");
          return;
        });
    } catch (error) {
      console.warn(error);
      // alert("Can't able  to fetch ");
      // setdatacondition(true);
      return error;
    }
  };
  //   const getassignment1 = async () => {
  //     let res = await axios.get(
  //       'https://doorstepedu.info/api/admin/getalluploadassignmentremark',
  //     );
  //     if ((res.status = 200)) {
  //       console.log(res.data);
  //       setass(res.data.success.filter(data => data.adminid === user._id));
  //     }
  //   };
  const sendmark = async () => {
    try {
      const config = {
        url: "/user/uploadassignmentremark",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "content-type": "application/json" },
        data: {
          uploadassignmentid: data1._id,
          mark: mark,
          feedback: Feedback,
          adminid: user._id,
          assignmentid: data1.assignmentid,
          studentid: data1.studentid,
        },
        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("Remark submitted successfully");
        // navigation.navigate('TabContent');
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const postdeleteimage = async (data) => {
    try {
      const config = {
        url: "/user/deleteuploadassignment/" + data._id,
        method: "delete",
        baseURL: "https://doorstepedu.info/api",
        headers: { "content-type": "multipart/form-data" },

        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("Deleted successfully");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const postdeleteimage1 = async (data) => {
    try {
      const config = {
        url: "/user/deleteuploadassignmentremark/" + data._id,
        method: "delete",
        baseURL: "https://doorstepedu.info/api",
        headers: { "content-type": "multipart/form-data" },

        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("Deleted successfully");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      dataField: "assignmentid.assignmenttitle",
      text: "Title",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p style={{fontSize:"16px",marginTop:'-0px'}}>{row.assignmentid?.assignmenttitle}</p>
          </div>
        );
      },
    },
    {
      dataField: "assignmentid.assignmentdate",
      text: "Assignment Due Date",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p style={{fontSize:"16px",marginTop:'-0px'}}>{row.assignmentid?.assignmentdate}</p>
          </div>
        );
      },
    },
    {
      dataField: "assignmentid.assignmentsubject",
      text: "Subject",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p style={{fontSize:"16px",marginTop:'-0px'}}>{row.assignmentid?.assignmentsubject}</p>
          </div>
        );
      },
    },
    {
      dataField: "assignmentid.standard",
      text: "Class ",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p style={{fontSize:"16px",marginTop:'-0px'}}>{row.assignmentid?.standard}</p>
          </div>
        );
      },
    },
    {
      dataField: "assignmentid.assignmenttype",
      text: "Type",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p  style={{fontSize:"16px",marginTop:'-0px'}}>
              {row.assignmentid?.assignmenttype === "standard"
                ? "Whole Class"
                : "Individual Student"}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "studentid.name",
      text: "Student Name",
      // formatter: (cell, row) => {
      //   console.log(row);
      //   return (
      //     <div>
      //       <p>
      //         {
      //           ass.filter(
      //             (data) => data.uploadassignmentid?._id === row?._id
      //           )[0]?.studentid.name
      //         }
      //       </p>
      //     </div>
      //   );
      // },
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p style={{fontSize:"16px",marginTop:'-0px'}}>{row.studentid.name}</p>
          </div>
        );
      },
    },

    {
      dataField: "assignmentid.others",
      text: "Others ",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p style={{fontSize:"16px",marginTop:'-0px'}}>{row.assignmentid?.others}</p>
          </div>
        );
      },
    },
    {
      dataField: "assignmentid.teacherid.name",
      text: "Teacher Name",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p  style={{fontSize:"16px",marginTop:'-0px'}}>{row.teacherid ? row.teacherid?.name : "Admin"}</p>
          </div>
        );
      },
    },

    {
      dataField: "assignmentid.mark",
      text: "Mark",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p style={{fontSize:"16px",marginTop:'-0px'}}>{row.assignmentid?.mark}</p>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Completed Date",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p  style={{fontSize:"16px",marginTop:'-0px'}}>{moment(row.createdAt).format("DD/MM/YYYY")}</p>
          </div>
        );
      },
    },
    // {
    //   dataField: "",
    //   text: "Obtained Marks",
    //   formatter: (cell, row) => {
    //     console.log(row);
    //     return (
    //       <div>
    //         <p>
    //           {
    //             ass.filter((data) => data.uploadassignmentid?._id === row._id)[0]
    //               ?.mark
    //           }
    //         </p>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   dataField: "",
    //   text: "Remarks",
    //   formatter: (cell, row) => {
    //     console.log(row);
    //     return (
    //       <div>
    //         <p>
    //           {
    //             ass.filter((data) => data.uploadassignmentid?._id === row._id)[0]
    //               ?.feedback
    //           }
    //         </p>
    //       </div>
    //     );
    //   },
    // },
    {
      dataField: "",
      text: "Given Assignment file",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
           {row.assignmentid?.assignmentfile.map((data)=>( <a
              className="btn btn-success"
              href={
                "https://doorstepedu.info/assignment/" +
                data.file
              }
            >
              File
            </a>))}
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Completed Assignment file",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
           {row.assignmentfile.map((data)=>(  <a
              className="btn btn-success"
              style={{marginBottom:10}}
              href={"https://doorstepedu.info/assignment/" + data.file}
            >
              File
            </a>))}
          </div>
        );
      },
    },
   
    {
      dataField: "",
      text: "Action",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            
            <button className="btn btn-success" onClick={() => showModal(row)}>
              Add Remarks
            </button>  
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Delete",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn btn-danger"
              onClick={() => postdeleteimage(row)}
            >
              Delete
            </button>
          </div>
        );
      },
    },
     
  ];
  const columns1 = [
    {
      dataField: "assignmentid.assignmenttitle",
      text: "Title",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p style={{fontSize:"16px",marginTop:'-0px'}}>{row.assignmentid?.assignmenttitle}</p>
          </div>
        );
      },
    },
    {
      dataField: "assignmentid.assignmentdate",
      text: "Assignment Due Date",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p style={{fontSize:"16px",marginTop:'-0px'}}>{row.assignmentid?.assignmentdate}</p>
          </div>
        );
      },
    },
    {
      dataField: "assignmentid.assignmentsubject",
      text: "Subject",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p style={{fontSize:"16px",marginTop:'-0px'}}>{row.assignmentid?.assignmentsubject}</p>
          </div>
        );
      },
    },
    {
      dataField: "assignmentid.standard",
      text: "Class ",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p style={{fontSize:"16px",marginTop:'-0px'}}>{row.assignmentid?.standard}</p>
          </div>
        );
      },
    },
    {
      dataField: "assignmentid.assignmenttype",
      text: "Type",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p style={{fontSize:"16px",marginTop:'-0px'}}>
              {row.assignmenttype === "standard"
                ? "Whole Class"
                : "Individual Student"}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "studentid.name",
      text: "Student Name",
      // formatter: (cell, row) => {
      //   console.log(row);
      //   return (
      //     <div>
      //       <p>
      //         {
      //           ass.filter(
      //             (data) => data.uploadassignmentid?._id === row?._id
      //           )[0]?.studentid.name
      //         }
      //       </p>
      //     </div>
      //   );
      // },
    },

    {
      dataField: "assignmentid.others",
      text: "Others",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p style={{fontSize:"16px",marginTop:'-0px'}}>{row.assignmentid?.others}</p>
          </div>
        );
      },
    },
    {
      dataField: "assignmentid.teacherid.name",
      text: "Teacher Name",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p style={{fontSize:"16px",marginTop:'-0px'}}>{row.teacherid ? row.teacherid?.name : "Admin"}</p>
          </div>
        );
      },
    },

    {
      dataField: "assignmentid.mark",
      text: "Mark",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p style={{fontSize:"16px",marginTop:'-0px'}}>{row.assignmentid?.mark}</p>
          </div>
        );
      },
    },
    {
      dataField: "",
      text: "Completed Date",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <p style={{fontSize:"16px",marginTop:'-0px'}}>{moment(row.createdAt).format("DD/MM/YYYY")}</p>
          </div>
        );
      },
    },
    {
      dataField: "mark",
      text: "Obtained Marks",
     
    },
    {
      dataField: "feedback",
      text: "Remarks",
     
    },
    {
      dataField: "",
      text: "Delete",
      formatter: (cell, row) => {
        console.log(row);
        return (
          <div>
            <button
              className="btn btn-danger"
              onClick={() => postdeleteimage1(row)}
            >
              Delete
            </button>
          </div>
        );
      },
    },
     
     
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total px-4">
      Showing {from} to {to} of {size} Results
    </span>
  );


  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data2.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };
  const options1 = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: ass.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };
  // data.filter((data) => {
  //   console.log(new Date(data.date.split("-").reverse().join("-")));
  //   console.log(Date.parse(data.date));
  // });
  // const search = () => {
  //   const filteredData = data1.filter(
  //     (data) =>
  //       new Date(data.date.split("-").reverse().join("-")) >=
  //         new Date(startDate.split("-").reverse().join("-")) &&
  //       new Date(data.date.split("-").reverse().join("-")) <=
  //         new Date(endDate.split("-").reverse().join("-"))
  //   );
  //   setdata(filteredData);
  // };

  return (
    <div style={{ background: "#fff" }}>
     {isOpen1 ? <h2 style={{ color: "#01a695", textAlign: "center" }}>
        Completed Assignment{" "}
      </h2> : ""}
      {isOpen2 ? <h2 style={{ color: "#01a695", textAlign: "center" }}>
       Remarks{" "}
      </h2> : ""}
    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-around',marginTop:"30px"}}>  <div>
            <a
              className="btn btn-success"
              onClick={oncl1}
            >
              Completed Assignment
            </a>
          </div>
          <div>
            <a
              className="btn btn-success"
              onClick={oncl2} 
            >
              Remarks
            </a>
          </div>
          </div>
      <div className="row pb-3 px-3">
        <div className="col-md-2">
          {/* <h7>Date From</h7> */}
          {/* <DatePicker
            style={{ height: "35px", width: "140px", marginRight: "-5px" }}
            placeholder="DD-MM-YYYY"
            format="DD-MM-YYYY"
            type="input-icon"
            onChange={(date) => setstartDate(date.format("DD-MM-YYYY"))}
          />
        </div>
        <div className="col-md-2">
          <h7>Date To</h7>
          <DatePicker
            style={{ height: "35px", width: "140px", marginRight: "-5px" }}
            placeholder="DD-MM-YYYY"
            format="DD-MM-YYYY"
            type="input-icon"
            onChange={(date) => setendDate(date.format("DD-MM-YYYY"))}
          /> */}
        </div>
        {/* <div className="col-md-2">
          <button
            className="btn btn-primary"
            style={{ marginTop: "20px" }}
            onClick={search}
          >
            filter
          </button>
        </div> */}
      </div>
      {isOpen1 ?   
       <ToolkitProvider
        keyField="id"
        data={data2}
        columns={columns}
        search
        exportCSV={{
          fileName: "Assignment.csv",
          blobType: "text/csv;charset=ansi",
        }}
      >
        {(props) => (
          <div>
            {/* <span className="px-3 mr-auto ">
              <ExportCSVButton
                className="btn-outline-success"
                {...props.csvProps}
              >
                Export to Excel
              </ExportCSVButton>
            </span> */}
             <span className="px-3 mr-auto ">
              <ExportCSVButton
                className="btn-outline-success"
                onClick={ExportToExcel}
              >
                Export CSV
              </ExportCSVButton>
            </span>
            <span className="pl-4 mr-5 mt-5">
              <SearchBar className="form-control " {...props.searchProps} />
            </span>
            <span className="pl-4 mr-5 mt-5"  style={{marginLeft:"20px"}}>
            <ClearSearchButton
            
              className="btn-outline-info"
              {...props.searchProps}
            />
            </span>

            <hr />
        <div className="table-responsive" style={{ margin: "30px" }}>
              <BootstrapTable
                keyField="id"
                striped
                hover
                condensed
                columns={columns}
                data={data2}
                pagination={paginationFactory(options)}
                {...props.baseProps}
              />
            </div> 
          
          </div>
        )}
      </ToolkitProvider> : ""}
      {isOpen2 ? <ToolkitProvider
        keyField="id"
        data={ass}
        columns={columns1}
        search
        exportCSV={{
          fileName: "transaction.csv",
          blobType: "text/csv;charset=ansi",
        }}
      >
        {(props) => (
          <div>
            {/* <span className="px-3 mr-auto ">
              <ExportCSVButton
                className="btn-outline-success"
                {...props.csvProps}
              >
                Export to Excel
              </ExportCSVButton>
            </span> */}
            <span className="pl-4 mr-5 mt-5" style={{marginLeft:"20px"}}>
              <SearchBar className="form-control " {...props.searchProps} />
            </span>
            <span className="pl-4 mr-5 mt-5" style={{marginLeft:"20px"}}>
            <span className="pl-4 mr-5 mt-5" style={{marginLeft:"20px"}}>
            <ClearSearchButton
              className="btn-outline-info"
              {...props.searchProps}
            />
</span></span>

            <hr />
            <div className="table-responsive" style={{ margin: "30px" }}>
              <BootstrapTable
                keyField="id"
                striped
                hover
                condensed
                columns={columns1}
                data={ass}
                pagination={paginationFactory(options1)}
                {...props.baseProps}
              />
            </div>
          
          </div>
        )}
      </ToolkitProvider> : ""}
      {/* <Table striped bordered hover size="sm">
  <thead>
    <tr>
      <th>S.no</th>
      <th>assignment Name</th>
      <th>Amount</th>
      <th>User name</th>
      <th>User Email</th>
      
      <th >Booked Date</th>
      <th >assignment Date</th>
      <th>Payment Method</th>
      
    </tr>
  </thead>
  <tbody>
    {data?.map((e)=>(
       <tr>
       <td>1</td>
       <td>{e.assignment[0].assignmenttitle}</td>
       <td>{e.assignment[0].assignmentamount}</td>
       <td>{e.user[0].name}</td>
    <td>{e.user[0].email}</td>
    <td>{e.assignmentdate}</td>
    <td>{e.bookedtime}</td>
    <td>{e.paymentmethod}</td>
       
     </tr>
    ))}
   
    

  <a href="https://wa.me/country_code_and_your_phone_number" className="whatsapp_float" target="_blank"> <i className="fa fa-whatsapp whatsapp-icon"></i></a>

    
   
  </tbody>
</Table> */}
      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Header>
          <Modal.Title>Remark</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form>
              {/* <FormGroup>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-mutiple-checkbox-label">
                    Select Country
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={countryName}
                    onChange={handleChange}
                    input={<Input />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {data?.map((name) => (
                      <MenuItem key={name._id} value={name?.countryName}>
                        <Checkbox
                          checked={countryName.indexOf(name?.countryName) > -1}
                        />
                        <ListItemText primary={name?.countryName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup> */}
              {/* <br></br> */}
              <div className="form-group">
                <label>Marks</label>
                <input
                  type="number"
                  placeholder="Enter Mark"
                  className="form-control"
                  onChange={(e) => setmark(e.target.value)}
                ></input>
                <label className="mt-2">Feedback</label>
                <input
                  type="text"
                  placeholder="Enter Feedback"
                  className="form-control"
                  onChange={(e) => setFeedback(e.target.value)}
                ></input>
              </div>{" "}
              <br></br>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={hideModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={sendmark}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CompletedAssignment;
