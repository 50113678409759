import React, { useState } from "react";
import { Card, Form, Button } from "react-bootstrap";
import axios from "axios";

function Addnotestudent() {
  // const [image, setimage] = useState();
  const [notestudenttitle, setnotestudenttitle] = useState(" ");
  const [notestudentdate, setnotestudentdate] = useState();
  const [notestudentclassname, setnotestudentclassname] = useState(" ");
  const [notestudentsubject, setnotestudentsubject] = useState(" ");
  const [notestudentcontent, setnotestudentcontent] = useState(" ");

  const formdata = new FormData();

  const postimage = async () => {
    //formdata.append("notestudentfile", image);
    formdata.append("notestudenttitle", notestudenttitle);
    formdata.append("notestudentclassname", notestudentclassname);
    formdata.append("notestudentdate", notestudentdate);
    formdata.append("notestudentsubject", notestudentsubject);
    formdata.append("notestudentcontent", notestudentcontent);

    try {
      const config = {
        url: "/addnotestudent",
        method: "post",
        baseURL: "https://doorstepedu.info/api",
        headers: { "content-type": "multipart/form-data" },
        data: formdata,
        //responseType:"application/json"
      };
      let res = await axios(config);
      if (res.status === 200) {
        console.log(res.data);
        alert("noticeboard add successfully");
        window.location.assign("/admin/notestudent");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="container mt-5">
      <Card
        className="text-center"
        style={{
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        }}
      >
        <h5 className="pt-5">Create Noticeboard for student</h5>
        <Card.Body>
          <Form className="w-50 mx-auto">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter title"
                onChange={(e) => setnotestudenttitle(e.target.value)}
              />
              {/* <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text> */}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Content</Form.Label>
              <Form.Control
                type="text"
                placeholder="Content"
                row="2"
                onChange={(e) => setnotestudentcontent(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Date"
                onChange={(e) => setnotestudentdate(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Subject</Form.Label>
              <Form.Control
                type="text"
                placeholder="Subject"
                onChange={(e) => setnotestudentsubject(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>classname</Form.Label>
              <Form.Control
                type="text"
                placeholder="Date"
                onChange={(e) => setnotestudentclassname(e.target.value)}
              />
            </Form.Group>
          </Form>

          <button className="btn btn-primary" onClick={postimage}>
            Create Noticeboard
          </button>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Addnotestudent;
